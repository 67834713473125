import React, { useMemo } from 'react';

import { DataType, IntegrationConfigParameterType } from '@m3ter-com/m3ter-api';
import { SelectOption } from '@m3ter-com/ui-components';
import {
  FormField,
  FormInput,
  FormMultiSelect,
  FormSelect,
  FormSwitch,
} from '@m3ter-com/console-core/components';

import {
  DataTypeFormEntityMultiSelect,
  FormEntityMultiSelect,
} from '@/components/forms/FormEntityMultiSelect/FormEntityMultiSelect';

import {
  formatParameterLabel,
  IntegrationConfigParameterFieldProps,
} from './shared';
import { IntegrationConfigParameterHelpText } from './IntegrationConfigParameterHelpText';
import { IntegrationConfigFieldMappingParameterField } from './IntegrationConfigFieldMappingParameterField';
import { IntegrationConfigMapParameterField } from './IntegrationConfigMapParameterField';

const IntegrationConfigMultiSelectListParameterField: React.FC<
  IntegrationConfigParameterFieldProps
> = ({ name, schema }) => {
  const options = useMemo<Array<SelectOption>>(
    () =>
      (schema.permittedValues ?? []).map((permittedValue) => ({
        label: permittedValue,
        value: permittedValue,
      })),
    [schema]
  );

  if (!schema.permittedValues?.length) {
    return null;
  }

  return (
    <FormField
      name={name}
      label={formatParameterLabel(schema.name)}
      helpText={
        <IntegrationConfigParameterHelpText
          docsLink={schema.m3terDocs}
          helpText={schema.description}
        />
      }
      control={FormMultiSelect}
      options={options}
    />
  );
};

const IntegrationConfigOptionListParameterField: React.FC<
  IntegrationConfigParameterFieldProps
> = ({ name, schema }) => {
  const options = useMemo<Array<SelectOption>>(
    () =>
      (schema.permittedValues ?? []).map((permittedValue) => ({
        label: permittedValue,
        value: permittedValue,
      })),
    [schema]
  );

  if (!schema.permittedValues?.length) {
    return null;
  }

  return (
    <FormField
      name={name}
      label={formatParameterLabel(schema.name)}
      helpText={
        <IntegrationConfigParameterHelpText
          docsLink={schema.m3terDocs}
          helpText={schema.description}
        />
      }
      control={FormSelect}
      options={options}
    />
  );
};

export const IntegrationConfigParameterField: React.FC<
  IntegrationConfigParameterFieldProps
> = ({ name, schema }) => {
  switch (schema.type) {
    case IntegrationConfigParameterType.Boolean:
      return (
        <FormField
          name={name}
          label={formatParameterLabel(schema.name)}
          helpText={
            <IntegrationConfigParameterHelpText
              docsLink={schema.m3terDocs}
              helpText={schema.description}
            />
          }
          control={FormSwitch}
        />
      );
    case IntegrationConfigParameterType.FieldMapping:
      return (
        <IntegrationConfigFieldMappingParameterField
          name={name}
          schema={schema}
        />
      );
    case IntegrationConfigParameterType.IdList:
      return (
        <FormField
          name={name}
          label={formatParameterLabel(schema.name)}
          helpText={
            <IntegrationConfigParameterHelpText
              docsLink={schema.m3terDocs}
              helpText={schema.description}
            />
          }
          control={
            FormEntityMultiSelect as DataTypeFormEntityMultiSelect<DataType.Account>
          }
          dataType={DataType.Account}
          accessor="name"
          detailAccessor="code"
        />
      );
    case IntegrationConfigParameterType.Integer:
      return (
        <FormField
          name={name}
          label={formatParameterLabel(schema.name)}
          helpText={
            <IntegrationConfigParameterHelpText
              docsLink={schema.m3terDocs}
              helpText={schema.description}
            />
          }
          control={FormInput}
          isReadOnly={schema.readOnly}
          type="number"
        />
      );
    case IntegrationConfigParameterType.Map:
      return <IntegrationConfigMapParameterField name={name} schema={schema} />;
    case IntegrationConfigParameterType.MultiSelectList:
      return (
        <IntegrationConfigMultiSelectListParameterField
          name={name}
          schema={schema}
        />
      );
    case IntegrationConfigParameterType.OptionList:
      return (
        <IntegrationConfigOptionListParameterField
          name={name}
          schema={schema}
        />
      );
    case IntegrationConfigParameterType.String:
      return (
        <FormField
          name={name}
          label={formatParameterLabel(schema.name)}
          helpText={
            <IntegrationConfigParameterHelpText
              docsLink={schema.m3terDocs}
              helpText={schema.description}
            />
          }
          control={FormInput}
          isReadOnly={schema.readOnly}
        />
      );
    case IntegrationConfigParameterType.UserList:
      return (
        <FormField
          name={name}
          label={formatParameterLabel(schema.name)}
          helpText={
            <IntegrationConfigParameterHelpText
              docsLink={schema.m3terDocs}
              helpText={schema.description}
            />
          }
          control={
            FormEntityMultiSelect as DataTypeFormEntityMultiSelect<DataType.User>
          }
          dataType={DataType.User}
          accessor={(user) => `${user.firstName} ${user.lastName}`}
          detailAccessor="email"
        />
      );
    default:
      return null;
  }
};
