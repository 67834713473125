import React from 'react';

import { ExportSchedule } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { ExportScheduleForm } from '@/components/features/data-exports/ExportScheduleForm/ExportScheduleForm';

export const ExportSchedulesEditRoute: React.FC = () => (
  <CrudEdit<ExportSchedule> form={ExportScheduleForm} />
);
