import { useCallback } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  activateServiceUserCredential,
  createServiceUserCredential,
  deactivateServiceUserCredential,
  deleteServiceUserCredential,
  getServiceUserCredentials,
  Id,
  ServiceUserCredential,
} from '@m3ter-com/m3ter-api';

import useOrg from '@/hooks/data/crud/useOrg';
import { useServiceUserCredentialsContext } from '@/components/features/access/service-user/ServiceUserCredentialsContext/ServiceUserCredentialsContext';

interface ServiceUserUpdateParams {
  mutateFn: (
    organizationId: Id,
    serviceUserId: Id,
    apiKey: Id
  ) => Promise<ServiceUserCredential>;
  apiKey: Id;
}

const useServiceUserCredentials = (serviceUserId: Id) => {
  const { currentOrgId: organizationId } = useOrg();
  const queryClient = useQueryClient();

  const { newServiceUserCredentials, setNewServiceUserCredentials } =
    useServiceUserCredentialsContext();

  const {
    isFetching: isFetchingServiceUserCredentials,
    isLoading: isLoadingServiceUserCredentials,
    error: serviceUserCredentialsLoadError,
    data: serviceUserCredentials = [],
  } = useQuery({
    queryFn: () => getServiceUserCredentials(organizationId, serviceUserId),
    queryKey: ['serviceUserCredentials', organizationId, serviceUserId],
    select: (response) => response.data,
  });

  const {
    error: serviceUserCredentialsUpdateError,
    isPending: isUpdatingServiceUserCredentials,
    mutate: serviceUserCredentialsUpdate,
  } = useMutation({
    mutationFn: async ({ mutateFn, apiKey }: ServiceUserUpdateParams) =>
      mutateFn(organizationId, serviceUserId, apiKey),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['serviceUserCredentials', organizationId, serviceUserId],
      });
    },
  });

  const {
    error: serviceUserCredentialsCreateError,
    isPending: isSavingServiceUserCredentials,
    mutate: serviceUserCredentialsCreate,
  } = useMutation({
    mutationFn: async () =>
      createServiceUserCredential(organizationId, serviceUserId),
    onSuccess: (servicerUserCredentials) => {
      queryClient.invalidateQueries({
        queryKey: ['serviceUserCredentials', organizationId, serviceUserId],
      });
      setNewServiceUserCredentials(servicerUserCredentials);
    },
  });

  const onCreateServiceUserCredentials = useCallback(() => {
    serviceUserCredentialsCreate();
  }, [serviceUserCredentialsCreate]);

  const onRemoveServiceUserCredentials = useCallback(() => {
    setNewServiceUserCredentials();
  }, [setNewServiceUserCredentials]);

  const onActivateServiceUserCredentials = useCallback(
    (apiKey: Id) => {
      serviceUserCredentialsUpdate({
        mutateFn: activateServiceUserCredential,
        apiKey,
      });
    },
    [serviceUserCredentialsUpdate]
  );

  const onDeactivateServiceUserCredentials = useCallback(
    (apiKey: Id) => {
      serviceUserCredentialsUpdate({
        mutateFn: deactivateServiceUserCredential,
        apiKey,
      });
    },
    [serviceUserCredentialsUpdate]
  );

  const onDeleteServiceUserCredentials = useCallback(
    (apiKey: Id) => {
      serviceUserCredentialsUpdate({
        mutateFn: deleteServiceUserCredential,
        apiKey,
      });
    },
    [serviceUserCredentialsUpdate]
  );

  return {
    isFetchingServiceUserCredentials,
    isLoadingServiceUserCredentials,
    isSavingServiceUserCredentials,
    isUpdatingServiceUserCredentials,
    newServiceUserCredentials,
    onActivateServiceUserCredentials,
    onCreateServiceUserCredentials,
    onDeactivateServiceUserCredentials,
    onDeleteServiceUserCredentials,
    onRemoveServiceUserCredentials,
    serviceUserCredentials,
    serviceUserCredentialsCreateError,
    serviceUserCredentialsLoadError,
    serviceUserCredentialsUpdateError,
  };
};

export default useServiceUserCredentials;
