import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Spinner, VStack } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import useQueryString from '@/hooks/navigation/useQueryString';
import {
  loadPricingData,
  reset,
  selectIsLoadingPricingData,
  selectPricingDataEntities,
  selectSegmentsEditorError,
} from '@/store/features/pricing/segmentsEditor';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { AggregationDetails } from '@/components/features/aggregations/AggregationDetails/AggregationDetails';
import { PlanDetails } from '@/components/features/pricing/PlanDetails/PlanDetails';
import { PlanTemplateDetails } from '@/components/features/pricing/PlanTemplateDetails/PlanTemplateDetails';
import { SegmentedPricingGrid } from '@/components/features/pricing/SegmentedPricingGrid/SegmentedPricingGrid';
import { CompoundAggregationDetails } from '@/components/features/aggregations/CompoundAggregationDetails/CompoundAggregationDetails';

interface RouteQueryParams {
  aggregationId?: string;
  compoundAggregationId?: string;
  planTemplateId: string;
  planId?: string;
}

export const PricingSegmentsEditorRoute: React.FC = () => {
  const { aggregationId, compoundAggregationId, planId, planTemplateId } =
    useQueryString<RouteQueryParams>();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      loadPricingData(
        aggregationId ?? compoundAggregationId!,
        !aggregationId,
        planTemplateId,
        planId
      )
    );

    return () => {
      dispatch(reset());
    };
  }, [aggregationId, compoundAggregationId, planTemplateId, planId, dispatch]);

  const error = useSelector(selectSegmentsEditorError);
  const isLoading = useSelector(selectIsLoadingPricingData);
  const pricingData = useSelector(selectPricingDataEntities);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('features:aggregation.segments')} />
      {isLoading ? (
        <Spinner />
      ) : (
        <VStack alignItems="stretch" spacing={4} w="100%">
          {!!error && <ErrorAlert error={error} />}
          {!!pricingData && (
            <React.Fragment>
              {pricingData.aggregation && (
                <AggregationDetails
                  aggregation={pricingData.aggregation}
                  showMetadata={false}
                />
              )}
              {pricingData.compoundAggregation && (
                <CompoundAggregationDetails
                  compoundAggregation={pricingData.compoundAggregation}
                  showMetadata={false}
                />
              )}
              {pricingData.plan ? (
                <PlanDetails
                  showIsCustomDetails
                  plan={pricingData.plan}
                  planTemplate={pricingData.planTemplate}
                  showMetadata={false}
                />
              ) : (
                <PlanTemplateDetails
                  planTemplate={pricingData.planTemplate}
                  showMetadata={false}
                />
              )}
              <SegmentedPricingGrid {...pricingData} />
            </React.Fragment>
          )}
        </VStack>
      )}
    </React.Fragment>
  );
};
