import { combineReducers } from '@reduxjs/toolkit';

import accountContractsReducer from './accountContracts';
import endBillingEntitiesReducer from './endBillingEntities';

const accountsReducer = combineReducers({
  accountContracts: accountContractsReducer,
  endBillingEntities: endBillingEntitiesReducer,
});

export default accountsReducer;
