import React, { ComponentProps } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { UsageQueryBuilderMeters } from '@/components/features/usage/query-builder/UsageQueryBuilderMeters/UsageQueryBuilderMeters';

export interface FormUsageQueryBuilderMetersProps
  extends Omit<
    ComponentProps<typeof UsageQueryBuilderMeters>,
    'value' | 'onChange'
  > {
  name: string;
}

export const FormUsageQueryBuilderMeters: React.FC<
  FormUsageQueryBuilderMetersProps
> = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value = [], onChange } }) => (
        <UsageQueryBuilderMeters value={value} onChange={onChange} {...props} />
      )}
    />
  );
};
