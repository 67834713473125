import React from 'react';

import { Navigate } from 'react-router-dom';

import { DataType, Bill } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { RouteWrapper } from '@/components/routes/helpers/RouteWrapper/RouteWrapper';
import { BillsRoute } from '@/components/routes/billing/BillsRoute';
import { BillDetailsRoute } from '@/components/routes/billing/BillDetailsRoute';
import { BillingNavigation } from '@/components/common/navigation/sections/BillingNavigation/BillingNavigation';

import setupCreditLineItemsRoute from './credit-line-items';
import setupDataExplorerRoute from './data-explorer';
import setupDebitLineItemsRoute from './debit-line-items';
import setupStatementDefinitionsRoute from './statement-definitions';
import setupReportsRoute from './reports';

const LazyBillHistoryRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BillHistoryRoute" */ '@/components/routes/billing/BillHistoryRoute'
  ).then((module) => ({ default: module.BillHistoryRoute }))
);

const LazyBillsList = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BillsList" */ '@/components/features/billing/BillsList/BillsList'
  ).then((module) => ({ default: module.BillsList }))
);

export default (): NamedRoute => ({
  path: 'billing',
  name: 'billing',
  element: <RouteWrapper navigation={<BillingNavigation />} />,
  children: [
    { index: true, element: <Navigate replace to="management" /> },
    {
      path: 'history',
      name: 'billing.history',
      element: <LazyBillHistoryRoute />,
    },
    createCrudRoute<Bill>({
      path: 'management',
      dataType: DataType.Bill,
      element: <BillsRoute />,
      list: <LazyBillsList />,
      details: <BillDetailsRoute />,
      entityChildRoutes: [
        setupCreditLineItemsRoute(),
        setupDebitLineItemsRoute(),
      ],
    }),
    setupDataExplorerRoute(),
    setupStatementDefinitionsRoute(),
    setupReportsRoute(),
  ],
});
