import React from 'react';

import { DataType, IntegrationWorkflow } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { createCrudRoute } from '@/routes/crud';

const LazyIntegrationWorkflowsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationWorkflowsRoute" */ '@/components/routes/integrations/workflows/IntegrationWorkflowsRoute'
  ).then((module) => ({ default: module.IntegrationWorkflowsRoute }))
);

const LazyIntegrationWorkflowsListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationWorkflowsListRoute" */ '@/components/routes/integrations/workflows/IntegrationWorkflowsListRoute'
  ).then((module) => ({ default: module.IntegrationWorkflowsListRoute }))
);

const LazyIntegrationWorkflowCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationWorkflowCreateRoute" */ '@/components/routes/integrations/workflows/IntegrationWorkflowCreateRoute'
  ).then((module) => ({ default: module.IntegrationWorkflowCreateRoute }))
);

const LazyIntegrationWorkflowEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationWorkflowEditRoute" */ '@/components/routes/integrations/workflows/IntegrationWorkflowEditRoute'
  ).then((module) => ({ default: module.IntegrationWorkflowEditRoute }))
);

const LazyIntegrationWorkflowDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationWorkflowDetailsRoute" */ '@/components/routes/integrations/workflows/IntegrationWorkflowDetailsRoute'
  ).then((module) => ({ default: module.IntegrationWorkflowDetailsRoute }))
);

export default (): NamedRoute =>
  createCrudRoute<IntegrationWorkflow>({
    path: 'workflows',
    dataType: DataType.IntegrationWorkflow,
    element: <LazyIntegrationWorkflowsRoute />,
    list: <LazyIntegrationWorkflowsListRoute />,
    create: <LazyIntegrationWorkflowCreateRoute />,
    edit: <LazyIntegrationWorkflowEditRoute />,
    details: <LazyIntegrationWorkflowDetailsRoute />,
  });
