import React, { useMemo } from 'react';

import { Box, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react';

import { DataType, ExportSchedule, SourceType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { DetailsCard } from '@/components/common/data/DetailsCard/DetailsCard';
import { ReferenceLink } from '@/components/common/data/ReferenceLink/ReferenceLink';
import {
  QueryDetails,
  QueryDetailsSection,
} from '@/components/features/data-exports/QueryDetails/QueryDetails';
import { getScheduleFrequencyDescription } from '@/util/dataExport';

export interface ExportScheduleDetailsProps {
  data: ExportSchedule;
}

export const ExportScheduleDetails: React.FC<ExportScheduleDetailsProps> = ({
  data: exportSchedule,
}) => {
  const { t } = useTranslation();

  const operationalDataTypes = useMemo(
    () =>
      (exportSchedule.operationalDataTypes ?? [])
        .map((type) => t(`features:dataExports.operationalDataType.${type}`))
        .sort((a, b) => a.localeCompare(b)),
    [exportSchedule, t]
  );

  return (
    <DetailsCard
      data={exportSchedule}
      dataType={DataType.ExportSchedule}
      showMetadata={false}
      showEditButton
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue
            label={t('forms:labels.name')}
            value={exportSchedule.name}
          />
          <KeyValue
            label={t('forms:labels.code')}
            value={exportSchedule.code}
          />
          <KeyValue
            label={t('forms:labels.sourceType')}
            value={t(
              `features:dataExports.sourceType.${exportSchedule.sourceType}`
            )}
          />
          <KeyValue
            label={t('forms:labels.frequency')}
            value={
              exportSchedule.period
                ? getScheduleFrequencyDescription(
                    exportSchedule.period,
                    exportSchedule.scheduleType
                  )
                : t(
                    `features:dataExports.scheduleType.${exportSchedule.scheduleType}`
                  )
            }
          />
          <KeyValue
            label={t('forms:labels.exportFileFormat')}
            value={t(
              `features:dataExports.exportFileFormat.${exportSchedule.exportFileFormat}`
            )}
          />
          {exportSchedule.sourceType === SourceType.Usage && (
            <React.Fragment>
              <KeyValue
                label={t('forms:labels.timePeriod')}
                value={t(
                  `features:dataExplorer.timePeriod.${exportSchedule.timePeriod}`
                )}
              />
              <KeyValue
                label={t('forms:labels.aggregationFrequency')}
                value={t(
                  `features:dataExports.aggregationFrequency.${exportSchedule.aggregationFrequency}`
                )}
              />
              <KeyValue
                label={t('common:aggregation')}
                value={
                  exportSchedule.aggregation
                    ? t(
                        t(
                          `features:usage.aggregationType.${exportSchedule.aggregation}`
                        )
                      )
                    : t('common:none')
                }
              />
            </React.Fragment>
          )}
          <KeyValue
            label={t('common:destinations')}
            value={exportSchedule.destinationIds.map((destinationId, index) => (
              <Text key={destinationId} as="span">
                <ReferenceLink
                  dataType={DataType.ExportDestination}
                  id={destinationId}
                  accessor="name"
                />
                {index < exportSchedule.destinationIds.length - 1 && ', '}
              </Text>
            ))}
          />
        </SimpleGrid>
      }
      extraDetails={
        <Box>
          <Heading size="sm" mb={2}>
            {t('common:query')}
          </Heading>
          <Stack>
            {exportSchedule.sourceType === SourceType.Usage ? (
              <SimpleGrid columns={2} gap={6}>
                <QueryDetails
                  dataType={DataType.Meter}
                  ids={exportSchedule.meterIds}
                />
                <QueryDetails
                  dataType={DataType.Account}
                  ids={exportSchedule.accountIds}
                />
              </SimpleGrid>
            ) : (
              <QueryDetailsSection
                title={t('features:dataExports.operationalDataTypes')}
                data={operationalDataTypes}
                colorScheme="indigo"
              />
            )}
          </Stack>
        </Box>
      }
    />
  );
};
