import React from 'react';

import { useSelector } from 'react-redux';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  AppLayout,
  AppLayoutHeader,
  AppLayoutMain,
} from '@m3ter-com/ui-components';

import {
  selectBootstrapFailureError,
  selectBootstrapFailureReason,
  BootstrapFailureReason,
} from '@/store/app/bootstrap/bootstrap';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { ConsoleHeader } from '@/components/common/navigation/ConsoleHeader/ConsoleHeader';

export const BootstrapFailureContent: React.FC = () => {
  const { t } = useTranslation();
  const appBootstrapFailureError = useSelector(selectBootstrapFailureError);
  const appBootstrapFailureReason = useSelector(selectBootstrapFailureReason)!;

  return (
    <AppLayout data-testid="organization-route-content">
      <AppLayoutHeader>
        <ConsoleHeader showNavigation={false} />
      </AppLayoutHeader>
      <AppLayoutMain>
        {appBootstrapFailureReason === BootstrapFailureReason.FatalError &&
          !!appBootstrapFailureError && (
            <ErrorAlert error={appBootstrapFailureError} />
          )}
        {appBootstrapFailureReason === BootstrapFailureReason.InvalidOrgId && (
          <ErrorAlert
            error={{ message: t('errors:organizations.invalidOrgId') }}
          />
        )}
        {appBootstrapFailureReason ===
          BootstrapFailureReason.NoOrgsAvailable && (
          <ErrorAlert
            error={{ message: t('errors:organizations.noAvailableOrgs') }}
          />
        )}
        {appBootstrapFailureReason ===
          BootstrapFailureReason.OrgDataLoadingFailure && (
          <ErrorAlert
            title={t('errors:organizations.noUserPermissions')}
            error={{
              message: t('errors:organizations.orgDataLoadingFailure', {
                error: appBootstrapFailureError?.message,
              }),
            }}
          />
        )}
      </AppLayoutMain>
    </AppLayout>
  );
};
