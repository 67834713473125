import React, { useMemo } from 'react';

import snakeCase from 'lodash/snakeCase';

import { ChildBillingMode, DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { useOrgDates } from '@/hooks/util/useOrgDates';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { useCrudRetrieveContext } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';
import { AccountCustomPlanForm } from '@/components/features/accounts/AccountCustomPlanForm/AccountCustomPlanForm';
import useCustomPlanCreate from '@/hooks/features/accounts/useCustomPlanCreate';

export const CreateCustomPlanRoute: React.FC = () => {
  const { t } = useTranslation();
  const { getStartOfToday } = useOrgDates();
  const { account } = useCrudRetrieveContext();
  const entityNamings = useEntityNamings(DataType.Plan);

  const { error, isSaving, onCancel, onSave } = useCustomPlanCreate(
    DataType.Plan
  );

  const initialValues = useMemo(() => {
    const planName = `${account?.name} ${t('common:plan')}`;

    return {
      accountId: account?.id,
      startDate: getStartOfToday().toISOString(),
      name: planName,
      code: snakeCase(planName),
      childBillingMode: ChildBillingMode.ParentBreakdown,
    };
  }, [account, getStartOfToday, t]);

  return (
    <React.Fragment>
      {error && (
        <ErrorAlert
          title={t('errors:generic.problemSavingData', {
            entityName: entityNamings.singularLower,
          })}
          error={error}
          mb={4}
        />
      )}
      <BreadcrumbItem title={t('common:create')} />
      <AccountCustomPlanForm
        isSaving={isSaving}
        initialValues={initialValues}
        onSave={onSave}
        onCancel={onCancel}
      />
    </React.Fragment>
  );
};
