import React from 'react';

import { DataType, BalanceTransaction } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormAdvancedNumberInput,
  FormCurrencyInput,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import balanceTransactionSchema from '@/validation/balanceTransaction';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { FormPicklistSelect } from '@/components/features/accounts/FormPicklistSelect/FormPicklistSelect';

export interface BalanceTransactionFormProps
  extends BaseFormProps<BalanceTransaction> {}

const defaultInitialValues: Partial<BalanceTransaction> = {};

export const BalanceTransactionForm: React.FC<BalanceTransactionFormProps> = ({
  extraData,
  initialValues = defaultInitialValues,
  isEdit,
  isSaving,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.BalanceTransaction);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSave}
      validationSchema={balanceTransactionSchema}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <FormField
            isRequired
            name="transactionTypeId"
            label={t('common:transactionType')}
            control={FormPicklistSelect}
            dataType={DataType.TransactionType}
          />
          <FormField
            isRequired
            name="amount"
            label={t('forms:labels.amount')}
            control={FormCurrencyInput}
            code={extraData?.currency}
          />
          <FormField
            name="description"
            label={t('forms:labels.description')}
            control={FormInput}
          />
        </FormSection>
        <FormSection
          isOptional
          heading={t('forms:labels.entitySettings', {
            entityName: entityNamings.singular,
          })}
          helpText={t('features:balances.paidDescription')}
        >
          <FormField
            name="currencyPaid"
            label={t('forms:labels.currency')}
            control={FormPicklistSelect}
            useCodeAsValue
            dataType={DataType.Currency}
          />
          <FormField
            name="paid"
            label={t('forms:labels.amount')}
            control={FormAdvancedNumberInput}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', {
                  entityName: entityNamings.singularLower,
                })
              : t('forms:buttons.createEntity', {
                  entityName: entityNamings.singularLower,
                })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
