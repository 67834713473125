import React from 'react';

import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import upperFirst from 'lodash/upperFirst';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Select } from '@m3ter-com/ui-components';

import {
  SegmentedPricingFilterState,
  SegmentedPricingSortingState,
} from '@/hooks/features/pricing/usePricingSegmentsEditor';
import { PricingGridCell } from '@/components/features/pricing/grid/PricingGridCell/PricingGridCell';

import { SegmentedPricingGridSortButton } from './SegmentedPricingGridSortButton';

export interface SegmentedPricingGridHeadersProps {
  segmentedFields: Array<string>;
  filterState: SegmentedPricingFilterState;
  segmentedFieldValues: Record<string, Array<string>>;
  sortingState: SegmentedPricingSortingState;
  onFieldFilterSelect: (fieldName: string, newFieldValue: string) => void;
  onSortButtonClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const SegmentedPricingGridHeaders: React.FC<
  SegmentedPricingGridHeadersProps
> = ({
  segmentedFields,
  filterState,
  segmentedFieldValues,
  sortingState,
  onFieldFilterSelect,
  onSortButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {segmentedFields.map((fieldName) => {
        const fieldFilterOptions = [
          { value: '', label: t('common:any') },
          ...segmentedFieldValues[fieldName].map((fieldValue) => ({
            value: fieldValue,
            label: fieldValue,
          })),
        ];

        return (
          <VStack
            key={fieldName}
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={4}
            as={PricingGridCell}
          >
            <Text>{upperFirst(fieldName)}</Text>
            <HStack
              alignItems="flex-start"
              justifyContent="center"
              spacing={4}
              w="100%"
            >
              <Box w="100%">
                <Select
                  value={filterState[fieldName]}
                  onChange={(newFieldValue: string | null) => {
                    // We can safely cast here since we aren't making the <Select> clearable
                    onFieldFilterSelect(fieldName, newFieldValue as string);
                  }}
                  options={fieldFilterOptions}
                />
              </Box>
              <SegmentedPricingGridSortButton
                fieldName={fieldName}
                sortingState={sortingState}
                onClick={onSortButtonClick}
              />
            </HStack>
          </VStack>
        );
      })}
      <VStack
        alignItems="flex-start"
        justifyContent="flex-start"
        as={PricingGridCell}
      >
        <Text>{t('common:pricing')}</Text>
      </VStack>
    </React.Fragment>
  );
};
