import React from 'react';

import { UserGroup } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { UserGroupEditForm } from '@/components/features/access/user-group/UserGroupEditFrom/UserGroupEditFrom';

export const UserGroupsEditRoute: React.FC = () => (
  <CrudEdit<UserGroup> form={UserGroupEditForm} />
);
