import React from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { ServiceUserCreateForm } from '@/components/features/access/service-user/ServiceUserCreateForm/ServiceUserCreateForm';
import useServiceUserCreate from '@/hooks/features/access/useServiceUserCreate';

export const ServiceUsersCreateRoute: React.FC = () => {
  const { t } = useTranslation();
  const { error, isSaving, onCancel, onSave } = useServiceUserCreate();

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:create')} />
      {error && <ErrorAlert error={error} />}
      <ServiceUserCreateForm
        isSaving={isSaving}
        onSave={onSave}
        onCancel={onCancel}
      />
    </React.Fragment>
  );
};
