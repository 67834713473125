import { WidgetType } from '@/types/widgets';

import WidgetBank from '@/data/widgets/WidgetBank';

export const registerWidgets = () => {
  WidgetBank.registerWidget({
    initialConfigData: {},
    configFieldSchemas: [],
    contentComponentLoader: () =>
      import(
        /* webpackChunkName: "AlertsSummaryWidget" */ '@/components/widgets/AlertsSummaryWidget/AlertsSummaryWidget'
      ).then((module) => ({
        default: module.AlertsSummaryWidget,
      })),
    type: WidgetType.AlertsSummary,
  });

  WidgetBank.registerWidget({
    initialConfigData: {},
    configFieldSchemas: [],
    contentComponentLoader: () =>
      import(
        /* webpackChunkName: "RecentAccountsWidget" */ '@/components/widgets/RecentAccountsWidget/RecentAccountsWidget'
      ).then((module) => ({
        default: module.RecentAccountsWidget,
      })),
    type: WidgetType.RecentAccounts,
  });
};
