import React, { useMemo } from 'react';

import { VStack } from '@chakra-ui/react';

import { DataType, Meter, MeterFieldCategory } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack, SelectOption } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormSection,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import meterSchema from '@/validation/meter';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { FormCustomFieldsEditorDefaults } from '@/components/forms/FormCustomFieldsEditorDefaults/FormCustomFieldsEditorDefaults';
import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect/FormEntitySelect';
import { DerivedFieldsField } from '@/components/features/meters/DerivedFieldsField/DerivedFieldsField';
import { DataFieldsField } from '@/components/features/meters/DataFieldsField/DataFieldsField';

export interface MeterFormProps {
  onSave: (item: Meter) => void;
  onCancel: () => void;
  isSaving?: boolean;
  isEdit?: boolean;
  initialValues?: Meter;
}

const defaultInitialValues = {
  dataFields: [{}],
  derivedFields: [],
};

export const MeterForm: React.FC<MeterFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  isEdit = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Meter);

  const categoryOptions = useMemo<Array<SelectOption>>(
    () =>
      Object.values(MeterFieldCategory).map((categoryValue) => ({
        value: categoryValue,
        label: t(`features:meters.dataFieldCategory.${categoryValue}`),
      })),
    [t]
  );

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={meterSchema}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <FormField
            name="productId"
            label={t('common:product')}
            control={
              FormEntitySelect as DataTypeFormEntitySelect<DataType.Product>
            }
            isClearable
            dataType={DataType.Product}
            placeholder={`(${t('common:global')})`}
            accessor="name"
            detailAccessor="code"
          />
          <NameCodeFields />
        </FormSection>
      </FormStack>
      <VStack my={6} spacing={6} alignItems="start">
        <DataFieldsField name="dataFields" categoryOptions={categoryOptions} />
        <DerivedFieldsField
          name="derivedFields"
          categoryOptions={categoryOptions}
        />
      </VStack>
      <FormStack>
        <FormCustomFieldsEditorDefaults dataType={DataType.Meter} />
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', {
                  entityName: entityNamings.singularLower,
                })
              : t('forms:buttons.createEntity', {
                  entityName: entityNamings.singularLower,
                })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
