import React, { useMemo } from 'react';

import { Outlet } from 'react-router-dom';
import { Text } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert } from '@m3ter-com/ui-components';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import usePreference from '@/hooks/data/usePreference';

const DataExportPreviewBanner: React.FC = () => {
  const { t } = useTranslation();
  const [dismissed, setDismissed] = usePreference(
    'data-exports-banner-dismissed',
    false
  );

  const alertButtonProps = useMemo(
    () => ({
      size: 'sm',
      onClick: () => setDismissed(true),
    }),
    [setDismissed]
  );

  return !dismissed ? (
    <Alert
      mb={4}
      status="info"
      alertTitle={t('features:dataExports.previewBannerTitle')}
      actionButtonText={t('common:close')}
      actionButtonProps={alertButtonProps}
    >
      <Text>{t('features:dataExports.previewBannerDescription')}</Text>
    </Alert>
  ) : null;
};

export const DataExportRootRoute: React.FC = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:dataExport')} />
      <DataExportPreviewBanner />
      <Outlet />
    </React.Fragment>
  );
};
