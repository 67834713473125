import React from 'react';

import { SimpleGrid } from '@chakra-ui/react';

import { DataType, ServiceUser } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { DetailsCard } from '@/components/common/data/DetailsCard/DetailsCard';
import { PermissionPolicyList } from '@/components/features/access/permission-policy/PermissionPolicyList/PermissionPolicyList';
import { ServiceUserCredentialList } from '@/components/features/access/service-user/ServiceUserCredentialsList/ServiceUserCredentialsList';

export interface ServiceUserDetailsProps {
  data: ServiceUser;
}

export const ServiceUserDetails: React.FC<ServiceUserDetailsProps> = ({
  data: serviceUser,
}) => {
  const { t } = useTranslation();

  return (
    <DetailsCard
      showEditButton
      data={serviceUser}
      dataType={DataType.ServiceUser}
      showMetadata={false}
      details={
        <SimpleGrid columns={1} gap={6} w="100%">
          <KeyValue label={t('forms:labels.name')} value={serviceUser.name} />
        </SimpleGrid>
      }
    >
      <PermissionPolicyList
        dataType={DataType.ServiceUser}
        id={serviceUser.id}
      />
      <ServiceUserCredentialList serviceUser={serviceUser} />
    </DetailsCard>
  );
};
