import React, { useMemo } from 'react';

import {
  Aggregation,
  CompoundAggregation,
  Counter,
  CounterPricing,
  Plan,
  PlanTemplate,
  Pricing,
} from '@m3ter-com/m3ter-api';
import { PropsWithOneOf } from '@m3ter-com/console-core/types';
import {
  getValidSegmentedPricings,
  PricingByPlanOrPlanTemplate,
} from '@m3ter-com/console-core/utils';

import {
  isAggregationSegmented,
  isCompoundAggregationSegmented,
} from '@/util/aggregation';
import { PricingGridDataCell } from '@/components/features/pricing/grid/PricingGridDataCell/PricingGridDataCell';
import { PricingGridCell } from '@/components/features/pricing/grid/PricingGridCell/PricingGridCell';
import { PricingGridRowContextProvider } from '@/components/features/pricing/grid/PricingGridRowContext/PricingGridRowContext';
import { PricingGridCellContextProvider } from '@/components/features/pricing/grid/PricingGridCellContext/PricingGridCellContext';

import {
  PricingGridRowHeader,
  PricingGridRowHeaderCallback,
} from './PricingGridRowHeader';

export interface PricingGridRowCommonProps {
  linkedPlanTemplatesByPlanId: Record<string, PlanTemplate | undefined>;
  selectedPlans: Array<Plan>;
  selectedPlanTemplates: Array<PlanTemplate>;
  onRemovePricingUsageEntity: PricingGridRowHeaderCallback;
}
export type AggregationPricingGridRowProps = PropsWithOneOf<
  PricingGridRowCommonProps & {
    pricingsByPlanOrTemplate: PricingByPlanOrPlanTemplate<Pricing> | undefined;
    itemCounter?: never;
  },
  {
    aggregation: Aggregation;
    compoundAggregation: CompoundAggregation;
  }
>;

export type ItemCounterPricingGridRowProps = PricingGridRowCommonProps & {
  pricingsByPlanOrTemplate:
    | PricingByPlanOrPlanTemplate<CounterPricing>
    | undefined;
  itemCounter: Counter;
  aggregation?: never;
  compoundAggregation?: never;
};

export type PricingGridRowProps =
  | AggregationPricingGridRowProps
  | ItemCounterPricingGridRowProps;

export const PricingGridRow: React.FC<PricingGridRowProps> = ({
  aggregation,
  compoundAggregation,
  itemCounter,
  linkedPlanTemplatesByPlanId,
  pricingsByPlanOrTemplate,
  selectedPlans,
  selectedPlanTemplates,
  onRemovePricingUsageEntity,
}) => {
  // Can only remove the usage entity if it has no pricings for any plan or template.
  const canRemovePricingUsageEntity = useMemo(() => {
    const pricings = Object.values(pricingsByPlanOrTemplate ?? {}).flat();

    if (pricings.length === 0) {
      return true;
    }

    if (aggregation && isAggregationSegmented(aggregation)) {
      return getValidSegmentedPricings(aggregation, pricings).length === 0;
    }

    if (
      compoundAggregation &&
      isCompoundAggregationSegmented(compoundAggregation)
    ) {
      return (
        getValidSegmentedPricings(compoundAggregation, pricings).length === 0
      );
    }

    return false;
  }, [aggregation, compoundAggregation, pricingsByPlanOrTemplate]);

  // We will always have an aggregation, compound aggregation, or item counter.
  const pricingUsageEntityId = (aggregation?.id ??
    compoundAggregation?.id ??
    itemCounter?.id)!;

  return (
    <PricingGridRowContextProvider
      aggregation={aggregation}
      compoundAggregation={compoundAggregation}
      itemCounter={itemCounter}
    >
      <PricingGridCell borderLeftWidth={2} data-testid="row-header">
        <PricingGridRowHeader
          aggregation={aggregation}
          compoundAggregation={compoundAggregation}
          itemCounter={itemCounter}
          canRemove={canRemovePricingUsageEntity}
          onRemovePricingUsageEntity={onRemovePricingUsageEntity}
        />
      </PricingGridCell>
      {selectedPlans.map((plan, planIndex) => {
        const planPricings = pricingsByPlanOrTemplate?.[plan.id] ?? [];
        const planTemplate = linkedPlanTemplatesByPlanId[plan.id];
        const planTemplatePricings = planTemplate
          ? pricingsByPlanOrTemplate?.[planTemplate.id] ?? []
          : [];

        return (
          <PricingGridCell
            key={`pricing-grid-plan-cell-${plan.id}-${pricingUsageEntityId}`}
            data-testid={`pricing-grid-plan-cell-${planIndex}`}
          >
            <PricingGridCellContextProvider
              isTemplateColumn={false}
              plan={plan}
              planPricings={planPricings}
              planTemplate={planTemplate}
              planTemplatePricings={planTemplatePricings}
            >
              <PricingGridDataCell />
            </PricingGridCellContextProvider>
          </PricingGridCell>
        );
      })}
      {selectedPlanTemplates.map((planTemplate, planTemplateIndex) => {
        const planTemplatePricings =
          pricingsByPlanOrTemplate?.[planTemplate.id] ?? [];

        return (
          <PricingGridCell
            key={`pricing-grid-plan-cell-${planTemplate.id}-${pricingUsageEntityId}`}
            data-testid={`pricing-grid-plan-cell-${planTemplateIndex}`}
          >
            <PricingGridCellContextProvider
              isTemplateColumn
              planTemplate={planTemplate}
              planTemplatePricings={planTemplatePricings}
            >
              <PricingGridDataCell />
            </PricingGridCellContextProvider>
          </PricingGridCell>
        );
      })}
    </PricingGridRowContextProvider>
  );
};
