import React from 'react';

import { Navigate } from 'react-router-dom';

import { NamedRoute } from '@/types/routes';

import { RouteWrapper } from '@/components/routes/helpers/RouteWrapper/RouteWrapper';
import { DataExportsNavigation } from '@/components/common/navigation/sections/DataExportsNavigation/DataExportsNavigation';

import setupDataExportRoute from './data-export';
import setupExportJobsRoute from './export-jobs';

export default (): NamedRoute => ({
  path: 'data',
  name: 'data',
  element: <RouteWrapper navigation={<DataExportsNavigation />} />,
  children: [
    { index: true, element: <Navigate replace to="export" /> },
    setupDataExportRoute(),
    setupExportJobsRoute(),
  ],
});
