import React, { useCallback, useMemo } from 'react';

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Link,
  useDisclosure,
} from '@chakra-ui/react';

import { DataType, Commitment, Contract } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import { OtherListIds } from '@/types/lists';

import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useContractUpdate from '@/hooks/features/contracts/useContractUpdate';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink/CrudEditLink';
import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import { EntityMultiSelectModal } from '@/components/common/data/EntitySelectModal';

export interface ContractCommitmentsProps {
  contract: Contract;
}

const emptyArray = new Array<any>();

export const ContractCommitments: React.FC<ContractCommitmentsProps> = ({
  contract,
}) => {
  const { accountId, id: contractId } = contract;
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { toLongDate } = useDateFormatter();
  const commitmentNamings = useEntityNamings(DataType.Commitment);

  const { isAddingCommitments, addCommitments, removeCommitment } =
    useContractUpdate(contractId);

  const contractCommitmentsListQueryParams = useMemo(
    () => ({ accountId, contractId }),
    [accountId, contractId]
  );
  const contractsCommitmentsListColumns = useMemo<
    Array<ColumnDefinition<Commitment>>
  >(
    () => [
      {
        id: 'amount',
        accessor: (commitment) => (
          <Link
            as={CrudEditLink}
            addReturnPath
            dataType={DataType.Commitment}
            id={commitment.id}
          >
            {formatCurrency(commitment.amount, commitment.currency)}
          </Link>
        ),
        header: t('forms:labels.amount'),
      },
      {
        id: 'start-date',
        accessor: (commitment) => toLongDate(commitment.startDate),
        header: t('forms:labels.startDate'),
      },
      {
        id: 'end-date',
        accessor: (commitment) =>
          commitment.endDate
            ? toLongDate(commitment.endDate)
            : `(${t('common:none')})`,
        header: t('forms:labels.endDate'),
      },
    ],
    [t, toLongDate, formatCurrency]
  );
  const onRemoveCommitment = useCallback(
    (commitment: Commitment) => {
      removeCommitment(commitment.id);
    },
    [removeCommitment]
  );

  const {
    isOpen: isCommitmentsModalOpen,
    onOpen: onOpenCommitmentsModal,
    onClose: onCloseCommitmentsModal,
  } = useDisclosure();
  const addCommitmentsModalQueryParams = useMemo(
    () => ({ accountId, contractId: '' }),
    [accountId]
  );
  const addCommitmentsModalColumns = useMemo<
    Array<ColumnDefinition<Commitment>>
  >(
    () => [
      {
        id: 'amount',
        accessor: (commitment) =>
          formatCurrency(commitment.amount, commitment.currency),
        header: t('forms:labels.amount'),
      },
      {
        id: 'start-date',
        accessor: (commitment) => toLongDate(commitment.startDate),
        header: t('forms:labels.startDate'),
      },
      {
        id: 'end-date',
        accessor: (commitment) =>
          commitment.endDate
            ? toLongDate(commitment.endDate)
            : `(${t('common:none')})`,
        header: t('forms:labels.endDate'),
      },
    ],
    [t, toLongDate, formatCurrency]
  );

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <HStack justifyContent="space-between">
            <Heading size="md">{t('features:contracts.prepayments')}</Heading>
            <Button
              size="sm"
              onClick={onOpenCommitmentsModal}
              isLoading={isAddingCommitments}
            >
              {t('forms:buttons.addEntity', {
                entityName: commitmentNamings.pluralLower,
              })}
            </Button>
          </HStack>
        </CardHeader>
        <CardBody>
          <CrudList<Commitment>
            listId={OtherListIds.ContractCommitments}
            columns={contractsCommitmentsListColumns}
            dataType={DataType.Commitment}
            params={contractCommitmentsListQueryParams}
            onDelete={onRemoveCommitment}
          />
        </CardBody>
      </Card>
      <EntityMultiSelectModal
        columns={addCommitmentsModalColumns}
        dataType={DataType.Commitment}
        isOpen={isCommitmentsModalOpen}
        onClose={onCloseCommitmentsModal}
        onConfirm={addCommitments}
        params={addCommitmentsModalQueryParams}
        selected={emptyArray}
      />
    </React.Fragment>
  );
};
