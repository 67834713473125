import React, { useMemo } from 'react';

import { Table, Tfoot, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react';

import { DataType, Bill, BillLineItem } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { getBillTotal, getReference, splitBillsByIds } from '@/util/billing';
import useCurrencies from '@/hooks/util/useCurrencies';
import { ReferenceLabel } from '@/components/common/data/ReferenceLabel/ReferenceLabel';

import { BillLineItemRow } from './BillLineItemRow';

export interface BillTableProps {
  bill: Bill;
  zeroQuantityLineItemsHidden: boolean;
}

function* getFakeLineItemRef(billRef: string): Generator<string> {
  let lineItemIndex = 1;

  while (true) {
    yield `${billRef}-${lineItemIndex}`;
    lineItemIndex += 1;
  }
}

const BillSectionHeader: React.FC<{ lineItem: BillLineItem }> = ({
  lineItem,
}) => {
  const { t } = useTranslation();
  const { commitmentId, planGroupId, productId, productName } = lineItem;

  // If the line item has a productId and productName, but no planGroupId,
  // return product name as the section header.
  //
  // Note: Plan groups can have an associated product (accounting product),
  // so we need to check for the absense of a planGroupId to determine if the
  // product name should be used as the section header.
  if (productId && productName && !planGroupId) {
    return (
      <span>
        {t('features:billing.productAndProductName', { productName })}
      </span>
    );
  }

  // If the line item has a planGroupId, return the plan group name as the section header.
  if (planGroupId) {
    return (
      <span>
        {`${t('common:planGroup')}: `}
        <ReferenceLabel
          dataType={DataType.PlanGroup}
          id={planGroupId}
          accessor="name"
        />
      </span>
    );
  }

  // If the line item has a commitmentId, return 'Prepayments charges' as the section header.
  if (commitmentId) {
    return <span>{t('features:billing.prepaymentsCharges')}</span>;
  }

  // For everything else return 'Other line items' as the section header.
  return <span>{t('features:billing.otherLineItems')}</span>;
};

export const BillTable: React.FC<BillTableProps> = ({
  bill,
  zeroQuantityLineItemsHidden,
}) => {
  const { t } = useTranslation();
  const { formatCurrency, getMaxDecimalPlaces } = useCurrencies();

  const billSplitById = useMemo(() => splitBillsByIds([bill]), [bill]);
  const lineItemRefGenerator = getFakeLineItemRef(getReference(bill));
  const billTotal = useMemo(
    () => formatCurrency(bill.billTotal, bill.currency),
    [bill, formatCurrency]
  );

  return (
    <Table w="100%" variant="unstyled">
      {billSplitById.map(([id, productBills], index) => {
        // Should only ever be one bill per product because we only had one
        // bill to split by products
        const productBill = productBills[0];
        if (!productBill.lineItems.length) return null;

        const isLastBillRow = billSplitById.length - 1 === index;

        return (
          <Tbody key={id} data-testid="product">
            <Tr borderBottom="2px solid" color="chakra-subtle-text">
              <Th w="24px" />
              <Th
                colSpan={7}
                scope="rowgroup"
                fontSize="lg"
                letterSpacing="normal"
                textTransform="none"
                paddingTop={index === 0 ? 2 : 10}
              >
                {/* Product bill line items previously validated so it is safe to index here */}
                <BillSectionHeader lineItem={productBill.lineItems[0]} />
              </Th>
            </Tr>
            <Tr>
              <Th />
              <Th>{t('forms:labels.reference')}</Th>
              <Th>{t('forms:labels.description')}</Th>
              <Th>{t('forms:labels.type')}</Th>
              <Th textAlign="right">{t('features:billing.numberOfUnits')}</Th>
              <Th>{t('forms:labels.unit')}</Th>
              <Th textAlign="right" whiteSpace="nowrap">
                {t('forms:labels.unitPrice')}
              </Th>
              <Th textAlign="right">{t('forms:labels.subtotal')}</Th>
            </Tr>
            {productBill.lineItems
              .filter(
                (lineItem) =>
                  !zeroQuantityLineItemsHidden || lineItem.units !== 0
              )
              .map((lineItem) => (
                <BillLineItemRow
                  key={lineItem.id}
                  bill={bill}
                  reference={
                    lineItem.sequenceNumber && bill.sequentialInvoiceNumber
                      ? `${bill.sequentialInvoiceNumber}-${lineItem.sequenceNumber}`
                      : lineItemRefGenerator.next().value
                  }
                  lineItem={lineItem}
                />
              ))}
            <Tr>
              <Td
                colSpan={8}
                fontWeight="bold"
                textAlign="right"
                borderTop="2px solid"
                borderBottom={isLastBillRow ? '2px solid' : '0px'}
              >
                {formatCurrency(
                  getBillTotal(
                    productBill,
                    getMaxDecimalPlaces(productBill.currency)
                  ),
                  productBill.currency
                )}
              </Td>
            </Tr>
          </Tbody>
        );
      })}
      <Tfoot data-testid="total">
        <Tr>
          <Td colSpan={6} />
          <Td colSpan={2} paddingY={6} textAlign="right" borderBottom={0}>
            <Text fontSize="xl" fontWeight="bold">{`${t(
              'common:total'
            )}: ${billTotal}`}</Text>
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  );
};
