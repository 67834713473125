import {
  EncodedIntegrationRunLogResponse,
  ExternalMapping,
  ExternalMappingEntityType,
  ExternalMappingExternalEntity,
  Id,
  IntegrationConfigSchema,
  IntegrationCredentialRedirectURLResponse,
  IntegrationTransformationPreviewResponse,
  IntegrationTransformationTransform,
  IntegrationWorkflowFunctionSchemasResponse,
  UnsavedEntity,
} from '../types';

import { getOrganizationPath } from '../util/path';
import { get, post } from '../client';

export type CreateExternalMappingsData = Omit<
  UnsavedEntity<ExternalMapping>,
  'externalId' | 'm3terId'
> & {
  mappings: Array<{ externalId: string; m3terId: string }>;
  organizationId: string;
};

interface GetIntegrationCredentialRedirectURLData {
  destination: string;
  name: string;
  organizationId: string;
}

interface GetIntegrationsExternalEntitiesData {
  organizationId: string;
  externalSystem: string;
  m3terEntityType: ExternalMappingEntityType;
  integrationCredentialId?: string;
}

interface PreviewIntegrationTransformationData {
  entity: string;
  entityId: string;
  organizationId: string;
  transformations: Array<IntegrationTransformationTransform>;
}

export const createExternalMappings = async (
  data: CreateExternalMappingsData
): Promise<Array<ExternalMapping>> => {
  const { mappings, organizationId, ...externalMappingsSettings } = data;
  const externalMappings = mappings.map<UnsavedEntity<ExternalMapping>>(
    (idMapping) => ({
      ...idMapping,
      ...externalMappingsSettings,
    })
  );
  return Promise.all(
    externalMappings.map(
      (externalMapping) =>
        post({
          body: externalMapping,
          path: getOrganizationPath('/externalmappings'),
          pathParams: { organizationId },
        }) as Promise<ExternalMapping>
    )
  );
};

export const getIntegrationConfigSchema = (
  organizationId: string
): Promise<IntegrationConfigSchema> =>
  get({
    path: getOrganizationPath('/integrationconfigs/available'),
    pathParams: { organizationId },
  }) as Promise<IntegrationConfigSchema>;

export const getIntegrationCredentialRedirectURL = (
  data: GetIntegrationCredentialRedirectURLData
): Promise<IntegrationCredentialRedirectURLResponse> =>
  get({
    path: getOrganizationPath('/integrationauth/:destination/oauth/authorize'),
    pathParams: {
      organizationId: data.organizationId,
      destination: data.destination.toLowerCase(),
    },
    queryParams: { name: data.name },
  }) as Promise<IntegrationCredentialRedirectURLResponse>;

export const getIntegrationsExternalEntities = (
  data: GetIntegrationsExternalEntitiesData
) =>
  get({
    path: getOrganizationPath(
      data.integrationCredentialId
        ? '/externalmappingconfiguration/values/:externalSystem/:m3terEntityType/:integrationCredentialId'
        : '/externalmappingconfiguration/values/:externalSystem/:m3terEntityType'
    ),
    pathParams: {
      organizationId: data.organizationId,
      externalSystem: data.externalSystem,
      m3terEntityType: data.m3terEntityType,
      integrationCredentialId: data.integrationCredentialId,
    },
  }) as Promise<Array<ExternalMappingExternalEntity>>;

export const getIntegrationRunLog = (organizationId: Id, runId: Id) =>
  get({
    path: getOrganizationPath('/integrationruns/logs/:runId'),
    pathParams: {
      organizationId,
      runId,
    },
  }) as Promise<EncodedIntegrationRunLogResponse>;

export const getIntegrationWorkflowFunctionSchemas = (
  organizationId: string
): Promise<IntegrationWorkflowFunctionSchemasResponse> =>
  get({
    path: getOrganizationPath('/integrationdestinations/workflows/functions'),
    pathParams: {
      organizationId,
    },
  }) as Promise<IntegrationWorkflowFunctionSchemasResponse>;

export const previewIntegrationTransformation = (
  data: PreviewIntegrationTransformationData
): Promise<IntegrationTransformationPreviewResponse> =>
  post({
    body: {
      entity: data.entity,
      entityId: data.entityId,
      transformations: data.transformations,
    },
    path: getOrganizationPath('/integrationtransformations/preview'),
    pathParams: {
      organizationId: data.organizationId,
    },
  }) as Promise<IntegrationTransformationPreviewResponse>;
