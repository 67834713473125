import React from 'react';

import { DataType, Account } from '@m3ter-com/m3ter-api';

import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';
import { AccountBills } from '@/components/features/accounts/AccountBills/AccountBills';

export interface AccountBillsRouteProps {}

export const AccountBillsRoute: React.FC<AccountBillsRouteProps> = () => {
  const account = useCrudRetrieveData<Account>(DataType.Account);

  return <AccountBills account={account} />;
};
