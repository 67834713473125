import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Badge, Link, Spinner, Text } from '@chakra-ui/react';

import { DataType, Aggregation } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';
import { Button, Well } from '@m3ter-com/ui-components';

import { EntityRouteListIds } from '@/types/lists';

import { dataTypeListQuery } from '@/queries/crud';
import { selectSelectedProductIdIfNotGlobal } from '@/store/products/products';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';
import {
  CrudList,
  ColumnDefinition,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { ProductSelector } from '@/components/features/products/ProductSelector/ProductSelector';

const listRelationships = ['meter'];
const searchFields = ['code', 'name'];

export const AggregationsListRoute: React.FC = () => {
  const { t } = useTranslation();
  const meterNamings = useEntityNamings(DataType.Meter);

  const productId = useSelector(selectSelectedProductIdIfNotGlobal);

  const pathParams = useOrgPathParams();
  const { data: meters, isLoading: isLoadingMeters } = useQuery(
    dataTypeListQuery({
      dataType: DataType.Meter,
      pathParams,
      queryParams: {
        pageSize: 1,
        // When a product is selected we check both that product's aggregations
        // and global aggregations. If no product, we just check global aggregations.
        productId: productId ? ['', productId] : '',
      },
    })
  );

  const columns = useMemo<Array<ColumnDefinition<Aggregation>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (aggregation: EntityWithRelationships<Aggregation>) => (
          <Text>
            {aggregation.name}
            {!aggregation.meter?.productId && (
              <Badge ml={2}>{t('common:global')}</Badge>
            )}
          </Text>
        ),
      },
      { id: 'code', header: t('forms:labels.code'), accessor: 'code' },
      {
        id: 'meter',
        header: meterNamings.singular,
        accessor: (aggregation: EntityWithRelationships<Aggregation>) =>
          !!aggregation.meter && (
            <Link
              as={CrudDetailsLink}
              dataType={DataType.Meter}
              id={aggregation.meterId}
            >
              {aggregation.meter.name}
            </Link>
          ),
      },
      {
        id: 'targetField',
        header: t('forms:labels.targetField'),
        accessor: 'targetField',
      },
    ],
    [meterNamings, t]
  );

  // When a product is selected, we show both global aggs and aggs for that product.
  // If not, we just show global aggs.
  const listParams = useMemo(
    () => ({ productId: productId ? ['', productId] : '' }),
    [productId]
  );

  if (isLoadingMeters) {
    return <Spinner />;
  }

  const hasNoMeters = !meters?.length;

  return (
    <CrudList
      listId={EntityRouteListIds.Aggregation}
      params={listParams}
      relationships={listRelationships}
      searchFields={searchFields}
    >
      <CrudListHeader isCreateDisabled={hasNoMeters}>
        <ProductSelector includeGlobal />
      </CrudListHeader>
      {hasNoMeters ? (
        <Well
          alignItems="center"
          display="flex"
          flexFlow="column nowrap"
          gap={4}
          justifyContent="center"
        >
          <Text>{t('errors:aggregations.noMetersCreated')}</Text>
          <Button as={CrudCreateLink} dataType={DataType.Meter}>
            {t('forms:buttons.createEntity', {
              entityName: meterNamings.singularLower,
            })}
          </Button>
        </Well>
      ) : (
        <React.Fragment>
          <CrudListTable columns={columns} />
          <CrudListFooter />
        </React.Fragment>
      )}
    </CrudList>
  );
};
