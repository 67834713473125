import React from 'react';

import { Meter } from '@m3ter-com/m3ter-api';

import { MeterForm } from '@/components/features/meters/MeterForm/MeterForm';
import { CrudEdit } from '@/components/common/crud/CrudEdit';

export const MetersEditRoute: React.FC = () => (
  <CrudEdit<Meter> form={MeterForm} />
);
