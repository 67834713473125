import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { Badge, Link } from '@chakra-ui/react';

import { DataType, Plan } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';

import { EntityRouteListIds } from '@/types/lists';

import { selectSelectedProductId } from '@/store/products/products';
import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { ProductSelector } from '@/components/features/products/ProductSelector/ProductSelector';

const searchFields = ['code', 'name'];
const relationships = ['planTemplate'];

export const PlansListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<Plan>>>(
    () => [
      { id: 'name', header: t('forms:labels.name'), accessor: 'name' },
      { id: 'code', header: t('forms:labels.code'), accessor: 'code' },
      {
        id: 'plan-type',
        header: t('features:plans.planType'),
        accessor: (item: EntityWithRelationships<Plan>) =>
          item.accountId && (
            <Badge colorScheme="green">{t('common:custom')}</Badge>
          ),
      },
      {
        id: 'planTemplate',
        header: t('common:planTemplate'),
        accessor: (item: EntityWithRelationships<Plan>) =>
          !!item.planTemplate && (
            <Link
              as={CrudDetailsLink}
              dataType={DataType.PlanTemplate}
              id={item.planTemplateId}
            >
              {item.planTemplate.name}
            </Link>
          ),
      },
    ],
    [t]
  );
  const productId = useSelector(selectSelectedProductId)!;
  const params = useMemo(() => ({ productId }), [productId]);

  return (
    <CrudList<Plan>
      listId={EntityRouteListIds.Plan}
      params={params}
      relationships={relationships}
      searchFields={searchFields}
    >
      <CrudListHeader>
        <ProductSelector />
      </CrudListHeader>
      <CrudListTable columns={columns} />
      <CrudListFooter />
    </CrudList>
  );
};
