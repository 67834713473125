import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { RunBillingFormValues } from '@/components/features/billing/RunBillingModal/RunBillingModal';

const suite = create(
  'runBillingModal',
  (data: Partial<RunBillingFormValues>) => {
    test(
      'externalInvoiceDate',
      i18next.t('forms:validations.billing.invoiceDateRequired'),
      () => {
        enforce(data.externalInvoiceDate).isNotEmpty();
      }
    );
  }
);

export default suite;
