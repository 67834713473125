import React, { useMemo } from 'react';

import {
  Card,
  CardBody,
  CardHeader,
  Grid,
  Heading,
  Link,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { DataType, Id } from '@m3ter-com/m3ter-api';
import { KeyValue } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { formatNumber } from '@m3ter-com/console-core/utils';

import { PricingDataType } from '@/types/data';

import { getFrequencyDescription } from '@/util/billing';
import { formatEntityUnit } from '@/util/data';
import useQueryString from '@/hooks/navigation/useQueryString';
import useParsedSegment from '@/hooks/features/aggregations/useParsedSegment';
import usePricingSchedule from '@/hooks/features/pricing/usePricingSchedule';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import {
  PricingSchedule,
  PricingScheduleProps,
} from '@/components/features/pricing/PricingSchedule/PricingSchedule';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch/LoadingErrorContentSwitch';
import { ItemCounterDetails } from '@/components/features/item-counters/ItemCounterDetails/ItemCounterDetails';
import { AuditData } from '@/components/common/data/AuditData/AuditData';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';

interface PricingScheduleQueryParams {
  planId?: Id;
  planTemplateId?: Id;
  aggregationId?: Id;
  compoundAggregationId?: Id;
  counterId?: Id;
  segment?: string;
}

export const PricingScheduleRoute: React.FC = () => {
  const { t } = useTranslation();
  const {
    planId,
    planTemplateId,
    aggregationId,
    compoundAggregationId,
    counterId,
    segment: stringifiedSegment,
  } = useQueryString<PricingScheduleQueryParams>();

  const segment = useParsedSegment(stringifiedSegment);
  const {
    isLoading,
    error,
    pricings,
    itemCounterPricings,
    plan,
    planTemplate,
    aggregation,
    compoundAggregation,
    itemCounter,
  } = usePricingSchedule({
    planId,
    planTemplateId,
    aggregationId,
    compoundAggregationId,
    counterId,
    segment,
  });

  const createPricingQueryParams = useMemo(
    () => ({
      planId: plan?.id,
      planTemplateId: planTemplate?.id,
      aggregationId: aggregation?.id,
      compoundAggregationId: compoundAggregation?.id,
      counterId: itemCounter?.id,
      segment: stringifiedSegment,
    }),
    [
      plan,
      planTemplate,
      aggregation,
      compoundAggregation,
      itemCounter,
      stringifiedSegment,
    ]
  );

  const pricingScheduleProps = useMemo<PricingScheduleProps<PricingDataType>>(
    () => ({
      ...(counterId
        ? {
            pricings: itemCounterPricings,
            dataType: DataType.CounterPricing,
          }
        : { pricings, dataType: DataType.Pricing }),
      currencyCode: planTemplate?.currency ?? '',
      createPricingQueryParams,
      isForPlan: !!plan,
    }),
    [
      createPricingQueryParams,
      counterId,
      itemCounterPricings,
      plan,
      planTemplate,
      pricings,
    ]
  );

  const entityName = counterId
    ? t('common:counterPricing')
    : t('common:pricing');

  const planData = plan ?? planTemplate;
  const aggregationData = aggregation ?? compoundAggregation;

  return (
    <React.Fragment>
      <BreadcrumbItem
        title={t('features:pricing.pricingEntitySchedule', { entityName })}
      />
      <LoadingErrorContentSwitch isLoading={isLoading} error={error}>
        <VStack spacing={4} alignItems="stretch">
          <Card>
            <CardHeader>
              <Heading size="md">
                {t('common:entityDetails', {
                  entityName: plan
                    ? t('common:plan')
                    : t('common:planTemplate'),
                })}
              </Heading>
            </CardHeader>
            <CardBody>
              <Stack
                w="100%"
                h="100%"
                spacing={4}
                justifyContent="space-between"
                divider={<StackDivider />}
              >
                <Grid
                  h="100%"
                  gap={4}
                  templateColumns={`repeat(${plan ? 3 : 2}, 1fr)`}
                >
                  {plan && (
                    <KeyValue
                      label={t('forms:labels.name')}
                      value={
                        <Link
                          as={CrudDetailsLink}
                          dataType={DataType.Plan}
                          id={plan.id}
                        >
                          {plan.name}
                        </Link>
                      }
                    />
                  )}
                  {planTemplate && (
                    <React.Fragment>
                      <KeyValue
                        label={
                          plan
                            ? t('common:planTemplate')
                            : t('forms:labels.name')
                        }
                        value={
                          <Link
                            as={CrudDetailsLink}
                            dataType={DataType.PlanTemplate}
                            id={planTemplate.id}
                          >
                            {planTemplate.name}
                          </Link>
                        }
                      />
                      <KeyValue
                        label={t('forms:labels.billFrequency')}
                        value={getFrequencyDescription(
                          planTemplate.billFrequencyInterval,
                          planTemplate.billFrequency
                        )}
                      />
                    </React.Fragment>
                  )}
                </Grid>
                {planData && <AuditData data={planData} variant="horizontal" />}
              </Stack>
            </CardBody>
          </Card>
          {aggregationData && (
            <Card>
              <CardHeader>
                <Heading size="md">
                  {t('common:entityDetails', {
                    entityName: aggregation
                      ? t('common:aggregation')
                      : t('common:compoundAggregation'),
                  })}
                </Heading>
              </CardHeader>
              <CardBody>
                <Stack
                  w="100%"
                  h="100%"
                  spacing={4}
                  justifyContent="space-between"
                  divider={<StackDivider />}
                >
                  <Grid h="100%" gap={4} templateColumns="repeat(4, 1fr)">
                    {aggregation && (
                      <React.Fragment>
                        <KeyValue
                          label={t('forms:labels.name')}
                          value={
                            <Link
                              as={CrudDetailsLink}
                              dataType={DataType.Aggregation}
                              id={aggregation.id}
                            >
                              {aggregation.name}
                            </Link>
                          }
                        />
                        <KeyValue
                          label={t('common:aggregation')}
                          value={t(
                            `features:usage.aggregationType.${aggregation.aggregation}`
                          )}
                        />
                        <KeyValue
                          label={t('forms:labels.unit')}
                          value={formatEntityUnit(aggregation.unit)}
                        />
                        <KeyValue
                          label={t('forms:labels.quantityPerUnit')}
                          value={formatNumber(aggregation.quantityPerUnit)}
                        />
                        <KeyValue
                          label={t('forms:labels.rounding')}
                          value={t(`common:rounding.${aggregation.rounding}`)}
                        />
                      </React.Fragment>
                    )}
                    {compoundAggregation && (
                      <React.Fragment>
                        <KeyValue
                          label={t('forms:labels.name')}
                          value={
                            <Link
                              as={CrudDetailsLink}
                              dataType={DataType.CompoundAggregation}
                              id={compoundAggregation.id}
                            >
                              {compoundAggregation.name}
                            </Link>
                          }
                        />
                        <KeyValue
                          label={t('forms:labels.unit')}
                          value={formatEntityUnit(compoundAggregation.unit)}
                        />
                        <KeyValue
                          label={t('forms:labels.quantityPerUnit')}
                          value={formatNumber(
                            compoundAggregation.quantityPerUnit
                          )}
                        />
                        <KeyValue
                          label={t('forms:labels.rounding')}
                          value={t(
                            `common:rounding.${compoundAggregation.rounding}`
                          )}
                        />
                      </React.Fragment>
                    )}
                    {segment && (
                      <KeyValue
                        label={t('features:aggregation.segment')}
                        value={Object.entries(segment)
                          .map(
                            ([fieldName, value]) => `${fieldName} = ${value}`
                          )
                          .join(' & ')}
                      />
                    )}
                  </Grid>
                  {aggregationData && (
                    <AuditData data={aggregationData} variant="horizontal" />
                  )}
                </Stack>
              </CardBody>
            </Card>
          )}
          {itemCounter && <ItemCounterDetails data={itemCounter} />}
          <PricingSchedule {...pricingScheduleProps} />
        </VStack>
      </LoadingErrorContentSwitch>
    </React.Fragment>
  );
};
