import React from 'react';

import { StyleProps } from '@chakra-ui/react';

import { Button } from '@m3ter-com/ui-components';

import { NamedLink } from '@/components/common/navigation/NamedLink/NamedLink';

import { useCrudListContext } from './CrudListContext';

export interface CrudListCreateLinkProps extends StyleProps {
  isDisabled?: boolean;
}

export const CrudListCreateLink = React.forwardRef<
  HTMLAnchorElement,
  CrudListCreateLinkProps
>(({ isDisabled = false, ...styleProps }, ref) => {
  const { createRouteLabel, createRouteName, createRouteParams } =
    useCrudListContext();

  return createRouteName ? (
    <Button
      ref={ref}
      as={NamedLink}
      name={createRouteName}
      intent="primary"
      isDisabled={isDisabled}
      queryParams={createRouteParams}
      {...styleProps}
    >
      {createRouteLabel}
    </Button>
  ) : null;
});
