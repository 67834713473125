import React from 'react';

import { PlanTemplate } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { PlanTemplateForm } from '@/components/features/pricing/PlanTemplateForm/PlanTemplateForm';

export const PlanTemplatesEditRoute: React.FC<{}> = () => (
  <CrudEdit<PlanTemplate> form={PlanTemplateForm} />
);
