import React from 'react';

import { Badge, Heading, Link, VStack } from '@chakra-ui/react';

import { DataType, Counter } from '@m3ter-com/m3ter-api';
import { KeyValueList, KeyValueListItem } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { formatEntityUnit } from '@/util/data';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';

export interface PricingGridItemCounterDetailsProps {
  itemCounter: Counter;
}

export const PricingGridItemCounterDetails: React.FC<
  PricingGridItemCounterDetailsProps
> = ({ itemCounter }) => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="start">
      <Link
        as={CrudDetailsLink}
        dataType={DataType.Counter}
        id={itemCounter.id}
      >
        <Heading as="h3" size="sm" maxW="300px" wordBreak="break-word">
          {itemCounter.name}
        </Heading>
      </Link>
      <Badge variant="outline" display="block" w="max-content">
        {t('common:counter')}
      </Badge>
      <KeyValueList>
        <KeyValueListItem
          label={t('forms:labels.unit')}
          value={formatEntityUnit(itemCounter.unit)}
        />
      </KeyValueList>
    </VStack>
  );
};
