import React from 'react';

import { Spinner } from '@chakra-ui/react';

import { DataType, Id } from '@m3ter-com/m3ter-api';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import useQueryString from '@/hooks/navigation/useQueryString';
import useLatestEntityIntegrationRun from '@/hooks/features/integrations/useLatestEntityIntegrationRun';
import { NamedNavigate } from '@/components/common/navigation/NamedNavigate/NamedNavigate';

interface IntegrationRunQueryParams {
  entityId: Id;
  entityType: string;
}

export const IntegrationRunRedirectRoute: React.FC = () => {
  const { entityType, entityId } = useQueryString<IntegrationRunQueryParams>();

  const { latestIntegrationRun } = useLatestEntityIntegrationRun(
    entityType,
    entityId
  );

  const detailsRouteName = getCrudRouteName(
    DataType.IntegrationRun,
    CrudRouteType.Details
  );

  return latestIntegrationRun ? (
    <NamedNavigate
      replace
      name={detailsRouteName}
      params={{
        integrationRunId: latestIntegrationRun?.id,
      }}
    />
  ) : (
    <Spinner />
  );
};
