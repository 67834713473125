import i18next from 'i18next';

import { DataType, Currency } from '@m3ter-com/m3ter-api';

import { EntityRouteListIds } from '@/types/lists';
import { NamedRoute } from '@/types/routes';

import { createPickListRoute } from '@/routes/picklist';
import { ColumnDefinition } from '@/components/common/crud/CrudList';
import { CurrencyForm } from '@/components/features/organization/CurrencyForm/CurrencyForm';

export default (): NamedRoute => {
  const listExtraColumns: Array<ColumnDefinition<Currency>> = [
    {
      id: 'maxDecimalPlaces',
      header: i18next.t('forms:labels.maxDecimalPlaces'),
      accessor: 'maxDecimalPlaces',
    },
  ];

  return createPickListRoute<Currency>({
    path: 'currencies',
    dataType: DataType.Currency,
    title: i18next.t('common:currencys'),
    listId: EntityRouteListIds.Currency,
    form: CurrencyForm,
    listExtraColumns,
  });
};
