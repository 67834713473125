import React, { useCallback, useMemo } from 'react';

import {
  Badge,
  ButtonGroup,
  Card,
  CardBody,
  HStack,
  Heading,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { ServiceUser, ServiceUserCredential } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Button,
  CardActionsHeader,
  DataTable,
  DataTableColumnDefinition,
} from '@m3ter-com/ui-components';

import useServiceUserCredentials from '@/hooks/features/access/useServiceUserCredentials';
import { ErrorAlerts } from '@/components/common/errors/ErrorAlerts/ErrorAlerts';

import { ServiceUserCredentialModal } from './ServiceUserCredentialsModal';

interface ServiceUserCredentialsProps {
  serviceUser: ServiceUser;
}

const MAX_ALLOWED_CREDENTIALS = 2;

export const ServiceUserCredentialList: React.FC<
  ServiceUserCredentialsProps
> = ({ serviceUser }) => {
  const { t } = useTranslation();
  const {
    isFetchingServiceUserCredentials,
    isLoadingServiceUserCredentials,
    isSavingServiceUserCredentials,
    isUpdatingServiceUserCredentials,
    newServiceUserCredentials,
    onActivateServiceUserCredentials,
    onCreateServiceUserCredentials,
    onDeactivateServiceUserCredentials,
    onDeleteServiceUserCredentials,
    onRemoveServiceUserCredentials,
    serviceUserCredentials,
    serviceUserCredentialsLoadError,
    serviceUserCredentialsCreateError,
    serviceUserCredentialsUpdateError,
  } = useServiceUserCredentials(serviceUser.id);

  const onActionStatusButtonClick = useCallback(
    (row: ServiceUserCredential) => {
      if (row.inactive) {
        onActivateServiceUserCredentials(row.apiKey);
      } else {
        onDeactivateServiceUserCredentials(row.apiKey);
      }
    },
    [onActivateServiceUserCredentials, onDeactivateServiceUserCredentials]
  );

  const columns = useMemo<
    Array<DataTableColumnDefinition<ServiceUserCredential>>
  >(
    () => [
      {
        id: 'accessKeyId',
        header: t('features:users.serviceUsers.accessKeyId'),
        accessor: (row: ServiceUserCredential) => row.apiKey,
      },
      {
        id: 'status',
        header: t('features:users.serviceUsers.status'),
        accessor: (row: ServiceUserCredential) =>
          row.inactive ? (
            <Badge variant="outline">{t('common:inactive')}</Badge>
          ) : (
            <Badge colorScheme="green">{t('common:active')}</Badge>
          ),
      },
      {
        id: 'actions',
        header: '',
        accessor: (row: ServiceUserCredential) => (
          <ButtonGroup>
            <Button size="sm" onClick={() => onActionStatusButtonClick(row)}>
              {row.inactive
                ? t('features:users.serviceUsers.activate')
                : t('features:users.serviceUsers.deactivate')}
            </Button>
            <Button
              size="sm"
              onClick={() => onDeleteServiceUserCredentials(row.apiKey)}
              isDisabled={row.inactive === false}
            >
              {t('common:remove')}
            </Button>
          </ButtonGroup>
        ),
        align: 'right',
      },
    ],
    [t, onActionStatusButtonClick, onDeleteServiceUserCredentials]
  );

  const errors = useMemo(
    () => [
      serviceUserCredentialsLoadError,
      serviceUserCredentialsCreateError,
      serviceUserCredentialsUpdateError,
    ],
    [
      serviceUserCredentialsCreateError,
      serviceUserCredentialsLoadError,
      serviceUserCredentialsUpdateError,
    ]
  );

  const isLoading =
    isFetchingServiceUserCredentials ||
    isUpdatingServiceUserCredentials ||
    isLoadingServiceUserCredentials;

  return (
    <Card>
      <CardActionsHeader
        actions={
          serviceUserCredentials.length < MAX_ALLOWED_CREDENTIALS ? (
            <Button
              size="sm"
              onClick={onCreateServiceUserCredentials}
              isLoading={isSavingServiceUserCredentials}
            >
              {t('features:users.serviceUsers.generateAccessKey')}
            </Button>
          ) : (
            <Tooltip
              label={t('features:access.serviceUsersCredentialsWarning', {
                entityName: serviceUser.name,
              })}
              placement="top"
            >
              <Button size="sm" isDisabled>
                {t('features:users.serviceUsers.generateAccessKey')}
              </Button>
            </Tooltip>
          )
        }
      >
        <Heading size="md">
          {t('features:users.serviceUsers.accessKeys')}
        </Heading>
      </CardActionsHeader>
      <CardBody>
        <ErrorAlerts errors={errors} />
        <React.Fragment>
          {newServiceUserCredentials && (
            <ServiceUserCredentialModal
              isOpen
              credential={newServiceUserCredentials}
              onClose={onRemoveServiceUserCredentials}
            />
          )}
          <DataTable<ServiceUserCredential>
            items={serviceUserCredentials}
            columnDefinitions={columns}
            idAccessor="apiKey"
            isLoading={isLoading}
            emptyContent={
              <HStack justifyContent="center" alignItems="center">
                <Text>
                  {t('features:users.serviceUsers.noAccesKeysGenerated')}
                </Text>
              </HStack>
            }
          />
        </React.Fragment>
      </CardBody>
    </Card>
  );
};
