import React from 'react';

import { CompoundAggregation } from '@m3ter-com/m3ter-api';

import { CompoundAggregationForm } from '@/components/features/aggregations/CompoundAggregationForm/CompoundAggregationForm';
import { CrudEdit } from '@/components/common/crud/CrudEdit';

export const CompoundAggregationsEditRoute: React.FC = () => (
  <CrudEdit<CompoundAggregation> form={CompoundAggregationForm} />
);
