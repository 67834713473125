import React, { PropsWithChildren, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { DataType } from '@m3ter-com/m3ter-api';

import {
  NamedLink,
  NamedLinkProps,
} from '@/components/common/navigation/NamedLink/NamedLink';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';

export interface CrudCreateLinkProps
  extends Omit<NamedLinkProps, 'name' | 'params'> {
  dataType: DataType;
  queryParams?: Record<string, any>;
  addReturnPath?: boolean;
}

export const CrudCreateLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<CrudCreateLinkProps>
>(
  (
    { dataType, children, queryParams, addReturnPath = false, ...props },
    ref
  ) => {
    // Get the path where the link is rendered to allow `addReturnPath`
    const location = useLocation();

    const combinedQueryParams = useMemo(
      () =>
        addReturnPath
          ? {
              ...queryParams,
              returnPath: `${location.pathname}${location.search}`,
            }
          : queryParams,
      [queryParams, addReturnPath, location]
    );

    return (
      <NamedLink
        ref={ref}
        name={getCrudRouteName(dataType, CrudRouteType.Create)}
        queryParams={combinedQueryParams}
        {...props}
      >
        {children}
      </NamedLink>
    );
  }
);
