import React from 'react';

import { Spinner } from '@chakra-ui/react';

import { IntegrationCredential } from '@m3ter-com/m3ter-api';

import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { CrudCreate } from '@/components/common/crud/CrudCreate/CrudCreate';
import { useIntegrationConfigSchemaContext } from '@/components/features/integrations/IntegrationConfigSchemaContext/IntegrationConfigSchemaContext';
import { IntegrationCredentialCreateForm } from '@/components/features/integrations/IntegrationCredentialCreateForm/IntegrationCredentialCreateForm';

export const IntegrationCredentialCreateRoute: React.FC = () => {
  const { configSchemaError, isLoadingConfigSchema } =
    useIntegrationConfigSchemaContext();

  if (isLoadingConfigSchema) {
    return <Spinner />;
  }

  if (configSchemaError) {
    return <ErrorAlert error={configSchemaError} />;
  }

  return (
    <CrudCreate<IntegrationCredential> form={IntegrationCredentialCreateForm} />
  );
};
