import React from 'react';

import { Balance } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails/CrudDetails';
import { BalanceDetails } from '@/components/features/accounts/BalanceDetails/BalanceDetails';

export const BalancesDetailsRoute: React.FC = () => (
  <CrudDetails<Balance> component={BalanceDetails} />
);
