import React, { useMemo } from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import {
  Tab,
  TabbedRoute,
} from '@/components/common/navigation/TabbedRoute/TabbedRoute';

export const AccessSettingsRoute: React.FC = () => {
  const { t } = useTranslation();

  const tabs = useMemo<Array<Tab>>(
    () => [
      { to: 'users', text: t('common:users') },
      { to: 'invitations', text: t('common:invitations') },
      { to: 'service-users', text: t('common:serviceUsers') },
      { to: 'user-groups', text: t('common:userGroups') },
      { to: 'permission-policies', text: t('common:permissionPolicys') },
      { to: 'support-access', text: t('common:supportAccess') },
    ],
    [t]
  );

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:access')} />
      <TabbedRoute tabs={tabs} />
    </React.Fragment>
  );
};
