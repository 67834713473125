import React from 'react';

import { DataType, Account } from '@m3ter-com/m3ter-api';

import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';
import { AccountChildren } from '@/components/features/accounts/AccountChildren/AccountChildren';

export const AccountChildrenRoute: React.FC = () => {
  const account = useCrudRetrieveData<Account>(DataType.Account);

  return <AccountChildren account={account} />;
};
