import React from 'react';

import { useSelector } from 'react-redux';

import { selectCurrentOrg } from '@/store/app/bootstrap/bootstrap';
import { OrganizationOverview } from '@/components/features/organization/OrganizationOverview/OrganizationOverview';

export const OrganizationOverviewRoute: React.FC = () => {
  const currentOrg = useSelector(selectCurrentOrg);

  return <OrganizationOverview editConfigUrl="edit" org={currentOrg} />;
};
