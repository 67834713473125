import React from 'react';

import {
  DatabaseIcon,
  FileClockIcon,
  FileSpreadsheetIcon,
  ListCollapseIcon,
  ReceiptTextIcon,
} from 'lucide-react';

import { DataType } from '@m3ter-com/m3ter-api';
import { Navigation } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { getCrudRouteName, CrudRouteType } from '@/routes/crud';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import { NamedNavLink } from '@/components/common/navigation/NamedNavLink/NamedNavLink';

export const BillingNavigation: React.FC = () => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();

  return (
    <Navigation>
      <NamedNavLink
        name={getCrudRouteName(DataType.Bill, CrudRouteType.List)}
        icon={<ReceiptTextIcon />}
      >
        {t('features:billing.billManagement')}
      </NamedNavLink>
      <NamedNavLink name="billing.history" icon={<FileClockIcon />}>
        {t('features:billing.billHistory')}
      </NamedNavLink>
      {isFeatureEnabled(Feature.Statements) && (
        <NamedNavLink
          name={getCrudRouteName(
            DataType.StatementDefinition,
            CrudRouteType.List
          )}
          icon={<ListCollapseIcon />}
        >
          {t('common:statementDefinitions')}
        </NamedNavLink>
      )}
      {isFeatureEnabled(Feature.Reports) && (
        <NamedNavLink name="billing.reports" icon={<FileSpreadsheetIcon />}>
          {t('common:reports')}
        </NamedNavLink>
      )}
      <NamedNavLink name="billing.data-explorer" icon={<DatabaseIcon />}>
        {t('features:dataExplorer.billing.title')}
      </NamedNavLink>
    </Navigation>
  );
};
