import React, { useCallback } from 'react';

import { useResolvedPath } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';

import { DataType, CustomFieldDefaults } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useSingleton from '@/hooks/data/useSingleton';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { OrganizationCustomFieldsForm } from '@/components/features/organization/OrganizationCustomFieldsForm/OrganizationCustomFieldsForm';

export const OrganizationCustomFieldsEditRoute: React.FC = () => {
  const { t } = useTranslation();

  const path = useResolvedPath('..');

  const {
    error,
    isLoading,
    isSaving,
    data: customFields,
    update,
  } = useSingleton<CustomFieldDefaults>(DataType.OrganizationCustomFields);

  const onSave = useCallback(
    (data: CustomFieldDefaults) => {
      update(data, undefined, undefined, path.pathname);
    },
    [update, path]
  );

  return (
    <>
      <BreadcrumbItem title={t('common:edit')} />
      {error && <ErrorAlert error={error} />}
      {isLoading || !customFields ? (
        <Spinner />
      ) : (
        <OrganizationCustomFieldsForm
          initialValues={customFields}
          isSaving={isSaving}
          returnUrl={path.pathname}
          onSave={onSave}
        />
      )}
    </>
  );
};
