import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const table: ComponentMultiStyleConfig = {
  parts: ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'tfoot', 'caption'],
  sizes: {
    md: {
      td: {
        px: 4,
        py: 2,
      },
      th: {
        px: 4,
        py: 2,
      },
    },
  },
  variants: {
    'report-table': (props) => {
      return {
        table: {
          height: 'fit-content',
          position: 'relative',
          borderCollapse: 'separate',
          borderSpacing: 0,
          zIndex: 0,
        },
        tr: {
          '> th:first-of-type, > td:first-of-type': {
            position: 'sticky',
            left: 0,
            width: '1%',
            textAlign: 'left',
            boxShadow: `0px 0px 8px ${props.colorScheme}`,
            clipPath: 'inset(0px -8px 0 0)',
            zIndex: 1,
          },
        },
        th: {
          backgroundColor: mode('white', 'gray.700')(props),
          borderWidth: '1px',
          textAlign: 'right',
          whiteSpace: 'nowrap',
        },
        td: {
          backgroundColor: mode('white', 'gray.700')(props),
          borderWidth: '1px',
          textAlign: 'right',
          whiteSpace: 'nowrap',
        },
      };
    },
    simple: (props) => {
      return {
        th: {
          bg: mode('gray.100', 'gray.800')(props),
          fontWeight: 'bold',
          borderWidth: 1,
          textTransform: 'none',
          letterSpacing: 'normal',
          fontSize: 'inherit',
        },
        td: {
          borderWidth: 1,
        },
        tbody: {
          tr: {
            bg: 'chakra-subtle-bg',
            '&:nth-of-type(even):not(:hover)': {
              background: mode('gray.50', 'gray.600')(props),
            },
          },
        },
      };
    },
  },
};

export default table;
