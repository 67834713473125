import React, { PropsWithChildren, ReactNode } from 'react';

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Stack,
  StackDivider,
} from '@chakra-ui/react';

import { DataType, Entity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import { AuditData } from '@/components/common/data/AuditData/AuditData';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink/CrudEditLink';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { MetadataCard } from '@/components/common/data/MetadataCard/MetadataCard';

export interface DetailsCardProps {
  details: ReactNode;
  extraDetails?: ReactNode;
  header?: ReactNode;
  dataType: DataType;
  data: Entity;
  entityNameOverride?: string;
  showEditButton?: boolean;
  showEditors?: boolean;
  showId?: boolean;
  showMetadata?: boolean;
}

export const DetailsCard: React.FC<PropsWithChildren<DetailsCardProps>> = ({
  children,
  details,
  extraDetails,
  header,
  dataType,
  data,
  entityNameOverride,
  showEditButton = false,
  showEditors = true,
  showId = true,
  showMetadata = true,
}) => {
  const { t } = useTranslation();

  const entityNamings = useEntityNamings(dataType);
  const entityName = entityNameOverride ?? entityNamings.singular;

  return (
    <Stack spacing={4}>
      <Card data-testid="details-card">
        <CardHeader>
          <Flex justify="space-between" alignItems="center">
            <Heading size="md" whiteSpace="nowrap">
              {t('common:entityDetails', { entityName })}
            </Heading>
            {header}
            {showEditButton && (
              <Button
                intent="primary"
                size="sm"
                as={CrudEditLink}
                addReturnPath
                dataType={dataType}
                id={data.id}
              >
                {t('common:edit')}
              </Button>
            )}
          </Flex>
        </CardHeader>
        <CardBody>
          <Stack
            alignItems="stretch"
            divider={<StackDivider />}
            flexFlow="column nowrap"
            gap={4}
            justifyContent="flex-start"
            width="100%"
          >
            <Box>{details}</Box>
            {extraDetails && <Box>{extraDetails}</Box>}
          </Stack>
        </CardBody>
        <CardFooter>
          <AuditData
            data={data}
            showEditors={showEditors}
            showId={showId}
            variant="horizontal"
          />
        </CardFooter>
      </Card>
      {children}
      {showMetadata && (
        <MetadataCard
          data={data}
          dataType={dataType}
          entityNameOverride={entityNameOverride}
        />
      )}
    </Stack>
  );
};
