import React from 'react';

import { Product } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails/CrudDetails';
import { ProductDetails } from '@/components/features/products/ProductDetails/ProductDetails';

export const ProductsDetailsRoute: React.FC = () => (
  <CrudDetails<Product> component={ProductDetails} />
);
