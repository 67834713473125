import React, { ReactElement, useMemo } from 'react';

import { Divider, Tr, Td, useDisclosure, SimpleGrid } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { DataTypeToEntity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  CaretDownIcon,
  CaretRightIcon,
  IconButton,
  KeyValue,
} from '@m3ter-com/ui-components';
import {
  getPricingDescription,
  isActive,
  isPlanPricing,
  isPlanTemplatePricing,
  isPricing,
} from '@m3ter-com/console-core/utils';

import { PricingDataType } from '@/types/data';

import { getBillInAdvanceDescription } from '@/util/billing';
import useEntityDelete from '@/hooks/data/crud/useEntityDelete';
import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge/ActiveStatusBadge';
import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';
import { AuditData } from '@/components/common/data/AuditData/AuditData';
import { EntityOrOrgConfigValue } from '@/components/common/data/EntityOrOrgConfigValue/EntityOrOrgConfigValue';
import { PricingSummary } from '@/components/features/pricing/PricingSummary/PricingSummary';

export interface PricingScheduleRowProps<DT extends PricingDataType> {
  pricing: DataTypeToEntity[DT];
  currencyCode: string;
  anyActivePlanPricing: boolean;
  isForPlan: boolean;
  dataType: DT;
}

export function PricingScheduleRow<DT extends PricingDataType>({
  pricing,
  currencyCode,
  anyActivePlanPricing,
  isForPlan,
  dataType,
}: PricingScheduleRowProps<DT>): ReactElement<any, any> | null {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { toLongDateTime } = useDateFormatter();

  const location = useLocation();
  const { deleteItem } = useEntityDelete(dataType);

  const editQueryParams = useMemo(
    () => ({ returnPath: `${location.pathname}${location.search}` }),
    [location]
  );

  const isPricingActive =
    !(isPlanTemplatePricing(pricing) && anyActivePlanPricing) &&
    isActive(pricing);

  const { singular: entityName } = useEntityNamings(dataType);

  const { isOpen, onToggle } = useDisclosure();

  const isStandardPricing = isPricing(pricing);

  return (
    <React.Fragment>
      <Tr data-testid="pricing-row">
        <Td paddingLeft={0} paddingRight={0}>
          <IconButton
            aria-label={
              isOpen
                ? t('features:pricing.collapseDetails')
                : t('features:pricing.expandDetails')
            }
            icon={isOpen ? <CaretDownIcon /> : <CaretRightIcon />}
            size="xs"
            onClick={onToggle}
            data-testid="pricing-row-expand-collapse"
          />
        </Td>
        <Td>{toLongDateTime(pricing.startDate)}</Td>
        <Td>{pricing.endDate ? toLongDateTime(pricing.endDate) : '-'}</Td>
        <Td>
          <ActiveStatusBadge active={isPricingActive} ml={0} />
        </Td>
        {isForPlan && (
          <Td>
            {isPlanPricing(pricing)
              ? t('common:plan')
              : t('common:planTemplate')}
          </Td>
        )}
        <Td>{getPricingDescription(pricing, currencyCode, formatCurrency)}</Td>
        {isStandardPricing && (
          <Td>
            {pricing.minimumSpend !== undefined &&
              formatCurrency(pricing.minimumSpend, currencyCode)}
          </Td>
        )}
        <Td>
          {(!isForPlan || !isPlanTemplatePricing(pricing)) && (
            <EntityCrudActions<DataTypeToEntity[DT]>
              dataType={dataType}
              item={pricing}
              editRouteName={getCrudRouteName(dataType, CrudRouteType.Edit)}
              editQueryParams={editQueryParams}
              onDelete={deleteItem}
            />
          )}
        </Td>
      </Tr>
      {isOpen && (
        <React.Fragment>
          <Tr bg="chakra-body-bg" data-testid="pricing-row-details">
            <Td />
            <Td colSpan={isForPlan ? 7 : 6}>
              <SimpleGrid columns={4} spacing={4}>
                <KeyValue
                  label={t('forms:labels.description')}
                  value={pricing.description || '-'}
                />
                <KeyValue
                  gridColumn="4/5"
                  gridRow="1/4"
                  label={entityName}
                  value={
                    <PricingSummary
                      pricing={pricing}
                      currencyCode={currencyCode}
                      showHeading={false}
                    />
                  }
                />
                {isStandardPricing ? (
                  <React.Fragment>
                    <KeyValue
                      label={t('forms:labels.pricingType')}
                      value={t(`features:pricing.applyAsTypes.${pricing.type}`)}
                    />
                    <KeyValue
                      label={t('features:pricing.tiersSpanPlan')}
                      value={
                        pricing.tiersSpanPlan ? t('common:yes') : t('common:no')
                      }
                    />
                    <KeyValue
                      label={t('forms:labels.minimumSpendDescription')}
                      value={pricing.minimumSpendDescription || '-'}
                    />
                    <KeyValue
                      label={t('forms:labels.minimumSpendBilling')}
                      value={
                        <EntityOrOrgConfigValue
                          fieldName="minimumSpendBillInAdvance"
                          entity={pricing}
                          format={getBillInAdvanceDescription}
                        />
                      }
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <KeyValue
                      label={t('forms:labels.proRateRunningTotal')}
                      value={
                        pricing.proRateRunningTotal
                          ? t('common:yes')
                          : t('common:no')
                      }
                    />
                    <KeyValue
                      label={t('forms:labels.proRateAdjustmentDebit')}
                      value={
                        pricing.proRateAdjustmentDebit
                          ? t('common:yes')
                          : t('common:no')
                      }
                    />
                    <KeyValue
                      label={t('forms:labels.proRateAdjustmentCredit')}
                      value={
                        pricing.proRateAdjustmentCredit
                          ? t('common:yes')
                          : t('common:no')
                      }
                    />
                    <KeyValue
                      label={t('forms:labels.runningTotalBilling')}
                      value={getBillInAdvanceDescription(
                        pricing.runningTotalBillInAdvance
                      )}
                    />
                  </React.Fragment>
                )}
              </SimpleGrid>
            </Td>
          </Tr>
          <Tr bg="chakra-body-bg">
            <Td colSpan={isForPlan ? 8 : 7} pb={4}>
              <Divider mb={4} />
              <AuditData data={pricing} variant="horizontal" />
            </Td>
          </Tr>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
