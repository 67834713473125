import React, { ReactNode } from 'react';

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Link,
  SimpleGrid,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';

import { DataType, Contract } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { isContractActive } from '@/util/account';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEntityDelete from '@/hooks/data/crud/useEntityDelete';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge/ActiveStatusBadge';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';

export interface AccountContractsListProps {
  contracts: Array<Contract>;
  header: ReactNode;
  isLoading: boolean;
  noDataMessage: string;
  showActiveStatusBadge?: boolean;
}

export const AccountContractsList: React.FC<AccountContractsListProps> = ({
  contracts,
  header,
  isLoading,
  noDataMessage,
  showActiveStatusBadge = false,
}) => {
  const { t } = useTranslation();
  const { timeZone, toLongDate } = useDateFormatter();
  const { deleteItem } = useEntityDelete(DataType.Contract);

  const hasData = contracts.length > 0;

  return (
    <Card>
      <CardHeader py={3}>{header}</CardHeader>
      <CardBody>
        {isLoading && <Spinner />}
        {!isLoading && !hasData && (
          <Text textAlign="center">{noDataMessage}</Text>
        )}
        {!isLoading && hasData && (
          <VStack alignItems="stretch" spacing={4} divider={<StackDivider />}>
            {contracts.map((contract) => (
              <SimpleGrid
                columns={4}
                key={contract.id}
                alignItems="start"
                data-testid="contract"
              >
                <Heading size="sm">
                  <Link
                    as={CrudDetailsLink}
                    dataType={DataType.Contract}
                    id={contract.id}
                  >
                    {contract.name}
                  </Link>
                  {showActiveStatusBadge && (
                    <ActiveStatusBadge
                      active={isContractActive(contract, timeZone)}
                      inactiveLabel={t('common:pending')}
                    />
                  )}
                </Heading>
                <KeyValue
                  label={t('common:start')}
                  value={toLongDate(contract.startDate)}
                />
                <KeyValue
                  label={t('common:end')}
                  value={toLongDate(contract.endDate)}
                />
                <Box>
                  <EntityCrudActions<Contract>
                    dataType={DataType.Contract}
                    editRouteName={getCrudRouteName(
                      DataType.Contract,
                      CrudRouteType.Edit
                    )}
                    item={contract}
                    onDelete={deleteItem}
                  />
                </Box>
              </SimpleGrid>
            ))}
          </VStack>
        )}
      </CardBody>
    </Card>
  );
};
