import React from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Card,
  CardBody,
  SimpleGrid,
} from '@chakra-ui/react';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import {
  ChartColumnIcon,
  FileSpreadsheetIcon,
  FileTextIcon,
} from 'lucide-react';

import { AlertLink } from '@m3ter-com/ui-components';
import { AnalyticsJobType } from '@m3ter-com/console-core/types';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { NamedLink } from '@/components/common/navigation/NamedLink/NamedLink';
import { ReportCard } from '@/components/features/reports/ReportCard/ReportCard';

export const BillingReportsListRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <SimpleGrid columns={3} gridGap={4} mb={4}>
          <ReportCard
            description={t('features:reports.monthOnMonth.summary')}
            icon={ChartColumnIcon}
            reportType={AnalyticsJobType.MonthOnMonthReport}
            title={t('features:reports.monthOnMonth.title')}
          />
          <ReportCard
            description={t(
              'features:reports.recurringRevenue.standard.summary'
            )}
            icon={FileSpreadsheetIcon}
            reportType={AnalyticsJobType.RecurringRevenueReport}
            title={t('features:reports.recurringRevenue.standard.title')}
          />
          <ReportCard
            description={t(
              'features:reports.recurringRevenue.billingBased.summary'
            )}
            icon={FileTextIcon}
            reportType={AnalyticsJobType.BillingBasedRecurringRevenueReport}
            title={t('features:reports.recurringRevenue.billingBased.title')}
          />
        </SimpleGrid>
        <Alert status="info">
          <AlertIcon />
          <AlertDescription>
            <Trans
              t={t as TFunction}
              i18nKey="features:reports.lookingForAccountReports"
            >
              Looking for…? Head to
              <AlertLink as={NamedLink} name="accounts.reports">
                account reports
              </AlertLink>
            </Trans>
          </AlertDescription>
        </Alert>
      </CardBody>
    </Card>
  );
};
