import {
  DateTimeISOString,
  Id,
  AccountMeasurementsUsageHealthReportResponse,
  UsageAnomaliesRequestBody,
  UsageAnomaliesResponse,
  UsageForecastRequestBody,
  UsageForecastResponse,
  AccountUsageHealthHistoryResponse,
  UsageHealthReportRequestBody,
  UsageHealthReportResponse,
  UsageMonitoringRequestBody,
  UsageMonitoringResponse,
} from '../types';

import { getOrganizationPath } from '../util/path';
import { get, post } from '../client';

interface GetAccountMeasurementsUsageHealthReportData {
  organizationId: Id;
  accountId: Id;
  dateTime: DateTimeISOString;
  nextToken?: string;
  pageSize?: number;
  sortBy?: string;
  sortOrder?: string;
}

interface GetAccountUsageHealthHistoryData {
  organizationId: Id;
  accountId: Id;
  startDate: DateTimeISOString;
  endDate: DateTimeISOString;
}

export const getAccountMeasurementsUsageHealthReport = ({
  organizationId,
  accountId,
  dateTime,
  nextToken,
  pageSize,
  sortBy,
  sortOrder,
}: GetAccountMeasurementsUsageHealthReportData): Promise<AccountMeasurementsUsageHealthReportResponse> =>
  get({
    path: getOrganizationPath('/usageforecasts/healthscores/measurements'),
    pathParams: { organizationId },
    queryParams: {
      accountId,
      dateTime,
      nextToken,
      pageSize,
      sortBy,
      sortOrder,
    },
  }) as Promise<AccountMeasurementsUsageHealthReportResponse>;

export const getAccountUsageHealthHistory = ({
  organizationId,
  accountId,
  startDate,
  endDate,
}: GetAccountUsageHealthHistoryData): Promise<AccountUsageHealthHistoryResponse> =>
  get({
    path: getOrganizationPath('/usageforecasts/healthscores/history'),
    pathParams: { organizationId },
    queryParams: {
      accountId,
      startDate,
      endDate,
    },
  }) as Promise<AccountUsageHealthHistoryResponse>;

export const getUsageAnomalies = (
  organizationId: Id,
  body: UsageAnomaliesRequestBody
): Promise<UsageAnomaliesResponse> =>
  post({
    body,
    path: getOrganizationPath('/usageforecasts/anomalies'),
    pathParams: { organizationId },
  }) as Promise<UsageAnomaliesResponse>;

export const getUsageForecast = (
  organizationId: Id,
  body: UsageForecastRequestBody
): Promise<UsageForecastResponse> =>
  post({
    body,
    path: getOrganizationPath('/usageforecasts'),
    pathParams: { organizationId },
  }) as Promise<UsageForecastResponse>;

export const getUsageHealthReport = (
  organizationId: Id,
  body?: UsageHealthReportRequestBody
): Promise<UsageHealthReportResponse> =>
  post({
    body,
    path: getOrganizationPath('/usageforecasts/healthscores'),
    pathParams: { organizationId },
  }) as Promise<UsageHealthReportResponse>;

export const getUsageMonitoringData = (
  organizationId: Id,
  body: UsageMonitoringRequestBody
): Promise<UsageMonitoringResponse> =>
  post({
    body,
    path: getOrganizationPath('/usageforecasts/monitoring'),
    pathParams: { organizationId },
  }) as Promise<UsageMonitoringResponse>;
