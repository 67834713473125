import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRoute } from '@/components/common/crud/CrudRoute/CrudRoute';

export interface StatementDefinitionsRouteProps {}

export const StatementDefinitionsRoute: React.FC<
  StatementDefinitionsRouteProps
> = () => {
  const { t } = useTranslation();

  return (
    <CrudRoute
      dataType={DataType.StatementDefinition}
      title={t('common:statementDefinitions')}
    />
  );
};
