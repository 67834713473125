import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { Badge, Text } from '@chakra-ui/react';

import { Counter } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { selectSelectedProductIdIfNotGlobal } from '@/store/products/products';
import { ProductSelector } from '@/components/features/products/ProductSelector/ProductSelector';

const searchFields = ['code', 'name'];

export const ItemCountersListRoute: React.FC = () => {
  const { t } = useTranslation();
  const productId = useSelector(selectSelectedProductIdIfNotGlobal);

  const columns = useMemo<Array<ColumnDefinition<Counter>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (item) => (
          <Text>
            {item.name}
            {!item.productId && <Badge ml={2}>{t('common:global')}</Badge>}
          </Text>
        ),
      },
      { id: 'code', header: t('forms:labels.code'), accessor: 'code' },
      { id: 'unit', header: t('forms:labels.unit'), accessor: 'unit' },
    ],
    [t]
  );

  // When a product is selected, we show both global item counters and item counters for that product.
  // If not, we just show global item counters.
  const listParams = useMemo(
    () => ({ productId: productId ? ['', productId] : '' }),
    [productId]
  );

  return (
    <CrudList<Counter>
      listId={EntityRouteListIds.Counter}
      params={listParams}
      searchFields={searchFields}
    >
      <CrudListHeader>
        <ProductSelector includeGlobal />
      </CrudListHeader>
      <CrudListTable columns={columns} />
      <CrudListFooter />
    </CrudList>
  );
};
