import React, { ElementType } from 'react';

import { PickListEntity } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate/CrudCreate';

export interface PickListCreateRouteProps {
  form: ElementType;
}

export const PickListCreateRoute: React.FC<PickListCreateRouteProps> = ({
  form,
}) => <CrudCreate<PickListEntity> form={form} />;
