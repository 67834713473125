import { Fragment, useMemo } from 'react';

import { NotificationRule } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge/ActiveStatusBadge';

const searchFields = ['code', 'name'];

export const NotificationRulesListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<NotificationRule>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (notificationRule) => (
          <Fragment>
            {notificationRule.name}
            <ActiveStatusBadge active={notificationRule.active} />
          </Fragment>
        ),
      },
      {
        id: 'code',
        header: t('forms:labels.code'),
        accessor: 'code',
      },
      {
        id: 'event',
        header: t('forms:labels.event'),
        accessor: 'eventName',
      },
    ],
    [t]
  );

  return (
    <CrudList<NotificationRule>
      listId={EntityRouteListIds.NotificationRules}
      searchFields={searchFields}
      columns={columns}
    />
  );
};
