import React from 'react';

import { Navigate } from 'react-router-dom';

import { NamedRoute } from '@/types/routes';

import { RouteWrapper } from '@/components/routes/helpers/RouteWrapper/RouteWrapper';
import { EventsNavigation } from '@/components/common/navigation/sections/EventsNavigation/EventsNavigation';

import setupAlertsRoute from './alerts';
import setupNotificationsRoute from './notifications';

export default (): NamedRoute => ({
  name: 'events',
  path: 'events',
  element: <RouteWrapper navigation={<EventsNavigation />} />,
  children: [
    { index: true, element: <Navigate replace to="alerts" /> },
    setupAlertsRoute(),
    setupNotificationsRoute(),
  ],
});
