import React, { useMemo } from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { SupportAccessForm } from '@/components/features/access/support-access/SupportAccessForm/SupportAccessForm';
import useSupportAccessUpdate from '@/hooks/features/access/useSupportAccessUpdate';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch/LoadingErrorContentSwitch';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';

export const SupportAccessEditRoute: React.FC = () => {
  const { t } = useTranslation();

  const {
    supportAccess,
    loadError,
    updateError,
    isLoading,
    isSaving,
    onCancel,
    onSave,
    permissionPolicyIds,
  } = useSupportAccessUpdate();

  const initialValues = useMemo(
    () => ({ ...supportAccess, permissionPolicyIds }),
    [supportAccess, permissionPolicyIds]
  );

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:edit')} />
      <LoadingErrorContentSwitch
        showContentOnError
        isLoading={isLoading}
        error={loadError}
      >
        {updateError && <ErrorAlert error={updateError} />}
        {!!supportAccess && (
          <SupportAccessForm
            initialValues={initialValues}
            isSaving={isSaving}
            onCancel={onCancel}
            onSave={onSave}
          />
        )}
      </LoadingErrorContentSwitch>
    </React.Fragment>
  );
};
