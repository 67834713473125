import React, { useCallback } from 'react';

import { Divider, Flex, HStack, Heading, Stack, Text } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormField,
  FormInput,
  FormPasswordInput,
} from '@m3ter-com/console-core/components';
import { ForgotPasswordSubmitFormValues } from '@m3ter-com/console-core/types';

import useAuth from '@/hooks/auth/useAuth';
import usePasswordValidator from '@/hooks/auth/usePasswordValidator';
import forgotPasswordSubmitFormSchema from '@/validation/forgotPasswordSubmitForm';
import { NamedLink } from '@/components/common/navigation/NamedLink/NamedLink';
import { PasswordValidationList } from '@/components/features/auth/PasswordValidationList/PasswordValidationList';
import { AuthSubmitButton } from '@/components/features/auth/AuthSubmitButton/AuthSubmitButton';

const emptyObject = {};

export const ForgotPasswordSubmitForm: React.FC = () => {
  const { t } = useTranslation();
  const { onForgotPasswordSubmit, isLoading } = useAuth(true);

  const { isPasswordValid, onValidatePassword, passwordValidationErrors } =
    usePasswordValidator();

  const onSubmit = useCallback(
    (data: ForgotPasswordSubmitFormValues) => {
      if (isPasswordValid) {
        onForgotPasswordSubmit(data.email, data.code, data.newPassword);
      }
    },
    [isPasswordValid, onForgotPasswordSubmit]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={emptyObject}
      validationSchema={forgotPasswordSubmitFormSchema}
    >
      <FormStack spacing={4}>
        <Flex w="100%" align="center" justify="center">
          <Heading size="md" textAlign="center">
            {t('forms:labels.createNewPassword')}
          </Heading>
        </Flex>
        <FormField
          autoFocus
          isRequired
          name="email"
          type="email"
          label={t('forms:labels.emailAddress')}
          control={FormInput}
          placeholder={t('features:auth.emailPlaceholder')}
          helpText={t('forms:helpText.caseSensitiveEmailAddress')}
        />
        <FormField
          isRequired
          name="code"
          label={t('forms:labels.confirmationCode')}
          control={FormInput}
          placeholder={t('forms:labels.confirmationCode')}
        />
        <FormField
          isRequired
          name="newPassword"
          label={t('forms:labels.newPassword')}
          control={FormPasswordInput}
          placeholder={t('forms:labels.password')}
          onPasswordChange={onValidatePassword}
        />
        <FormField
          isRequired
          name="confirmPassword"
          label={t('forms:labels.confirmPassword')}
          control={FormPasswordInput}
          placeholder={t('forms:labels.confirmPassword')}
        />
        <PasswordValidationList
          passwordValidationErrors={passwordValidationErrors}
        />
        <AuthSubmitButton
          isLoading={isLoading}
          buttonText={t('forms:labels.createNewPassword')}
        />
        <Stack w="100%" spacing={6}>
          <HStack>
            <Divider />
            <Text textStyle="sm" whiteSpace="nowrap">
              {t('features:auth.signInWith')}
            </Text>
            <Divider />
          </HStack>
        </Stack>
        <Button w="100%" as={NamedLink} name="auth.sign-in">
          {t('features:auth.emailAndPassword')}
        </Button>
      </FormStack>
    </Form>
  );
};
