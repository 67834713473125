import { create, enforce, omitWhen, test } from 'vest';
import i18next from 'i18next';

import { Currency } from '@m3ter-com/m3ter-api';
import { FormValidationContext } from '@m3ter-com/console-core/components';

import { CurrencyFormExtraData } from '@/components/features/organization/CurrencyForm/CurrencyForm';

const suite = create(
  'currency',
  (
    data: Partial<Currency>,
    context: FormValidationContext<{}, CurrencyFormExtraData>
  ) => {
    const { isCustomOrEditCurrency } = context.extraData;

    omitWhen(isCustomOrEditCurrency, () => {
      test(
        'code',
        i18next.t('forms:validations.common.currencyRequired'),
        () => {
          enforce(data.code).isNotEmpty();
        }
      );
    });

    omitWhen(!isCustomOrEditCurrency, () => {
      test('name', i18next.t('forms:validations.common.nameRequired'), () => {
        enforce(data.name).isNotEmpty();
      });

      test('code', i18next.t('forms:validations.common.codeRequired'), () => {
        enforce(data.code).isNotEmpty();
      });

      test('code', i18next.t('forms:validations.common.currencyLength'), () => {
        enforce(data.code).lengthEquals(3);
      });

      test(
        'maxDecimalPlaces',
        i18next.t('forms:validations.currency.maxDecimalPlacesRequired'),
        () => {
          enforce(data.maxDecimalPlaces).isNotEmpty();
        }
      );
    });
  }
);

export default suite;
