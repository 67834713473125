import React from 'react';

import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Link,
} from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import useTerms from '@/hooks/auth/useTerms';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { Logo } from '@/components/common/brand/Logo/Logo';

// The terms modal can't be dismissed.
const onClose = () => {};

export const TermsModal: React.FC = () => {
  const { t } = useTranslation();
  const { acceptTerms, termsError, isAcceptingTerms } = useTerms();

  return (
    <Modal isOpen onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          <Logo marginRight="auto" marginLeft="auto" />
        </ModalHeader>
        <ModalBody>
          {termsError && <ErrorAlert error={termsError} />}
          <Text>
            <Trans t={t as TFunction} i18nKey="features:users.terms.details">
              click-accept
              <Link
                href="https://www.m3ter.com/docs/legal/terms-of-service"
                target="_blank"
              >
                tos
              </Link>
              ,
              <Link
                href="https://www.m3ter.com/docs/legal/website-notices"
                target="_blank"
              >
                web
              </Link>
              and
              <Link
                href="https://www.m3ter.com/docs/legal/privacy-notice"
                target="_blank"
              >
                privacy
              </Link>
              .
            </Trans>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            intent="primary"
            isLoading={isAcceptingTerms}
            onClick={acceptTerms}
          >
            {t('features:users.terms.accept')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
