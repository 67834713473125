import React, { useMemo, PropsWithChildren, ReactElement } from 'react';

import { useLocation } from 'react-router-dom';
import { HStack } from '@chakra-ui/react';

import { DataType, Entity } from '@m3ter-com/m3ter-api';

import { Accessor, getAccessorValue } from '@/util/data';
import { getIdParamName } from '@/routes/crud';
import { NamedLink } from '@/components/common/navigation/NamedLink/NamedLink';

import { EntityEditButton } from './EntityEditButton';
import { EntityDuplicateButton } from './EntityDuplicateButton';
import { EntityDeleteButton } from './EntityDeleteButton';

export interface EntityCrudActionsProps<E extends Entity> {
  addReturnPath?: boolean;
  canDelete?: Accessor<E, boolean>;
  canEdit?: Accessor<E, boolean>;
  dataType: DataType;
  duplicateQueryParams?: Record<string, any>;
  duplicateRouteName?: string;
  editQueryParams?: Record<string, any>;
  editRouteName?: string;
  item: E;
  onDelete?: (item: E) => void;
}

export function EntityCrudActions<E extends Entity>({
  dataType,
  item,
  addReturnPath,
  duplicateRouteName,
  duplicateQueryParams,
  editRouteName,
  editQueryParams,
  canDelete,
  canEdit,
  onDelete,
}: PropsWithChildren<EntityCrudActionsProps<E>>): ReactElement<
  any,
  any
> | null {
  const idParam = getIdParamName(dataType);
  const editRouteParams = useMemo(
    () => ({
      [idParam]: item.id,
    }),
    [idParam, item]
  );

  const location = useLocation();

  const combinedEditQueryParams = useMemo(
    () =>
      addReturnPath
        ? {
            ...editQueryParams,
            returnPath: `${location.pathname}${location.search}`,
          }
        : editQueryParams,
    [editQueryParams, addReturnPath, location]
  );

  const combinedDuplicateQueryParams = useMemo(
    () => ({
      ...duplicateQueryParams,
      ...(addReturnPath && {
        returnPath: `${location.pathname}${location.search}`,
      }),
      duplicate: item.id,
    }),
    [duplicateQueryParams, addReturnPath, location, item]
  );

  // To be able to edit we need a route to link to and either no `canEdit` or one that returns true.
  const showEdit =
    !!editRouteName && (!canEdit || getAccessorValue(item, canEdit));
  // To be able to delete we need an `onDelete` and either no `canDelete` or one that returns true.
  const showDelete =
    !!onDelete && (!canDelete || getAccessorValue(item, canDelete));

  return (
    <HStack spacing={2} whiteSpace="nowrap" justifyContent="flex-end">
      {showEdit && (
        <EntityEditButton
          as={NamedLink}
          name={editRouteName}
          params={editRouteParams}
          queryParams={combinedEditQueryParams}
        />
      )}
      {duplicateRouteName && (
        <EntityDuplicateButton
          as={NamedLink}
          name={duplicateRouteName}
          queryParams={combinedDuplicateQueryParams}
        />
      )}
      {showDelete && (
        <EntityDeleteButton
          onConfirm={() => {
            onDelete(item);
          }}
        />
      )}
    </HStack>
  );
}
