import React, { useCallback, useMemo, useState } from 'react';

import { Badge, HStack } from '@chakra-ui/react';

import { DataType, Alert, AlertStatus } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEntityDelete from '@/hooks/data/crud/useEntityDelete';
import { SeverityBadge } from '@/components/common/data/SeverityBadge/SeverityBadge';
import {
  EntityDeleteButton,
  EntityDetailsButton,
} from '@/components/common/data/EntityCrudActions';
import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import { AlertDetailsModal } from '@/components/features/alerts/AlertDetailsModal/AlertDetailsModal';

export const AlertsListRoute: React.FC = () => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();

  const [viewingAlert, setViewingAlert] = useState<Alert | undefined>();
  const onCloseAlertDetails = useCallback(() => {
    setViewingAlert(undefined);
  }, []);

  const { deleteItem } = useEntityDelete(DataType.Alert);

  const columns = useMemo<Array<ColumnDefinition<Alert>>>(
    () => [
      {
        id: 'status',
        header: t('forms:labels.status'),
        accessor: (alert: Alert) => (
          <Badge
            whiteSpace="nowrap"
            colorScheme={alert.status === AlertStatus.New ? 'green' : undefined}
          >
            {t(`features:alerts.statuses.${alert.status}`)}
          </Badge>
        ),
      },
      {
        id: 'createdDate',
        header: t('common:createdDate'),
        accessor: (alert: Alert) => toLongDateTime(alert.createdDate),
      },
      {
        id: 'lastUpdatedDate',
        header: t('common:lastUpdatedDate'),
        accessor: (alert: Alert) => toLongDateTime(alert.lastUpdatedDate),
      },
      {
        id: 'severity',
        header: t('forms:labels.severity'),
        accessor: (alert: Alert) => <SeverityBadge severity={alert.severity} />,
      },
      {
        id: 'type',
        header: t('forms:labels.type'),
        accessor: (alert: Alert) => <Badge>{alert.type}</Badge>,
      },
      {
        id: 'description',
        header: t('forms:labels.description'),
        accessor: 'description',
      },
      {
        id: 'actions',
        header: '',
        accessor: (alert) => (
          <HStack spacing={2} whiteSpace="nowrap" justifyContent="flex-end">
            <EntityDetailsButton onClick={() => setViewingAlert(alert)} />
            <EntityDeleteButton onConfirm={() => deleteItem(alert)} />
          </HStack>
        ),
      },
    ],
    [deleteItem, t, toLongDateTime]
  );

  return (
    <React.Fragment>
      <CrudList<Alert>
        addActions={false}
        columns={columns}
        listId={EntityRouteListIds.Alert}
      />
      {!!viewingAlert && (
        <AlertDetailsModal
          isOpen
          alert={viewingAlert}
          onClose={onCloseAlertDetails}
        />
      )}
    </React.Fragment>
  );
};
