import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRoute } from '@/components/common/crud/CrudRoute/CrudRoute';
import { IntegrationConfigSchemaContextProvider } from '@/components/features/integrations/IntegrationConfigSchemaContext/IntegrationConfigSchemaContext';

export const IntegrationConfigsRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IntegrationConfigSchemaContextProvider>
      <CrudRoute
        dataType={DataType.Integration}
        title={t('features:integrations.configurations')}
      />
    </IntegrationConfigSchemaContextProvider>
  );
};
