import React from 'react';

import { Contract } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails/CrudDetails';
import { ContractDetails } from '@/components/features/accounts/ContractDetails/ContractDetails';

export const ContractsDetailsRoute: React.FC = () => (
  <CrudDetails<Contract> component={ContractDetails} />
);
