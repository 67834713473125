import React from 'react';

import { DataType, IntegrationConfig } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { createCrudRoute } from '@/routes/crud';
import { IntegrationConfigsRoute } from '@/components/routes/integrations/configs/IntegrationConfigsRoute';
import { IntegrationConfigEditRoute } from '@/components/routes/integrations/configs/IntegrationConfigEditRoute';
import { IntegrationConfigCreateRoute } from '@/components/routes/integrations/configs/IntegrationConfigCreateRoute';

const LazyIntegrationConfigsList = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationConfigsList" */ '@/components/features/integrations/IntegrationConfigsList/IntegrationConfigsList'
  ).then((module) => ({ default: module.IntegrationConfigsList }))
);

const LazyIntegrationConfigDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationConfigDetailsRoute" */ '@/components/routes/integrations/configs/IntegrationConfigDetailsRoute'
  ).then((module) => ({ default: module.IntegrationConfigDetailsRoute }))
);

export default (): NamedRoute =>
  createCrudRoute<IntegrationConfig>({
    path: 'configurations',
    dataType: DataType.Integration,
    element: <IntegrationConfigsRoute />,
    list: <LazyIntegrationConfigsList />,
    details: <LazyIntegrationConfigDetailsRoute />,
    edit: <IntegrationConfigEditRoute />,
    create: <IntegrationConfigCreateRoute />,
  });
