import React, { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { CustomFieldDefaults, DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Form, FormActions } from '@m3ter-com/console-core/components';
import { FormStack } from '@m3ter-com/ui-components';

import organizationCustomFieldsSchema from '@/validation/organizationCustomFields';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { FormCustomFieldsEditor } from '@/components/forms/FormCustomFieldsEditor/FormCustomFieldsEditor';

export interface OrganizationCustomFieldsFormProps {
  initialValues: CustomFieldDefaults;
  isSaving: boolean;
  returnUrl: string;
  onSave: (data: CustomFieldDefaults) => void;
}

export const OrganizationCustomFieldsForm: React.FC<
  OrganizationCustomFieldsFormProps
> = ({ initialValues, isSaving, returnUrl, onSave }) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.OrganizationCustomFields);
  const navigate = useNavigate();

  const onCancel = useCallback(() => {
    navigate(returnUrl);
  }, [navigate, returnUrl]);

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={organizationCustomFieldsSchema}
    >
      <FormStack alignItems="stretch" spacing={4}>
        <FormCustomFieldsEditor
          defaultIsOpen
          name="organization"
          title={t('common:organization')}
        />
        <FormCustomFieldsEditor
          defaultIsOpen
          name="product"
          title={t('common:product')}
        />
        <FormCustomFieldsEditor
          defaultIsOpen
          name="meter"
          title={t('common:meter')}
        />
        <FormCustomFieldsEditor
          defaultIsOpen
          name="plan"
          title={t('common:plan')}
        />
        <FormCustomFieldsEditor
          defaultIsOpen
          name="planTemplate"
          title={t('common:planTemplate')}
        />
        <FormCustomFieldsEditor
          defaultIsOpen
          name="account"
          title={t('common:account')}
        />
        <FormCustomFieldsEditor
          defaultIsOpen
          name="accountPlan"
          title={t('common:accountPlan')}
        />
        <FormCustomFieldsEditor
          defaultIsOpen
          name="aggregation"
          title={t('common:aggregation')}
        />
        <FormCustomFieldsEditor
          defaultIsOpen
          name="compoundAggregation"
          title={t('common:compoundAggregation')}
        />
        <FormActions
          isSaving={isSaving}
          submitText={t('forms:buttons.updateEntity', {
            entityName: entityNamings.singularLower,
          })}
          cancelText={t('common:cancel')}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
