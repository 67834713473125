import React from 'react';

import { DataType, CreditLineItem } from '@m3ter-com/m3ter-api';

import { BaseFormProps } from '@/types/forms';

import { MemoLineItemForm } from '@/components/features/billing/MemoLineItemForm/MemoLineItemForm';

interface CreditLineItemFormProps extends BaseFormProps<CreditLineItem> {}

export const CreditLineItemForm: React.FC<CreditLineItemFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  isEdit = false,
  initialValues,
}) => (
  <MemoLineItemForm
    dataType={DataType.CreditReason}
    initialValues={initialValues}
    isEdit={isEdit}
    isSaving={isSaving}
    onCancel={onCancel}
    onSave={onSave}
  />
);
