import { Fragment } from 'react';

import { Link, Text } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

interface IntegrationConfigParameterHelpTextProps {
  docsLink?: string;
  helpText?: string;
}

export const IntegrationConfigParameterHelpText: React.FC<
  IntegrationConfigParameterHelpTextProps
> = ({ docsLink, helpText }) => {
  const { t } = useTranslation();

  if (!docsLink && !helpText) {
    return null;
  }

  return (
    <Fragment>
      {!!helpText && <Text as="span">{helpText}</Text>}
      {!!helpText && <br />}
      {!!docsLink && (
        <Link href={docsLink} target="_blank">
          {t('common:readDocs')}
        </Link>
      )}
    </Fragment>
  );
};
