import React, { useCallback, useMemo } from 'react';

import { Badge } from '@chakra-ui/react';

import { DataType, PickListEntity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import useOrg from '@/hooks/data/crud/useOrg';
import { useCrudContext } from '@/components/common/crud/CrudContext/CrudContext';

export interface PickListListRouteProps<T extends PickListEntity> {
  listId: string;
  extraColumns?: Array<ColumnDefinition<T>>;
}

export function PickListListRoute<
  EntityType extends PickListEntity = PickListEntity
>({ listId, extraColumns = [] }: PickListListRouteProps<EntityType>) {
  const { t } = useTranslation();
  const { orgConfig } = useOrg();
  const { dataType } = useCrudContext<EntityType>();

  const isCurrencyDataType = dataType === DataType.Currency;

  const isItemDeletable = useCallback(
    (item: EntityType) =>
      (isCurrencyDataType && item.code !== orgConfig.currency) ||
      !isCurrencyDataType,
    [isCurrencyDataType, orgConfig]
  );

  const columns = useMemo<Array<ColumnDefinition<EntityType>>>(
    () => [
      { id: 'name', header: t('forms:labels.name'), accessor: 'name' },
      { id: 'code', header: t('forms:labels.code'), accessor: 'code' },
      ...extraColumns,
      ...(isCurrencyDataType
        ? [
            {
              id: 'default',
              header: t('common:default'),
              accessor: (item: EntityType) =>
                !isItemDeletable(item) && (
                  <Badge colorScheme="green">{t('common:default')}</Badge>
                ),
            },
          ]
        : []),
      {
        id: 'archived',
        header: t('forms:labels.archived'),
        accessor: (item) =>
          item.archived && <Badge>{t('forms:labels.archived')}</Badge>,
      },
    ],
    [t, extraColumns, isCurrencyDataType, isItemDeletable]
  );

  return (
    <CrudList<EntityType>
      listId={listId}
      columns={columns}
      isItemDeletable={isItemDeletable}
    />
  );
}
