import React, { useCallback } from 'react';

import { DataType, Plan } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Form, FormActions } from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import planCreateSchema from '@/validation/planCreate';
import planEditSchema from '@/validation/planEdit';
import useProducts from '@/hooks/features/products/useProducts';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { PlanFormFields } from '@/components/features/pricing/PlanFormFields/PlanFormFields';

export interface PlanFormProps extends BaseFormProps<Partial<Plan>> {}

const defaultInitialValues: Partial<Plan> = {};

export const PlanForm: React.FC<PlanFormProps> = ({
  onSave,
  onCancel,
  isSaving,
  isEdit = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Plan);

  const { selectProduct } = useProducts();

  const onSubmit = useCallback(
    (data: Plan) => {
      onSave(data);

      if (data.productId) {
        selectProduct(data.productId);
      }
    },
    [onSave, selectProduct]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={isEdit ? planEditSchema : planCreateSchema}
    >
      <PlanFormFields isEdit={isEdit} />
      <FormActions
        cancelText={t('common:cancel')}
        submitText={
          isEdit
            ? t('forms:buttons.updateEntity', {
                entityName: entityNamings.singularLower,
              })
            : t('forms:buttons.createEntity', {
                entityName: entityNamings.singularLower,
              })
        }
        isSaving={isSaving}
        onCancel={onCancel}
      />
    </Form>
  );
};
