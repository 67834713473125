import { spawn } from 'redux-saga/effects';

import accountBalancesSaga from './accountBalances.saga';
import accountContractsSaga from './accountContracts.saga';
import endBillingEntitiesSaga from './endBillingEntities.saga';

export default function* accountsSaga() {
  yield spawn(accountBalancesSaga);
  yield spawn(accountContractsSaga);
  yield spawn(endBillingEntitiesSaga);
}
