import React, { PropsWithChildren, useMemo } from 'react';

import { DataType, Id } from '@m3ter-com/m3ter-api';

import {
  NamedLink,
  NamedLinkProps,
} from '@/components/common/navigation/NamedLink/NamedLink';
import { CrudRouteType, getCrudRouteName, getIdParamName } from '@/routes/crud';

export interface CrudDetailsLinkProps extends Omit<NamedLinkProps, 'name'> {
  dataType: DataType;
  id: Id;
}

export const CrudDetailsLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<CrudDetailsLinkProps>
>(({ dataType, id, params, children, ...props }, ref) => {
  const routeParams = useMemo(
    () => ({
      ...params,
      [getIdParamName(dataType)]: id,
    }),
    [dataType, id, params]
  );

  return (
    <NamedLink
      ref={ref}
      name={getCrudRouteName(dataType, CrudRouteType.Details)}
      params={routeParams}
      {...props}
    >
      {children}
    </NamedLink>
  );
});
