import React from 'react';

import { Box } from '@chakra-ui/react';

import { Breadcrumbs } from '@/components/common/breadcrumbs/Breadcrumbs/Breadcrumbs';
import { PageTitle } from '@/components/common/breadcrumbs/PageTitle/PageTitle';

export interface PageHeaderProps {}

export const PageHeader: React.FC<PageHeaderProps> = () => {
  return (
    <Box mb={4}>
      <Breadcrumbs />
      <PageTitle />
    </Box>
  );
};
