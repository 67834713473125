import React, { useMemo, ReactNode, ReactElement } from 'react';

import { Outlet } from 'react-router-dom';

import { Entity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { getTitle } from '@/util/crud';
import { Accessor } from '@/util/data';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useCrudRetrieve from '@/hooks/data/crud/useCrudRetrieve';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch/LoadingErrorContentSwitch';
import { CrudRetrieveContextProvider } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';

export interface CrudRetrieveProps<E extends Entity> {
  element?: ReactNode;
  relationships?: Array<string>;
  titleAccessor?: Accessor<E, string>;
}

export function CrudRetrieve<E extends Entity = Entity>({
  element = <Outlet />,
  relationships,
  titleAccessor,
}: CrudRetrieveProps<E>): ReactElement<any, any> | null {
  const { t } = useTranslation();

  const { dataType, error, isLoading, itemData } =
    useCrudRetrieve<E>(relationships);

  const title = useMemo(
    () => (itemData ? getTitle(itemData as E, titleAccessor) : ''),
    [itemData, titleAccessor]
  );

  const { singular: entityName } = useEntityNamings(dataType);

  return (
    <LoadingErrorContentSwitch
      isLoading={isLoading}
      error={error}
      errorTitle={t('errors:generic.problemLoadingData', { entityName })}
    >
      {!!itemData && (
        <CrudRetrieveContextProvider<E> dataType={dataType} data={itemData}>
          {title && <BreadcrumbItem title={title} />}
          {element}
        </CrudRetrieveContextProvider>
      )}
    </LoadingErrorContentSwitch>
  );
}
