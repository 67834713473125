import React from 'react';

import { Product } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate/CrudCreate';
import { ProductForm } from '@/components/features/products/ProductForm/ProductForm';

export const ProductsCreateRoute: React.FC = () => {
  return <CrudCreate<Product> form={ProductForm} />;
};
