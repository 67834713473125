import React from 'react';

import {
  Badge,
  Box,
  Heading,
  SimpleGrid,
  StackDivider,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';

import { DataType, StatementDefinition } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { DetailsCard } from '@/components/common/data/DetailsCard/DetailsCard';
import { ReferenceLink } from '@/components/common/data/ReferenceLink/ReferenceLink';

export interface StatementDefinitionDetailsProps {
  data: StatementDefinition;
}

export const StatementDefinitionDetails: React.FC<
  StatementDefinitionDetailsProps
> = ({ data: statementDefinition }) => {
  const { t } = useTranslation();

  return (
    <DetailsCard
      showEditButton
      data={statementDefinition}
      dataType={DataType.StatementDefinition}
      showMetadata={false}
      details={
        <VStack spacing={6} alignItems="stretch" divider={<StackDivider />}>
          <SimpleGrid columns={4} gap={6} w="100%">
            <KeyValue
              label={t('forms:labels.name')}
              value={statementDefinition.name}
            />
            <KeyValue
              label={t('forms:labels.aggregationFrequency')}
              value={t(
                `features:statements.frequency.${statementDefinition.aggregationFrequency}`
              )}
            />
            <KeyValue
              label={t('forms:labels.includePricePerUnit')}
              value={
                statementDefinition.includePricePerUnit
                  ? t('common:yes')
                  : t('common:no')
              }
            />
          </SimpleGrid>
          <Box>
            <Heading size="sm" mb={2}>
              {t('features:statements.measures')}
            </Heading>
            <Table data-testid="measures">
              <Thead>
                <Tr>
                  <Th w="30%">{t('common:meter')}</Th>
                  <Th w="30%">{t('features:statements.measure')}</Th>
                  <Th w="40%">
                    {t('features:statements.aggregationFunctions')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {statementDefinition.measures.map((measure) => (
                  <Tr key={`${measure.meterId}-${measure.name}`}>
                    <Td>
                      {measure.meterId && (
                        <ReferenceLink
                          dataType={DataType.Meter}
                          id={measure.meterId}
                          accessor="name"
                        />
                      )}
                    </Td>
                    <Td>{measure.name}</Td>
                    <Td>
                      {measure.aggregations.map((aggregation) => (
                        <Badge key={aggregation} mr={2}>
                          {t(`features:usage.aggregationType.${aggregation}`)}
                        </Badge>
                      ))}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box>
            <Heading size="sm" mb={2}>
              {t('features:statements.dimensions')}
            </Heading>
            <Table data-testid="dimensions">
              <Thead>
                <Tr>
                  <Th w="30%">{t('common:meter')}</Th>
                  <Th w="30%">{t('features:statements.dimension')}</Th>
                  <Th w="40%">{t('features:statements.filter')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {statementDefinition.dimensions.map((dimension) => (
                  <Tr key={`${dimension.meterId}-${dimension.name}`}>
                    <Td>
                      {dimension.meterId && (
                        <ReferenceLink
                          dataType={DataType.Meter}
                          id={dimension.meterId}
                          accessor="name"
                        />
                      )}
                    </Td>
                    <Td>{dimension.name}</Td>
                    <Td>
                      {dimension.filter[0] === '*'
                        ? '-'
                        : dimension.filter.map((filterValue) => (
                            <Badge key={filterValue} mr={2}>
                              {filterValue}
                            </Badge>
                          ))}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </VStack>
      }
    />
  );
};
