import React from 'react';

import { StatementDefinition } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails/CrudDetails';
import { StatementDefinitionDetails } from '@/components/features/billing/StatementDefinitionDetails/StatementDefinitionDetails';

export const StatementDefinitionsDetailsRoute: React.FC = () => (
  <CrudDetails<StatementDefinition> component={StatementDefinitionDetails} />
);
