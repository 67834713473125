import React from 'react';

import { UserGroup } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails/CrudDetails';
import { UserGroupDetails } from '@/components/features/access/user-group/UserGroupDetails/UserGroupDetails';

export const UserGroupsDetailsRoute: React.FC = () => {
  return <CrudDetails<UserGroup> component={UserGroupDetails} />;
};
