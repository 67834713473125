import React from 'react';

import { Navigate } from 'react-router-dom';

import type { NamedRoute } from '@/types/routes';

import { RouteWrapper } from '@/components/routes/helpers/RouteWrapper/RouteWrapper';
import { MeteringNavigation } from '@/components/common/navigation/sections/MeteringNavigation/MeteringNavigation';

import setupAggregationsRoute from './aggregations';
import setupCompoundAggregationsRoute from './compound-aggregations';
import setupDataExplorerRoute from './data-explorer';
import setupIngestEventsRoute from './ingest-events';
import setupItemCountersRoute from './item-counters';
import setupMetersRoute from './meters';

export default (): NamedRoute => ({
  path: 'usage',
  name: 'usage',
  element: <RouteWrapper navigation={<MeteringNavigation />} />,
  children: [
    { index: true, element: <Navigate replace to="meters" /> },
    setupAggregationsRoute(),
    setupCompoundAggregationsRoute(),
    setupDataExplorerRoute(),
    setupIngestEventsRoute(),
    setupItemCountersRoute(),
    setupMetersRoute(),
  ],
});
