import React, { ComponentProps, PropsWithChildren, useMemo } from 'react';

import { Stack, StackDivider, StackProps, StyleProps } from '@chakra-ui/react';

import { DataType, Entity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { CopyToClipboard, KeyValue } from '@m3ter-com/ui-components';

import { isAuditedEntity } from '@/util/data';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import { ReferenceLink } from '@/components/common/data/ReferenceLink/ReferenceLink';

type AuditDataVariant = 'horizontal' | 'vertical';

interface AuditDataStackProps {
  variant: AuditDataVariant;
}

interface AuditDataItemProps extends ComponentProps<typeof KeyValue> {
  variant: AuditDataVariant;
}

interface AuditDataEditorProps {
  typeAndId: string;
}

export interface AuditDataProps {
  data: Entity;
  showEditors?: boolean;
  showId?: boolean;
  variant?: AuditDataVariant;
}

const AuditDataStack: React.FC<PropsWithChildren<AuditDataStackProps>> = ({
  children,
  variant,
}) => {
  const styleProps = useMemo<StackProps>(
    () =>
      variant === 'horizontal'
        ? {
            alignItems: 'center',
            direction: 'row',
            divider: <StackDivider />,
            flexFlow: 'row nowrap',
            justifyContent: 'flex-start',
            spacing: 2,
          }
        : {
            alignItems: 'flex-start',
            flexFlow: 'column nowrap',
            justifyContent: 'flex-start',
            spacing: 2,
          },
    [variant]
  );

  return <Stack {...styleProps}>{children}</Stack>;
};

const AuditDataItem: React.FC<AuditDataItemProps> = ({
  label,
  variant,
  ...props
}) => {
  const labelSuffix = variant === 'horizontal' ? ':' : '';
  const styleProps = useMemo<StyleProps>(
    () =>
      variant === 'horizontal'
        ? {
            alignItems: 'center',
            display: 'flex',
            flexFlow: 'row nowrap',
            gap: 1,
            justifyContent: 'flex-start',
          }
        : {},
    [variant]
  );

  return (
    <KeyValue
      fontSize="sm"
      label={`${label}${labelSuffix}`}
      {...styleProps}
      {...props}
    />
  );
};

const AuditDataEditor: React.FC<AuditDataEditorProps> = ({ typeAndId }) => {
  const [type, id] = typeAndId.split('_');

  switch (type) {
    case 'USER':
      return (
        <ReferenceLink
          dataType={DataType.User}
          id={id}
          accessor={({ firstName, lastName }) => `${firstName} ${lastName}`}
        />
      );
    case 'SERVICEUSER':
      return (
        <ReferenceLink
          dataType={DataType.ServiceUser}
          id={id}
          accessor="name"
        />
      );
    case 'm3ter':
    case 'M3ter':
      return <span>m3ter</span>;
    default:
      return null;
  }
};

export const AuditData: React.FC<AuditDataProps> = ({
  data,
  showEditors = true,
  showId = true,
  variant = 'vertical',
}) => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();

  const canShowEditors = showEditors && isAuditedEntity(data);

  if (!showId && !canShowEditors) {
    return null;
  }

  return (
    <AuditDataStack variant={variant}>
      {showId && (
        <AuditDataItem
          label={t('common:id')}
          value={<CopyToClipboard value={data.id}>{data.id}</CopyToClipboard>}
          variant={variant}
        />
      )}
      {canShowEditors && (
        <AuditDataItem
          label={t('common:created')}
          value={
            <span>
              {toLongDateTime(data.dtCreated)}
              {data.createdBy && (
                <React.Fragment>
                  {' '}
                  {t('common:by')}{' '}
                  <AuditDataEditor typeAndId={data.createdBy} />
                </React.Fragment>
              )}
            </span>
          }
          variant={variant}
        />
      )}
      {canShowEditors && (
        <AuditDataItem
          label={t('common:lastModified')}
          value={
            <span>
              {toLongDateTime(data.dtLastModified)}
              {data.lastModifiedBy && (
                <React.Fragment>
                  {' '}
                  {t('common:by')}{' '}
                  <AuditDataEditor typeAndId={data.lastModifiedBy} />
                </React.Fragment>
              )}
            </span>
          }
          variant={variant}
        />
      )}
    </AuditDataStack>
  );
};
