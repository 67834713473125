import { Entity, PathParams } from '@m3ter-com/m3ter-api';

import { CrudQueryParams } from '@/types/routes';

import { useCrudContext } from '@/components/common/crud/CrudContext/CrudContext';
import useQueryString from '@/hooks/navigation/useQueryString';
import useEntityUpdate from '@/hooks/data/crud/useEntityUpdate';
import useCrudId from '@/hooks/data/crud/useCrudId';

const useCrudEdit = <E extends Entity = Entity>(pathParams?: PathParams) => {
  const { basePath, dataType, onCancel } = useCrudContext<E>();
  const id = useCrudId(dataType);
  const { returnPath } = useQueryString<CrudQueryParams>();

  const {
    itemData,
    isLoading,
    isSaving,
    loadingError,
    savingError,
    updateItem,
  } = useEntityUpdate<E>(dataType, id, returnPath || basePath, pathParams);

  return {
    dataType,
    isLoading,
    isSaving,
    itemData,
    loadingError,
    savingError,
    onCancel,
    updateItem,
  };
};

export default useCrudEdit;
