import React, { ChangeEvent, ReactNode, useCallback, useState } from 'react';

import {
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';

import { Id } from '@m3ter-com/m3ter-api';
import { Button } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import {
  UsageQueryBuilderMenu,
  UsageQueryBuilderMenuProps,
} from '@/components/features/usage/query-builder/UsageQueryBuilderMenu/UsageQueryBuilderMenu';
import { ManualValueInput } from '@/components/features/usage/query-builder/ManualValueInput/ManualValueInput';

interface Option {
  value: Id;
  label: ReactNode;
  isDisabled?: boolean;
}

export interface UsageQueryBuilderMultiSelectMenuProps
  extends Omit<UsageQueryBuilderMenuProps, 'footer'> {
  emptyMessage: string;
  options: Array<Option>;
  onAdd: (ids: Array<Id>) => void;
  isLoading?: boolean;
  showSelectAll?: boolean;
  allowManualEntry?: boolean;
}

export const UsageQueryBuilderMultiSelectMenu: React.FC<
  UsageQueryBuilderMultiSelectMenuProps
> = ({
  emptyMessage,
  options,
  onAdd,
  onClose,
  isLoading,
  showSelectAll = false,
  allowManualEntry = false,
  ...menuProps
}) => {
  const { t } = useTranslation();

  const [selectedIds, setSelectedIds] = useState<Array<Id>>([]);
  const [manualValue, setManualValue] = useState<string>('');

  // Have to cast becasue `CheckboxGroup` uses `string | number` but we always have IDs.
  const onChange = useCallback((value: Array<string | number>) => {
    setSelectedIds(value as Array<Id>);
  }, []);

  const onManualValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setManualValue(event.target.value);
    },
    []
  );

  const onCancelClick = useCallback(() => {
    setSelectedIds([]);
    setManualValue('');
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const onAddClick = useCallback(() => {
    onAdd(manualValue !== '' ? [...selectedIds, manualValue] : selectedIds);
    onCancelClick(); // Reset
  }, [onAdd, selectedIds, manualValue, onCancelClick]);

  return (
    <UsageQueryBuilderMenu
      onClose={onClose}
      {...menuProps}
      preFooter={
        allowManualEntry && (
          <ManualValueInput
            size="sm"
            placeholder={t('features:usage.queryBuilder.orEnterId')}
            value={manualValue}
            onChange={onManualValueChange}
          />
        )
      }
      footer={
        <ButtonGroup size="sm">
          <Button onClick={onAddClick}>{t('common:add')}</Button>
          <Button variant="ghost" onClick={onCancelClick}>
            {t('common:cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      {isLoading ? (
        <Spinner size="sm" />
      ) : (
        <Stack>
          {options.length === 0 ? (
            <Text>{emptyMessage}</Text>
          ) : (
            <CheckboxGroup value={selectedIds} onChange={onChange}>
              <Stack>
                {showSelectAll && (
                  <Checkbox
                    isChecked={
                      selectedIds.length ===
                        options.filter((option) => !option.isDisabled).length &&
                      selectedIds.length !== 0
                    }
                    isDisabled={options.every((option) => option.isDisabled)}
                    isIndeterminate={
                      selectedIds.length > 0 &&
                      selectedIds.length <
                        options.filter((option) => !option.isDisabled).length
                    }
                    onChange={(event) => {
                      onChange(
                        event.target.checked
                          ? options
                              .filter((option) => !option.isDisabled)
                              .map((option) => option.value)
                          : []
                      );
                    }}
                  >
                    {t('common:selectAll')}
                  </Checkbox>
                )}
                {options.map((option) => (
                  <Checkbox
                    key={option.value}
                    value={option.value}
                    isDisabled={option.isDisabled}
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          )}
        </Stack>
      )}
    </UsageQueryBuilderMenu>
  );
};
