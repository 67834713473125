import { Entity } from '@m3ter-com/m3ter-api';

import { useCrudContext } from '@/components/common/crud/CrudContext/CrudContext';
import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';

const useCrudDetails = <E extends Entity = Entity>() => {
  const { dataType } = useCrudContext();
  const itemData = useCrudRetrieveData<E>(dataType);

  return {
    itemData,
  };
};

export default useCrudDetails;
