import React, { useMemo } from 'react';

import { ExternalMappingConfig } from '@m3ter-com/m3ter-api';
import { SelectOption } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  FormCreatableSelect,
  FormField,
  FormSelect,
} from '@m3ter-com/console-core/components';

export interface ExternalMappingExternalTableFieldProps {
  externalMappingConfig: ExternalMappingConfig;
  name: string;
  externalSystem?: string;
}

export const ExternalMappingExternalTableField: React.FC<
  ExternalMappingExternalTableFieldProps
> = ({ externalMappingConfig, name, externalSystem }) => {
  const { t } = useTranslation();

  const externalSystemConfig = useMemo(() => {
    if (!externalSystem) {
      return undefined;
    }

    return (externalMappingConfig.externalSystems || []).find(
      (system) => system.name === externalSystem
    );
  }, [externalMappingConfig.externalSystems, externalSystem]);

  const externalTableOptions = useMemo<Array<SelectOption>>(() => {
    return (externalSystemConfig?.tables || []).map((tableName) => ({
      value: tableName,
      label: tableName,
    }));
  }, [externalSystemConfig]);

  return (
    <FormField
      isRequired
      name={name}
      label={t('forms:labels.externalTable')}
      isDisabled={!externalSystem}
      control={
        externalSystemConfig?.allowCustomExternalTables
          ? FormCreatableSelect
          : FormSelect
      }
      options={externalTableOptions}
    />
  );
};
