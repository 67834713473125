import React from 'react';

import { AppLayoutMain } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { FeedbackBanner } from '@/components/common/help/FeedbackBanner/FeedbackBanner';

const LazyDashboard = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "Dashboard" */ '@/components/features/dashboard/Dashboard/Dashboard'
  ).then((module) => ({ default: module.Dashboard }))
);

export const OrganizationIndexRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AppLayoutMain>
      <BreadcrumbItem title={t('common:dashboard')} />
      <FeedbackBanner />
      <LazyDashboard />
    </AppLayoutMain>
  );
};
