import React, { useEffect } from 'react';

import { Navigate, useParams } from 'react-router-dom';

import { DataType } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import useNamedNavigate from '@/hooks/navigation/useNamedNavigate';
import { RouteWrapper } from '@/components/routes/helpers/RouteWrapper/RouteWrapper';
import { AccountsNavigation } from '@/components/common/navigation/sections/AccountsNavigation/AccountsNavigation';
import { RecentAccounts } from '@/components/common/navigation/RecentAccounts/RecentAccounts';

import setupAccountsRoute from './accounts';
import setupDataExplorerRoute from './data-explorer';
import setupReportsRoute from './reports';

const AccountRedirect: React.FC = () => {
  const params = useParams();
  const navigate = useNamedNavigate();

  useEffect(() => {
    navigate(getCrudRouteName(DataType.Account, CrudRouteType.Details), params);
  }, [params, navigate]);

  return null;
};

const LazyUsageHealthReportRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "UsageHealthReportRoute" */ '@/components/routes/forecasting/UsageHealthReportRoute'
  ).then((module) => ({ default: module.UsageHealthReportRoute }))
);
const LazyUsageAnomaliesReportRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "UsageAnomaliesReportRoute" */ '@/components/routes/forecasting/UsageAnomaliesReportRoute'
  ).then((module) => ({ default: module.UsageAnomaliesReportRoute }))
);
const LazyUsageMonitoringRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "UsageMonitoringRoute" */ '@/components/routes/forecasting/UsageMonitoringRoute'
  ).then((module) => ({ default: module.UsageMonitoringRoute }))
);
const LazyUsageForecastsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "UsageForecastsRoute" */ '@/components/routes/forecasting/UsageForecastsRoute'
  ).then((module) => ({ default: module.UsageForecastsRoute }))
);
const LazyAccountUsageHealthRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "AccountUsageHealthRoute" */ '@/components/routes/forecasting/AccountUsageHealthRoute'
  ).then((module) => ({ default: module.AccountUsageHealthRoute }))
);

export default (): NamedRoute => ({
  path: 'accounts',
  name: 'accounts-root', // Can't overload `accounts` from CRUD route.
  element: (
    <RouteWrapper
      navigation={<AccountsNavigation />}
      navigationActions={<RecentAccounts />}
    />
  ),
  children: [
    { index: true, element: <Navigate replace to="accounts" /> },
    setupAccountsRoute(),
    setupDataExplorerRoute(),
    setupReportsRoute(),
    // Redirect from old `/accounts/:accountId` to `/accounts/accounts/:accountId`
    {
      path: ':accountId',
      element: <AccountRedirect />,
    },
    {
      name: 'usage-health',
      path: 'usage-health',
      element: <LazyUsageHealthReportRoute />,
    },
    {
      name: 'usage-anomalies',
      path: 'usage-anomalies',
      element: <LazyUsageAnomaliesReportRoute />,
    },
    {
      name: 'usage-monitoring',
      path: 'usage-monitoring',
      element: <LazyUsageMonitoringRoute />,
    },
    {
      path: 'usage-forecasts',
      name: 'usage-forecasts',
      element: <LazyUsageForecastsRoute />,
    },
    {
      name: 'account-usage-health',
      path: 'accounts/:accountId/usage-health',
      element: <LazyAccountUsageHealthRoute />,
    },
  ],
});
