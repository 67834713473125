import React from 'react';

import { Box, Button, Spinner, Text, VStack } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

import { DateTimeISOString, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormField } from '@m3ter-com/console-core/components';
import { Alert } from '@m3ter-com/ui-components';

import { FormDatePicker } from '@/components/forms/FormDatePicker/FormDatePicker';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useOverlappingAccountPlans from '@/hooks/features/accounts/useOverlappingAccountPlans';

export interface AccountPlanDateFieldsProps {
  checkOverlap?: boolean;
}

export const AccountPlanDateFields: React.FC<AccountPlanDateFieldsProps> = ({
  checkOverlap,
}) => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();

  const accountId: Id | undefined = useWatch({ name: 'accountId' });
  const productId: Id | undefined = useWatch({ name: 'productId' });
  const startDate: DateTimeISOString | undefined = useWatch({
    name: 'startDate',
  });

  const {
    overlappingAccountPlan,
    onEndOverlappingAccountPlan,
    isUpdatingAccountPlan,
    isRefetchingAccountPlans,
  } = useOverlappingAccountPlans({
    accountId,
    productId,
    startDate,
    checkOverlap,
  });

  const shouldShowOverlappingAlert =
    !!overlappingAccountPlan &&
    !isUpdatingAccountPlan &&
    !isRefetchingAccountPlans;

  return (
    <VStack width="100%" spacing={4} alignItems="stretch">
      <FormField
        isRequired
        showTimeSelect
        name="startDate"
        label={t('forms:labels.startDateInclusive')}
        control={FormDatePicker}
      />
      <FormField
        showTimeSelect
        name="endDate"
        label={t('forms:labels.endDateExclusive')}
        control={FormDatePicker}
      />
      {isUpdatingAccountPlan && <Spinner />}
      {shouldShowOverlappingAlert && (
        <Alert status="warning">
          <Box>
            <Text size="xs" mb={4}>
              {overlappingAccountPlan.endDate
                ? t('features:account.overlappingPlanNoEnd', {
                    end: toLongDateTime(overlappingAccountPlan.endDate),
                  })
                : t('features:account.overlappingPlanNoEnd')}
            </Text>
            <Button
              size="sm"
              colorScheme="orange"
              onClick={onEndOverlappingAccountPlan}
            >
              {t('features:account.endAttachedPlan')}
            </Button>
          </Box>
        </Alert>
      )}
    </VStack>
  );
};
