import React from 'react';

import { PlanGroup } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudCreate } from '@/components/common/crud/CrudCreate/CrudCreate';
import { PlanGroupForm } from '@/components/features/pricing/PlanGroupForm/PlanGroupForm';
import { RequiresCurrenciesWrapper } from '@/components/features/organization/RequiresCurrenciesWrapper/RequiresCurrenciesWrapper';

export const PlanGroupsCreateRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <RequiresCurrenciesWrapper
      message={t('features:planGroups.noCurrenciesMessage')}
    >
      <CrudCreate<PlanGroup> form={PlanGroupForm} />
    </RequiresCurrenciesWrapper>
  );
};
