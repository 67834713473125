import {
  ActivityIcon,
  ChartNoAxesCombinedIcon,
  DatabaseIcon,
  FileSpreadsheetIcon,
  LineChartIcon,
  TangentIcon,
  UsersIcon,
} from 'lucide-react';

import { DataType } from '@m3ter-com/m3ter-api';
import { Navigation } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import { NamedNavLink } from '@/components/common/navigation/NamedNavLink/NamedNavLink';

export const AccountsNavigation: React.FC = () => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();

  return (
    <Navigation>
      <NamedNavLink
        name={getCrudRouteName(DataType.Account, CrudRouteType.List)}
        icon={<UsersIcon />}
      >
        {t('common:accounts')}
      </NamedNavLink>
      {isFeatureEnabled(Feature.Reports) && (
        <NamedNavLink name="accounts.reports" icon={<FileSpreadsheetIcon />}>
          {t('common:reports')}
        </NamedNavLink>
      )}
      <NamedNavLink name="commitments.data-explorer" icon={<DatabaseIcon />}>
        {t('features:dataExplorer.commitments.title')}
      </NamedNavLink>
      {isFeatureEnabled(Feature.Forecasting) && (
        <NamedNavLink name="usage-health" icon={<ActivityIcon />}>
          {t('features:forecasting.usageHealth')}
        </NamedNavLink>
      )}
      {isFeatureEnabled(Feature.Forecasting) && (
        <NamedNavLink name="usage-anomalies" icon={<TangentIcon />}>
          {t('features:forecasting.usageAnomalies')}
        </NamedNavLink>
      )}
      {isFeatureEnabled(Feature.Forecasting) && (
        <NamedNavLink
          name="usage-monitoring"
          icon={<ChartNoAxesCombinedIcon />}
        >
          {t('features:forecasting.usageMonitoring')}
        </NamedNavLink>
      )}
      {isFeatureEnabled(Feature.Forecasting) && (
        // Here temporarily until we decide where usage forecasting lives
        <NamedNavLink name="usage-forecasts" icon={<LineChartIcon />}>
          {t('features:forecasting.usageForecasts')}
        </NamedNavLink>
      )}
    </Navigation>
  );
};
