import React, { ReactNode } from 'react';

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Link,
  SimpleGrid,
  Spinner,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { DataType, AccountPlan } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { PlanAccountPlansByProduct, PlanGroupAccountPlan } from '@/types/data';

import { isAccountPlanActive } from '@/util/account';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge/ActiveStatusBadge';
import { ReferenceLink } from '@/components/common/data/ReferenceLink/ReferenceLink';
import { KeyValueReferenceLink } from '@/components/common/data/KeyValueReferenceLink/KeyValueReferenceLink';

export interface AccountPlansListProps {
  header: ReactNode;
  isLoading: boolean;
  noDataContent: ReactNode;
  planAccountPlanRows: PlanAccountPlansByProduct;
  planGroupAccountPlans: Array<PlanGroupAccountPlan>;
  showActiveStatusBadge?: boolean;
  onDeleteAccountPlan: (accountPlan: AccountPlan) => void;
}

export const AccountPlansList: React.FC<AccountPlansListProps> = ({
  header,
  isLoading,
  noDataContent,
  planAccountPlanRows,
  planGroupAccountPlans,
  showActiveStatusBadge = false,
  onDeleteAccountPlan,
}) => {
  const { t } = useTranslation();
  const { timeZone, toLongDateTime } = useDateFormatter();

  const hasData =
    planGroupAccountPlans.length > 0 ||
    Object.values(planAccountPlanRows).some(
      (planAccountPlans) => planAccountPlans.length > 0
    );

  return (
    <Card>
      <CardHeader py={3}>{header}</CardHeader>
      <CardBody>
        {isLoading && <Spinner />}
        {!isLoading && !hasData && noDataContent}
        {!isLoading && hasData && (
          <VStack alignItems="stretch" spacing={4} divider={<StackDivider />}>
            {Object.entries(planAccountPlanRows).map(
              ([productId, planAccountPlans]) => (
                <Box key={productId} data-testid="product">
                  <Heading size="sm" mb={2}>
                    {t('common:product')}: {planAccountPlans[0].product.name}
                  </Heading>
                  {planAccountPlans.map((planAccountPlan) => (
                    <SimpleGrid
                      columns={5}
                      key={planAccountPlan.id}
                      alignItems="start"
                    >
                      <KeyValue
                        label={t('common:plan')}
                        value={
                          <React.Fragment>
                            <Link
                              as={CrudDetailsLink}
                              dataType={DataType.AccountPlan}
                              id={planAccountPlan.id}
                            >
                              {planAccountPlan.plan.name}
                            </Link>
                            {showActiveStatusBadge && (
                              <ActiveStatusBadge
                                active={isAccountPlanActive(
                                  planAccountPlan,
                                  timeZone
                                )}
                                inactiveLabel={t('common:pending')}
                              />
                            )}
                          </React.Fragment>
                        }
                      />
                      <KeyValue
                        label={t('common:start')}
                        value={toLongDateTime(planAccountPlan.startDate)}
                      />
                      <KeyValue
                        label={t('common:end')}
                        value={
                          planAccountPlan.endDate
                            ? toLongDateTime(planAccountPlan.endDate)
                            : '-'
                        }
                      />
                      <Box>
                        {planAccountPlan.contractId && (
                          <KeyValueReferenceLink
                            label={t('forms:labels.contract')}
                            dataType={DataType.Contract}
                            id={planAccountPlan.contractId}
                            accessor="name"
                          />
                        )}
                      </Box>
                      <Box textAlign="right">
                        <EntityCrudActions<AccountPlan>
                          addReturnPath
                          dataType={DataType.AccountPlan}
                          item={planAccountPlan}
                          editRouteName={getCrudRouteName(
                            DataType.AccountPlan,
                            CrudRouteType.Edit
                          )}
                          onDelete={onDeleteAccountPlan}
                        />
                      </Box>
                    </SimpleGrid>
                  ))}
                </Box>
              )
            )}
            {planGroupAccountPlans.length > 0 && (
              <Box>
                <Heading size="sm" mb={2}>
                  {t('common:planGroups')}
                </Heading>
                {planGroupAccountPlans.map((planGroupAccountPlan) => (
                  <SimpleGrid
                    columns={5}
                    key={planGroupAccountPlan.id}
                    alignItems="center"
                  >
                    <Box>
                      <KeyValue
                        label={t('common:planGroup')}
                        value={
                          <React.Fragment>
                            <Link
                              as={CrudDetailsLink}
                              dataType={DataType.AccountPlan}
                              id={planGroupAccountPlan.id}
                            >
                              {planGroupAccountPlan.planGroup.name}
                            </Link>
                            {showActiveStatusBadge && (
                              <ActiveStatusBadge
                                active={isAccountPlanActive(
                                  planGroupAccountPlan,
                                  timeZone
                                )}
                                inactiveLabel={t('common:pending')}
                              />
                            )}
                          </React.Fragment>
                        }
                      />
                    </Box>
                    <KeyValue
                      label={t('common:start')}
                      value={toLongDateTime(planGroupAccountPlan.startDate)}
                    />
                    <KeyValue
                      label={t('common:end')}
                      value={
                        planGroupAccountPlan.endDate
                          ? toLongDateTime(planGroupAccountPlan.endDate)
                          : '-'
                      }
                    />
                    <Box>
                      {planGroupAccountPlan.contractId && (
                        <KeyValue
                          label={t('forms:labels.contract')}
                          value={
                            <ReferenceLink
                              dataType={DataType.Contract}
                              id={planGroupAccountPlan.contractId}
                              accessor="name"
                            />
                          }
                        />
                      )}
                    </Box>
                    <Box textAlign="right">
                      <EntityCrudActions<AccountPlan>
                        addReturnPath
                        dataType={DataType.AccountPlan}
                        item={planGroupAccountPlan}
                        editRouteName={getCrudRouteName(
                          DataType.AccountPlan,
                          CrudRouteType.Edit
                        )}
                        onDelete={onDeleteAccountPlan}
                      />
                    </Box>
                  </SimpleGrid>
                ))}
              </Box>
            )}
          </VStack>
        )}
      </CardBody>
    </Card>
  );
};
