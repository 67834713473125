import React, { useMemo } from 'react';

import { Contract } from '@m3ter-com/m3ter-api';

import { useCrudRetrieveContext } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';
import { CrudCreate } from '@/components/common/crud/CrudCreate/CrudCreate';
import { ContractForm } from '@/components/features/accounts/ContractForm/ContractForm';

export const ContractsCreateRoute: React.FC = () => {
  const { account } = useCrudRetrieveContext();

  const initialValues = useMemo(() => ({ accountId: account?.id }), [account]);

  return (
    <CrudCreate<Contract> form={ContractForm} initialValues={initialValues} />
  );
};
