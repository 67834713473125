import React, { useMemo } from 'react';

import {
  Heading,
  VStack,
  Box,
  StackDivider,
  SimpleGrid,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { DataType } from '@m3ter-com/m3ter-api';
import { longDateFormatKey } from '@m3ter-com/console-core/utils';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, KeyValue } from '@m3ter-com/ui-components';

import { toLabel } from '@/data/timeZones';
import { getCreditOrderDescription } from '@/util/credit';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import useOrg from '@/hooks/data/crud/useOrg';
import { DetailsCard } from '@/components/common/data/DetailsCard/DetailsCard';
import { KeyValueReferenceLink } from '@/components/common/data/KeyValueReferenceLink/KeyValueReferenceLink';

import { CurrencyConversionsSummary } from './CurrencyConversionsSummary';

export interface OrganizationConfigSummaryProps {
  editUrl: string;
}

export const OrganizationConfigSummary: React.FC<
  OrganizationConfigSummaryProps
> = ({ editUrl }) => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();
  const { orgConfig } = useOrg();

  const creditApplicationOrderDescription = useMemo(
    () =>
      orgConfig.creditApplicationOrder &&
      getCreditOrderDescription(orgConfig.creditApplicationOrder),
    [orgConfig]
  );

  return (
    <DetailsCard
      data={orgConfig}
      dataType={DataType.OrganizationConfig}
      showMetadata={false}
      header={
        <Button intent="primary" size="sm" as={Link} to={editUrl}>
          {t('common:edit')}
        </Button>
      }
      details={
        <VStack spacing={4} alignItems="stretch" divider={<StackDivider />}>
          <SimpleGrid columns={4} gap={6} w="100%">
            <KeyValue
              label={t('forms:labels.timezone')}
              value={toLabel(orgConfig.timezone)}
            />
            <KeyValue
              label={t('forms:labels.currency')}
              value={orgConfig.currency}
            />
            <KeyValue
              label={t('forms:labels.autoApproveBills')}
              value={
                orgConfig.autoApproveBillsGracePeriod
                  ? t('common:afterCountPeriod', {
                      count: orgConfig.autoApproveBillsGracePeriod,
                      period: t(
                        `features:billing.autoApprovePeriodUnits.${orgConfig.autoApproveBillsGracePeriodUnit}`
                      ),
                    })
                  : t('common:disabled')
              }
            />
            <KeyValue
              label={t('forms:labels.scheduledBillInterval')}
              value={t(
                `features:organizations.scheduledBillIntervalOptions.${(
                  orgConfig.scheduledBillInterval ?? 0
                )
                  .toString()
                  .replace('.', '_')}` // Can't use dots in translation keys.
              )}
            />
            <KeyValue
              label={t('features:organizations.yearEpoch')}
              value={format(new Date(orgConfig.yearEpoch), longDateFormatKey)}
            />
            <KeyValue
              label={t('features:organizations.monthEpoch')}
              value={format(new Date(orgConfig.monthEpoch), longDateFormatKey)}
            />
            <KeyValue
              label={t('features:organizations.weekEpoch')}
              value={format(new Date(orgConfig.weekEpoch), longDateFormatKey)}
            />
            <KeyValue
              label={t('features:organizations.dayEpoch')}
              value={format(new Date(orgConfig.dayEpoch), longDateFormatKey)}
            />
            <KeyValue
              label={t('forms:labels.daysBeforeBillDue')}
              value={orgConfig.daysBeforeBillDue}
            />
            <KeyValue
              label={t('features:organizations.externalInvoiceDate')}
              value={t(
                `features:organizations.externalInvoiceDateOptions.${orgConfig.externalInvoiceDate}`
              )}
            />
            <KeyValue
              label={t('forms:labels.billPrefix')}
              value={orgConfig.billPrefix ?? '-'}
            />
            <KeyValue
              label={t('forms:labels.sequenceStartNumber')}
              value={orgConfig.sequenceStartNumber ?? '-'}
            />
            <KeyValue
              label={t('features:organizations.suppressEmptyBills')}
              value={
                orgConfig.suppressedEmptyBills
                  ? t('common:yes')
                  : t('common:no')
              }
            />
            <KeyValue
              label={t('features:organizations.consolidateBills')}
              value={
                orgConfig.consolidateBills ? t('common:yes') : t('common:no')
              }
            />
            <KeyValue
              label={t('forms:labels.commitmentFeeBilling')}
              value={
                orgConfig.commitmentFeeBillInAdvance
                  ? t('common:inAdvance')
                  : t('common:inArrears')
              }
            />
            <KeyValue
              label={t('forms:labels.minimumSpendBilling')}
              value={
                orgConfig.minimumSpendBillInAdvance
                  ? t('common:inAdvance')
                  : t('common:inArrears')
              }
            />
            <KeyValue
              label={t('forms:labels.standingChargeBilling')}
              value={
                orgConfig.standingChargeBillInAdvance
                  ? t('common:inAdvance')
                  : t('common:inArrears')
              }
            />
            {creditApplicationOrderDescription && (
              <KeyValue
                label={t('forms:labels.creditApplicationOrder')}
                value={creditApplicationOrderDescription}
              />
            )}
            {isFeatureEnabled(Feature.Statements) && (
              <KeyValueReferenceLink
                label={t('forms:labels.defaultStatementDefinition')}
                dataType={DataType.StatementDefinition}
                id={orgConfig.defaultStatementDefinitionId}
                accessor="name"
                defaultValue="-"
              />
            )}
          </SimpleGrid>
          <Box>
            <Heading as="h3" size="sm" mb={2}>
              {t('features:organizations.currencyConversions')}
            </Heading>
            <CurrencyConversionsSummary
              currencyConversions={orgConfig.currencyConversions || []}
            />
          </Box>
        </VStack>
      }
    />
  );
};
