import React, { useCallback, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { Currency, DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack, RadioTabs } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
  FormSwitch,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import currencySchema from '@/validation/currency';
import { getCurrencyByCode } from '@/data/currencies';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { FormCurrencySelect } from '@/components/forms/FormCurrencySelect/FormCurrencySelect';

export interface CurrencyFormProps extends BaseFormProps<Currency> {}

interface CurrencyFormFieldsProps {
  isEdit: boolean;
  currencyType: CurrencyType;
  onChangeCurrencyType: (newCurrencyType: CurrencyType) => void;
}

export interface CurrencyFormExtraData {
  isCustomOrEditCurrency: boolean;
}

enum CurrencyType {
  Standard = 'standard',
  Custom = 'custom',
}

const defaultInitialValues: Partial<Currency> = {};

const CurrencyFormFields: React.FC<CurrencyFormFieldsProps> = ({
  isEdit,
  currencyType,
  onChangeCurrencyType,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Currency);

  const { setValue, clearErrors } = useFormContext();

  const onCurrencyChange = useCallback(
    (newCurrencyType: CurrencyType) => {
      onChangeCurrencyType(newCurrencyType);
      // When changing currency type clear any errors and reset the form for the new type of currency.
      clearErrors();
      setValue('code', undefined);
      setValue('name', undefined);
    },
    [clearErrors, onChangeCurrencyType, setValue]
  );

  const typeOptions = useMemo(
    () => [
      {
        value: CurrencyType.Standard,
        label: t('features:organizations.standardCurrency'),
        content: isEdit ? (
          <NameCodeFields isCodeFieldReadOnly />
        ) : (
          <FormField
            isRequired
            name="code"
            label={t('forms:labels.currency')}
            control={FormCurrencySelect}
          />
        ),
      },
      {
        value: CurrencyType.Custom,
        label: t('features:organizations.customCurrency'),
        content: <NameCodeFields autoFillCodeField={false} />,
      },
    ],
    [t, isEdit]
  );

  // Only show the message about overriding if creating using a standard currency.
  const showDecimalsHelpText =
    !isEdit && currencyType === CurrencyType.Standard;

  return (
    <React.Fragment>
      {isEdit ? (
        <NameCodeFields isCodeFieldReadOnly />
      ) : (
        <RadioTabs
          options={typeOptions}
          value={currencyType}
          onChange={onCurrencyChange}
          width="100%"
        />
      )}
      <FormField
        isRequired={currencyType === CurrencyType.Custom || isEdit}
        name="maxDecimalPlaces"
        label={t('forms:labels.maxDecimalPlaces')}
        helpText={
          showDecimalsHelpText
            ? t('forms:helpText.maxDecimalPlaces')
            : undefined
        }
        control={FormInput}
        type="number"
      />
      <FormField
        name="archived"
        label={t('forms:labels.archived')}
        control={FormSwitch}
        helpText={t('forms:helpText.archivePickListEntity', {
          entityName: entityNamings.singularLower,
        })}
      />
    </React.Fragment>
  );
};

export const CurrencyForm: React.FC<CurrencyFormProps> = ({
  onSave,
  onCancel,
  isEdit = false,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Currency);

  const [currencyType, setCurrencyType] = useState<CurrencyType>(
    CurrencyType.Standard
  );

  const onSubmit = useCallback(
    (formData: any) => {
      let data = { ...formData };
      if (formData.code) {
        const currency = getCurrencyByCode(formData.code);
        if (currency && !formData.name) {
          data = { ...formData, name: currency.name, code: currency.code };
        }
      }

      onSave(data);
    },
    [onSave]
  );

  const extraValidationData = useMemo(
    () => ({
      isCustomOrEditCurrency: currencyType === CurrencyType.Custom || isEdit,
    }),
    [currencyType, isEdit]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={currencySchema}
      extraValidationData={extraValidationData}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <CurrencyFormFields
            isEdit={isEdit}
            currencyType={currencyType}
            onChangeCurrencyType={setCurrencyType}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', {
                  entityName: entityNamings.singularLower,
                })
              : t('forms:buttons.createEntity', {
                  entityName: entityNamings.singularLower,
                })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
