import { createStore } from 'zustand/vanilla';

import { AppError } from '@/types/errors';

import { StoreWithActions } from './types';

interface AuthState {
  authError: AppError | null;
}

interface AuthActions {
  setAuthErrorMessage: (message: string | null) => void;
}

type AuthStore = StoreWithActions<AuthState, AuthActions>;

export const authStore = createStore<AuthStore>((set) => ({
  authError: null,
  actions: {
    setAuthErrorMessage: (message) => {
      if (message) {
        set({ authError: { message } });
      }
    },
  },
}));

export const getAuthError = () => authStore.getState().authError;

export const setAuthErrorMessage = (message: string | null) =>
  authStore.getState().actions.setAuthErrorMessage(message);
