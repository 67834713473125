import React, { useCallback, useMemo } from 'react';

import { useResolvedPath } from 'react-router-dom';

import {
  DataType,
  CreditType,
  OrganizationConfig,
  StatementAutoGenerateMode,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useSingleton from '@/hooks/data/useSingleton';
import useOrg from '@/hooks/data/crud/useOrg';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch/LoadingErrorContentSwitch';
import { OrganizationConfigForm } from '@/components/features/organization/OrganizationConfigForm/OrganizationConfigForm';

export const OrganizationConfigEditRoute: React.FC = () => {
  const { t } = useTranslation();

  const path = useResolvedPath('..');

  const { orgConfig } = useOrg();
  const { error, isLoading, isSaving, update } =
    useSingleton<OrganizationConfig>(DataType.OrganizationConfig);

  const onSave = useCallback(
    (data: OrganizationConfig) => {
      update(data, undefined, undefined, path.pathname);
    },
    [update, path]
  );

  // Default fields that might be missing in the existing org config but
  // that need a value.
  const initialValues = useMemo(
    () => ({
      creditApplicationOrder: [CreditType.Prepayment, CreditType.Balance],
      autoGenerateStatementMode: StatementAutoGenerateMode.None,
      ...orgConfig,
    }),
    [orgConfig]
  );

  return (
    <>
      <BreadcrumbItem title={t('features:organizations.configuration')} />
      <LoadingErrorContentSwitch
        showContentOnError
        error={error}
        isLoading={isLoading}
      >
        {!isLoading && (
          <OrganizationConfigForm
            initialValues={initialValues}
            isSaving={isSaving}
            returnUrl={path.pathname}
            onSave={onSave}
          />
        )}
      </LoadingErrorContentSwitch>
    </>
  );
};
