import React, { useCallback, useMemo } from 'react';

import { HStack, Text, useDisclosure } from '@chakra-ui/react';
import { GaugeIcon, PlusIcon, XIcon } from 'lucide-react';

import { DataType, Id } from '@m3ter-com/m3ter-api';
import { IconButton, SearchBar, TagButton } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { UsageQueryBuilderSection } from '@/components/features/usage/query-builder/UsageQueryBuilderSection/UsageQueryBuilderSection';
import { UsageQueryBuilderMultiSelectMenu } from '@/components/features/usage/query-builder/UsageQueryBuilderMultiSelectMenu/UsageQueryBuilderMultiSelectMenu';

import useUsageQueryBuilderMeters from './useUsageQueryBuilderMeters';

export interface UsageQueryBuilderMetersProps {
  value: Array<Id>;
  onChange: (value: Array<Id>) => void;
}

export const UsageQueryBuilderMeters: React.FC<
  UsageQueryBuilderMetersProps
> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Meter);

  const {
    meters,
    filteredSortedMeters,
    searchTerm,
    setSearchTerm,
    clearSearchTerm,
  } = useUsageQueryBuilderMeters();

  const { isOpen, onClose, onToggle } = useDisclosure();

  const reset = useCallback(() => {
    onClose();
    clearSearchTerm();
  }, [onClose, clearSearchTerm]);

  const onRemove = useCallback(
    (meterId: Id) => {
      onChange(value.filter((v) => v !== meterId));
    },
    [onChange, value]
  );

  const onRemoveAll = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const onAdd = useCallback(
    (ids: Array<Id>) => {
      onChange([...value, ...ids]);
      reset();
    },
    [onChange, value, reset]
  );

  const options = useMemo(
    () =>
      filteredSortedMeters.map((meter) => ({
        value: meter.id,
        label: meter.name,
        isDisabled: value.includes(meter.id),
      })),
    [filteredSortedMeters, value]
  );

  return (
    <UsageQueryBuilderSection
      title={entityNamings.plural}
      icon={GaugeIcon}
      colorScheme="indigo"
    >
      <HStack wrap="wrap">
        {value.length === 0 && (
          <Text fontStyle="italic">
            {t('features:usage.queryBuilder.allMeters')}
          </Text>
        )}
        {value.map((meterId) => {
          const meter = meters.find((m) => m.id === meterId);
          return (
            <TagButton
              key={meterId}
              size="sm"
              colorScheme="indigo"
              closeLabel={t('common:remove')}
              onClose={() => {
                onRemove(meterId);
              }}
            >
              {meter ? meter.name : meterId}
            </TagButton>
          );
        })}
        {value.length > 0 && (
          <IconButton
            size="sm"
            colorScheme="indigo"
            variant="subtle"
            aria-label={t('common:removeAll')}
            onClick={onRemoveAll}
            icon={<XIcon size={16} />}
          />
        )}
        <UsageQueryBuilderMultiSelectMenu
          allowManualEntry
          isOpen={isOpen}
          onClose={reset}
          trigger={
            <IconButton
              size="sm"
              colorScheme="indigo"
              variant="subtle"
              aria-label={t('forms:buttons.addEntity', {
                entityName: entityNamings.singularLower,
              })}
              icon={<PlusIcon size={16} />}
              onClick={onToggle}
            />
          }
          header={
            <SearchBar
              placeholder={t('common:searchEntity', {
                entity: entityNamings.pluralLower,
              })}
              initialValue={searchTerm}
              onSearch={setSearchTerm}
              onClear={clearSearchTerm}
            />
          }
          options={options}
          onAdd={onAdd}
          emptyMessage={t('common:searchEntityNoResults', {
            entity: entityNamings.pluralLower,
          })}
        />
      </HStack>
    </UsageQueryBuilderSection>
  );
};
