import React from 'react';

import { Text, VStack } from '@chakra-ui/react';

import { Plan, PlanTemplate } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { SegmentedPricingRowData } from '@/hooks/features/pricing/usePricingSegmentsEditor';
import { PricingGridCell } from '@/components/features/pricing/grid/PricingGridCell/PricingGridCell';
import { PricingGridPricingNavigator } from '@/components/features/pricing/grid/PricingGridPricingNavigator/PricingGridPricingNavigator';
import { PricingGridCellContextProvider } from '@/components/features/pricing/grid/PricingGridCellContext/PricingGridCellContext';

export interface SegmentedPricingGridRowProps {
  segmentedFields: Array<string>;
  planTemplate: PlanTemplate;
  rowData: SegmentedPricingRowData;
  plan?: Plan;
}

export const SegmentedPricingGridRow: React.FC<
  SegmentedPricingGridRowProps
> = ({ segmentedFields, rowData, plan, planTemplate }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {segmentedFields.map((fieldName) => (
        <VStack
          key={fieldName}
          alignItems="flex-start"
          justifyContent="center"
          as={PricingGridCell}
        >
          <Text>{rowData.segment[fieldName] || t('common:any')}</Text>
        </VStack>
      ))}
      <PricingGridCell>
        <PricingGridCellContextProvider
          isTemplateColumn={!plan}
          plan={plan}
          planPricings={rowData.planPricings}
          planTemplate={planTemplate}
          planTemplatePricings={rowData.planTemplatePricings}
          segment={rowData.segment}
        >
          <PricingGridPricingNavigator />
        </PricingGridCellContextProvider>
      </PricingGridCell>
    </React.Fragment>
  );
};
