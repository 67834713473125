import React from 'react';

import { Account } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate/CrudCreate';
import { AccountForm } from '@/components/features/accounts/AccountForm/AccountForm';

export const AccountsCreateRoute: React.FC = () => (
  <CrudCreate<Account> form={AccountForm} />
);
