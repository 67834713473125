import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { CrudRoute } from '@/components/common/crud/CrudRoute/CrudRoute';

export interface PickListRouteProps {
  dataType: DataType;
  title: string;
}

export const PickListRoute: React.FC<PickListRouteProps> = ({
  dataType,
  title,
}) => <CrudRoute dataType={dataType} title={title} />;
