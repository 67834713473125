import React, { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Link,
  List,
  ListItem,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import {
  DataType,
  Balance,
  BalanceTransaction,
  BalanceTransactionEntityType,
  DrawdownChargeTypes,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, KeyValue } from '@m3ter-com/ui-components';
import { formatNumber } from '@m3ter-com/console-core/utils';

import { dataTypeListAllQuery } from '@/queries/crud';
import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { DetailsCard } from '@/components/common/data/DetailsCard/DetailsCard';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch/LoadingErrorContentSwitch';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';
import { ReferenceLabel } from '@/components/common/data/ReferenceLabel/ReferenceLabel';

export interface BalanceDetailsProps {
  data: Balance;
}

interface BalanceTransactionWithBalance extends BalanceTransaction {
  balance: number;
}

interface SourceLinkProps {
  transaction: BalanceTransaction;
}

const SourceLink: React.FC<SourceLinkProps> = ({ transaction }) => {
  const { t } = useTranslation();
  const label = t(`features:balances.entityTypes.${transaction.entityType}`);

  // We can only link to a bill.
  return transaction.entityType === BalanceTransactionEntityType.Bill ? (
    <Link
      as={CrudDetailsLink}
      dataType={DataType.Bill}
      id={transaction.entityId}
    >
      {label}
    </Link>
  ) : (
    <span>{label}</span>
  );
};

export const BalanceDetails: React.FC<BalanceDetailsProps> = ({
  data: balance,
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { toLongDateTime } = useDateFormatter();

  const transactionsPathParams = useOrgPathParams({ balanceId: balance.id });

  const {
    isLoading,
    error,
    data: transactions,
  } = useQuery(
    dataTypeListAllQuery({
      dataType: DataType.BalanceTransaction,
      pathParams: transactionsPathParams,
    })
  );

  const transactionsWithBalance = useMemo(
    () =>
      transactions
        ? // Add a `balance` property to each transaction with the running total.
          transactions.reduce((acc, transaction) => {
            const previousBalance = acc[acc.length - 1]?.balance ?? 0;
            acc.push({
              ...transaction,
              balance: previousBalance + transaction.amount,
            });
            return acc;
          }, new Array<BalanceTransactionWithBalance>())
        : [],
    [transactions]
  );

  const balanceChargeTypes = useMemo(
    () =>
      balance.lineItemTypes?.length
        ? balance.lineItemTypes
        : Object.values(DrawdownChargeTypes),
    [balance.lineItemTypes]
  );

  return (
    <DetailsCard
      showEditButton
      data={balance}
      dataType={DataType.Balance}
      details={
        <React.Fragment>
          <Heading size="lg" mb={4}>
            {formatCurrency(balance.amount, balance.currency)}
          </Heading>
          <SimpleGrid columns={4} gap={6} w="100%">
            <KeyValue label={t('forms:labels.name')} value={balance.name} />
            <KeyValue label={t('forms:labels.code')} value={balance.code} />
            <KeyValue
              label={t('forms:labels.startDate')}
              value={toLongDateTime(balance.startDate)}
            />
            <KeyValue
              label={t('forms:labels.endDate')}
              value={toLongDateTime(balance.endDate)}
            />
            <KeyValue
              label={t('forms:labels.description')}
              value={balance.description || '-'}
            />
            <KeyValue
              label={t('forms:labels.drawDownDescription')}
              value={balance.balanceDrawDownDescription || '-'}
            />
            <KeyValue
              label={t('forms:labels.drawDownChargeTypes')}
              value={
                <List>
                  {balanceChargeTypes.map((chargeType) => (
                    <ListItem key={chargeType}>
                      {t(`features:billing.lineItemTypes.${chargeType}`)}
                    </ListItem>
                  ))}
                </List>
              }
            />
            <KeyValue
              label={t('forms:labels.drawDownProducts')}
              value={
                balance.productIds?.length
                  ? t('features:balances.selectedProducts')
                  : t('features:balances.allProducts')
              }
            />
            <KeyValue
              label={t('forms:labels.rolloverAmount')}
              value={
                balance.rolloverAmount
                  ? formatCurrency(balance.rolloverAmount, balance.currency)
                  : '-'
              }
            />
            <KeyValue
              label={t('forms:labels.rolloverEndDate')}
              value={
                balance.rolloverEndDate
                  ? toLongDateTime(balance.rolloverEndDate)
                  : '-'
              }
            />
            <KeyValue
              label={t('forms:labels.overageSurchargePercent')}
              value={balance.overageSurchargePercent ?? '-'}
            />
            <KeyValue
              label={t('forms:labels.overageDescription')}
              value={balance.overageDescription || '-'}
            />
          </SimpleGrid>
        </React.Fragment>
      }
    >
      <Card>
        <CardHeader>
          <HStack justifyContent="space-between">
            <Heading size="md">{t('features:balances.transactions')}</Heading>
            <Button
              size="sm"
              as={CrudCreateLink}
              addReturnPath
              dataType={DataType.BalanceTransaction}
            >
              {t('features:balances.addTransaction')}
            </Button>
          </HStack>
        </CardHeader>
        <CardBody>
          <LoadingErrorContentSwitch isLoading={isLoading} error={error}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{t('features:balances.transactionDate')}</Th>
                  <Th>{t('features:balances.appliedDate')}</Th>
                  <Th>{t('common:description')}</Th>
                  <Th>{t('common:source')}</Th>
                  <Th textAlign="right">{t('common:amount')}</Th>
                  <Th textAlign="right">{t('common:balance')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {transactionsWithBalance.length > 0 ? (
                  transactionsWithBalance.map((transaction) => (
                    <Tr
                      key={transaction.id}
                      verticalAlign="top"
                      data-testid="transaction"
                    >
                      <Td>{toLongDateTime(transaction.transactionDate)}</Td>
                      <Td>{toLongDateTime(transaction.appliedDate)}</Td>
                      <Td>
                        <Box>
                          {transaction.transactionTypeId && (
                            <ReferenceLabel
                              dataType={DataType.TransactionType}
                              id={transaction.transactionTypeId}
                              accessor="name"
                            />
                          )}
                        </Box>
                        {transaction.description}
                      </Td>
                      <Td>
                        <SourceLink transaction={transaction} />
                      </Td>
                      <Td textAlign="right">
                        {formatCurrency(transaction.amount, balance.currency)}
                        {/* Show the paid amount if the transaction is paid and not from a bill */}
                        {transaction.paid &&
                          transaction.entityType !==
                            BalanceTransactionEntityType.Bill && (
                            <Text fontSize="sm">
                              {t('common:paid')}{' '}
                              {transaction.currencyPaid
                                ? formatCurrency(
                                    transaction.paid,
                                    transaction.currencyPaid
                                  )
                                : formatNumber(transaction.paid)}
                            </Text>
                          )}
                      </Td>
                      <Td textAlign="right">
                        {formatCurrency(transaction.balance, balance.currency)}
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={6} textAlign="center">
                      <Text data-testid="no-transactions">
                        {t('features:balances.noTransactions')}
                      </Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </LoadingErrorContentSwitch>
        </CardBody>
      </Card>
    </DetailsCard>
  );
};
