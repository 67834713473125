import i18next from 'i18next';

export const clamp = (value: number, min: number, max: number): number =>
  Math.min(Math.max(value, min), max);

/**
 * Takes a number and returns it rounded to a fixed decimal point.
 *
 * See https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
 */
export const roundTo = (value: number, decimalPlaces: number): number => {
  const p = 10 ** decimalPlaces;
  const n = value * p * (1 + Number.EPSILON);
  return Math.round(n) / p || 0; // Prevent -0;
};

export const formatNumber = (value: number): string =>
  new Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
  }).format(value);

export const formatPercentage = (value: number, showPlus = false): string => {
  const result = Number.isNaN(value)
    ? ''
    : new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: 'percent',
      }).format(value);
  if (result && showPlus && value >= 0) {
    return `+${result}`;
  }
  return result;
};

export const addOrdinalSuffix = (value: number) => {
  const rules = new Intl.PluralRules(undefined, { type: 'ordinal' });
  const suffix = i18next.t(`common:ordinalSuffix.${rules.select(value)}`);
  return `${value}${suffix}`;
};
