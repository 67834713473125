import React, { useCallback, useMemo } from 'react';

import { Stack, useDisclosure } from '@chakra-ui/react';
import { CalculatorIcon, PlusIcon } from 'lucide-react';

import { AggregationType, DataType } from '@m3ter-com/m3ter-api';
import { Button, ButtonProps } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { UsageQueryBuilderSection } from '@/components/features/usage/query-builder/UsageQueryBuilderSection/UsageQueryBuilderSection';
import {
  UsageQueryBuilderMenu,
  UsageQueryBuilderMenuButton,
} from '@/components/features/usage/query-builder/UsageQueryBuilderMenu/UsageQueryBuilderMenu';

const nonUniqueAggregationType: Array<AggregationType> = Object.values(
  AggregationType
).filter((type) => type !== AggregationType.Unique);

export interface UsageQueryBuilderAggregationProps {
  value?: AggregationType;
  onChange: (value: AggregationType) => void;
  aggregationTypes?: Array<AggregationType>;
  isDisabled?: boolean;
}

export const UsageQueryBuilderAggregation: React.FC<
  UsageQueryBuilderAggregationProps
> = ({
  value,
  onChange,
  aggregationTypes = nonUniqueAggregationType,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Aggregation);

  const { isOpen, onClose, onToggle } = useDisclosure();

  const onSelect = useCallback(
    (type: AggregationType) => {
      onChange(type);
      onClose();
    },
    [onChange, onClose]
  );

  const buttonProps = useMemo<ButtonProps>(
    () => ({
      isDisabled,
      size: 'sm',
      onClick: onToggle,
      colorScheme: 'blue',
      variant: !isDisabled ? 'subtle' : 'ghost',
      ...(!value ? { leftIcon: <PlusIcon size={16} /> } : {}),
    }),
    [isDisabled, onToggle, value]
  );

  return (
    <UsageQueryBuilderSection
      colorScheme="blue"
      title={t('common:aggregation')}
      icon={CalculatorIcon}
    >
      <UsageQueryBuilderMenu
        isOpen={isOpen}
        onClose={onClose}
        trigger={
          <Button {...buttonProps}>
            {!value
              ? t('forms:buttons.addEntity', {
                  entityName: entityNamings.singularLower,
                })
              : t(`features:usage.aggregationType.${value}`)}
          </Button>
        }
      >
        <Stack>
          {aggregationTypes.map((type) => (
            <UsageQueryBuilderMenuButton
              key={type}
              onClick={() => onSelect(type)}
            >
              {t(`features:usage.aggregationType.${type}`)}
            </UsageQueryBuilderMenuButton>
          ))}
        </Stack>
      </UsageQueryBuilderMenu>
    </UsageQueryBuilderSection>
  );
};
