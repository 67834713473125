import React from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';

import { useCrudListContext } from './CrudListContext';

export const CrudListError: React.FC = () => {
  const { t } = useTranslation();

  const { entityNamings, error } = useCrudListContext();

  if (error) {
    return (
      <ErrorAlert
        title={t('errors:generic.problemLoadingData', {
          entityName: entityNamings.pluralLower,
        })}
        error={error}
      />
    );
  }

  return null;
};
