import React, { useEffect } from 'react';

import { useResolvedPath } from 'react-router-dom';

import { useBreadcrumbActions } from '@/state/breadcrumbs';
import { useTabContext } from '@/components/common/navigation/TabbedRoute/TabbedRoute';

export interface BreadcrumbItemProps {
  title: string;
  hasNoRoute?: boolean;
}

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  title,
  hasNoRoute = false,
}) => {
  // Get the resolved path to the current route.
  const resolvedPath = useResolvedPath('');
  const to = !hasNoRoute ? resolvedPath.pathname : undefined;

  const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbActions();

  // Breadcrumb items directly under tabbed routes currently showing tabs shouldn't show
  // in the trail, to avoid duplication; their title will be in the tab itself.
  const { showTabs = false } = useTabContext();

  useEffect(() => {
    addBreadcrumb(title, to, showTabs);
    return () => {
      removeBreadcrumb(title, to);
    };
  }, [addBreadcrumb, removeBreadcrumb, showTabs, title, to]);

  return null;
};
