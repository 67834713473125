import React from 'react';

import { Flex, SimpleGrid, VStack } from '@chakra-ui/react';

import { DataType, Contract } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, KeyValue } from '@m3ter-com/ui-components';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useAccountHasChildren from '@/hooks/features/accounts/useAccountHasChildren';
import useEndBillingEntities from '@/hooks/features/accounts/useEndBillingEntities';
import { DetailsCard } from '@/components/common/data/DetailsCard/DetailsCard';
import { ContractPlans } from '@/components/features/accounts/ContractPlans/ContractPlans';
import { ContractCommitments } from '@/components/features/accounts/ContractCommitments/ContractCommitments';
import { EndBillingEntitiesModal } from '@/components/features/accounts/EndBillingEntitiesModal/EndBillingEntitiesModal';

export interface ContractDetailsProps {
  data: Contract;
}

export const ContractDetails: React.FC<ContractDetailsProps> = ({
  data: contract,
}) => {
  const { t } = useTranslation();
  const { toLongDate } = useDateFormatter();
  const entityNamings = useEntityNamings(DataType.Contract);

  const { hasChildren: isContractAccountParent } = useAccountHasChildren(
    contract.accountId
  );

  const {
    onEndBillingEntities,
    isEndBillingEntitiesModalOpen,
    onOpenEndBillingEntitiesModal,
    onCloseEndBillingEntitiesModal,
  } = useEndBillingEntities(contract.id, DataType.Contract);

  return (
    <DetailsCard
      showEditButton
      data={contract}
      dataType={DataType.Contract}
      header={
        <Flex w="100%" mr={2} justifyContent="flex-end">
          <Button size="sm" onClick={onOpenEndBillingEntitiesModal}>
            {t('features:billing.endBillingEntities', {
              entityName: entityNamings.singularLower,
            })}
          </Button>
        </Flex>
      }
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue label={t('forms:labels.name')} value={contract.name} />
          <KeyValue
            label={t('forms:labels.code')}
            value={contract.code ?? '-'}
          />
          <KeyValue
            label={t('forms:labels.startDateInclusive')}
            value={toLongDate(contract.startDate)}
          />
          <KeyValue
            label={t('forms:labels.endDateExclusive')}
            value={toLongDate(contract.endDate)}
          />
          <KeyValue
            label={t('forms:labels.purchaseOrderNumber')}
            value={contract.purchaseOrderNumber || '-'}
          />
          <KeyValue
            label={t('forms:labels.description')}
            value={contract.description || '-'}
          />
        </SimpleGrid>
      }
    >
      <VStack w="100%" spacing={4} alignItems="stretch">
        <ContractPlans contract={contract} />
        <ContractCommitments contract={contract} />
      </VStack>
      <EndBillingEntitiesModal
        dataType={DataType.Contract}
        showApplyToChildren={isContractAccountParent}
        isOpen={isEndBillingEntitiesModalOpen}
        onClose={onCloseEndBillingEntitiesModal}
        onSubmit={onEndBillingEntities}
      />
    </DetailsCard>
  );
};
