import React, { useCallback } from 'react';

import {
  Divider,
  Flex,
  HStack,
  Heading,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormField,
  FormInput,
  FormPasswordInput,
} from '@m3ter-com/console-core/components';
import { SignInFormValues } from '@m3ter-com/console-core/types';

import useAuth from '@/hooks/auth/useAuth';
import signInFormSchema from '@/validation/signInForm';
import { NamedLink } from '@/components/common/navigation/NamedLink/NamedLink';
import { AuthSubmitButton } from '@/components/features/auth/AuthSubmitButton/AuthSubmitButton';

const emptyObject = {};

export const SignInForm: React.FC = () => {
  const { t } = useTranslation();
  const { onSignIn, isLoading } = useAuth(true);

  const onSubmit = useCallback(
    (data: SignInFormValues) => {
      onSignIn(data.email, data.password);
    },
    [onSignIn]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={emptyObject}
      validationSchema={signInFormSchema}
    >
      <FormStack spacing={4}>
        <Flex w="100%" align="center" justify="center">
          <Heading size="md" textAlign="center">
            {t('features:auth.signInToAccount')}
          </Heading>
        </Flex>
        <FormField
          autoFocus
          isRequired
          name="email"
          type="email"
          label={t('forms:labels.emailAddress')}
          control={FormInput}
          placeholder={t('features:auth.emailPlaceholder')}
          helpText={t('forms:helpText.caseSensitiveEmailAddress')}
        />
        <FormField
          isRequired
          name="password"
          label={t('forms:labels.password')}
          control={FormPasswordInput}
          placeholder={t('forms:labels.password')}
        />
        <Link
          fontSize="md"
          as={NamedLink}
          disabled={isLoading}
          name="auth.forgot-password"
        >
          {t('features:auth.forgotPassword')}
        </Link>
        <AuthSubmitButton
          isLoading={isLoading}
          buttonText={t('features:auth.signIn')}
        />
        <Stack w="100%" spacing={6}>
          <HStack>
            <Divider />
            <Text textStyle="sm" whiteSpace="nowrap">
              {t('features:auth.signInWith')}
            </Text>
            <Divider />
          </HStack>
        </Stack>
        <Button w="100%" as={NamedLink} name="auth.federated-sign-in">
          {t('features:auth.singleSignOn')}
        </Button>
      </FormStack>
    </Form>
  );
};
