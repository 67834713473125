import { DataType, Entity } from '@m3ter-com/m3ter-api';
import { SelectOption } from '@m3ter-com/ui-components';

export enum EntityRouteListIds {
  Account = 'accounts-list-route',
  Aggregation = 'aggregations-list-route',
  Alert = 'alerts-list-route',
  Bill = 'bills-list-route',
  CompoundAggregation = 'compound-aggregations-list-route',
  Counter = 'counters-list-route',
  CreditReason = 'credit-reasons-list-route',
  Currency = 'currencies-list-route',
  Customer = 'customer-list-route',
  DebitReason = 'debit-reasons-list-route',
  ExportDestination = 'export-destination-list-route',
  ExportJob = 'export-job-list-route',
  ExportSchedule = 'export-schedule-list-route',
  ExternalMapping = 'external-mappings-list-route',
  IdentityProvider = 'identity-providers-list-route',
  IntegrationTransformation = 'integration-transformations-list-route',
  IntegrationWorkflow = 'integration-workflows-list-route',
  Invitation = 'invitation-list-route',
  MeasurementsDeletion = 'measurements-deletions-list-route',
  Meter = 'meters-list-route',
  NotificationRules = 'notification-rules-list-route',
  OrganizationAdmin = 'organization-admin-list-route',
  OutboundWebhook = 'outbound-webhooks-list-route',
  PermissionPolicy = 'permission-policies-list-route',
  Plan = 'plan-list-route',
  PlanGroup = 'plan-group-list-route',
  PlanGroupLink = 'plan-group-link-list-route',
  PlanTemplate = 'plan-template-list-route',
  Product = 'products-list-route',
  ServiceUser = 'service-user-list-route',
  StatementDefinition = 'statement-definitions',
  TransactionType = 'transaction-types-list-route',
  User = 'user-list-route',
  UserAdmin = 'user-adimn-list-route',
  UserGroup = 'user-group-list-route',
}

export enum ListSearchComparator {
  Equal = 'equal',
  Contains = 'contains',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotEqual = 'notEqual',
}

export interface ListSearchCriterion {
  comparator: ListSearchComparator;
  value: string | number | 'true' | 'false';
}

export interface ListSearchCriteria {
  [fieldName: string]: ListSearchCriterion | Array<ListSearchCriterion>;
}

export enum ListSearchOperator {
  And = 'AND',
  Or = 'OR',
}

export enum ListSize {
  TwentyItems = 20,
  FiftyItems = 50,
  HundredItems = 100,
  TwoHundredItems = 200,
}

export interface ListSortCriteria {
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export enum OtherListIds {
  AccountBalances = 'account-balances',
  AccountBills = 'account-bills',
  AccountChildren = 'account-children',
  AccountExternalMappings = 'account-external-mappings',
  AccountLatestBills = 'account-latest-bills',
  ActiveAndPendingCommitments = 'active-pending-commitments',
  ContractAccountPlans = 'contract-account-plans',
  ContractCommitments = 'contract-commitments',
  CounterAdjustments = 'counter-adjustments-list-route',
  EntityQuickSearch = 'entity-quick-search',
  EntitySelectModal = 'entity-select-modal',
  ExpiredCommitments = 'expired-commitments',
  DataExplorerSavedQueriesModal = 'data-explorer-saved-queries-modal',
  IngestEvents = 'ingest-events',
  IntegrationsEvents = 'integrations-events',
  NotificationRuleEvents = 'notification-rule-events',
  ServiceUsersPermissionPolicies = 'service-users-permission-policies',
  SupportAccessPermissionPolicies = 'support-access-permission-policies',
  UserGroupsPermissionPolicies = 'user-groups-permission-polices',
  UsersPermissionPolicies = 'users-permission-policies',
  UsersUserGroups = 'users-user-groups',
  UserGroupUsers = 'user-group-users',
}

// Types for list filter components

type ListFilterFieldName<E extends Entity> = Extract<keyof E, string>;

export enum ListFilterFieldType {
  Checkbox = 'checkbox',
  Date = 'date',
  DateRange = 'dateRange',
  SearchableEntitySelect = 'searchableEntitySelect',
  Input = 'input',
  Number = 'number',
  Select = 'select',
}

type ListFilterFieldValueTranslator = (value: any) => React.ReactNode;

interface BasicFilterDefinition {
  filterFieldType: ListFilterFieldType.Input | ListFilterFieldType.Number;
  options?: never;
  option?: never;
  dataType?: never;
  accessor?: never;
  searchFields?: never;
  valueTranslator?: never;
}

interface CheckboxFilterDefinition {
  filterFieldType: ListFilterFieldType.Checkbox;
  option: SelectOption;
  options?: never;
  dataType?: never;
  accessor?: never;
  searchFields?: never;
  valueTranslator?: never;
}

interface DateFilterDefinition {
  filterFieldType: ListFilterFieldType.Date | ListFilterFieldType.DateRange;
  option?: never;
  options?: never;
  dataType?: never;
  accessor?: never;
  searchFields?: never;
  valueTranslator: ListFilterFieldValueTranslator;
}

interface SearchableEntitySelectFilterDefinition {
  filterFieldType: ListFilterFieldType.SearchableEntitySelect;
  options?: never;
  option?: never;
  dataType: DataType;
  accessor: string;
  searchFields: Array<string>;
  valueTranslator?: ListFilterFieldValueTranslator;
}

interface SelectFilterDefinition {
  filterFieldType: ListFilterFieldType.Select;
  options: Array<SelectOption>;
  option?: never;
  dataType?: never;
  accessor?: never;
  searchFields?: never;
  valueTranslator?: ListFilterFieldValueTranslator;
}

type ListFilterFieldDefinition =
  | BasicFilterDefinition
  | CheckboxFilterDefinition
  | DateFilterDefinition
  | SearchableEntitySelectFilterDefinition
  | SelectFilterDefinition;

export type ListFilterDefinition<E extends Entity = Entity> =
  ListFilterFieldDefinition & {
    name: ListFilterFieldName<E>;
    label: string;
    helpText?: string;
  };
