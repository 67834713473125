import React, { useMemo } from 'react';

import snakeCase from 'lodash/snakeCase';

import { ChildBillingMode, DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { useOrgDates } from '@/hooks/util/useOrgDates';
import useCustomPlanCreate from '@/hooks/features/accounts/useCustomPlanCreate';
import { AccountCustomPlanGroupForm } from '@/components/features/accounts/AccountCustomPlanGroupForm/AccountCustomPlanGroupForm';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { useCrudRetrieveContext } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';

export const CreateCustomPlanGroupRoute: React.FC = () => {
  const { t } = useTranslation();
  const { account } = useCrudRetrieveContext();
  const { getStartOfToday } = useOrgDates();
  const entityNamings = useEntityNamings(DataType.PlanGroup);

  const { error, isSaving, onCancel, onSave } = useCustomPlanCreate(
    DataType.PlanGroup
  );

  const initialValues = useMemo(() => {
    const name = `${account?.name} ${entityNamings.singularLower}`;

    return {
      accountId: account?.id,
      startDate: getStartOfToday().toISOString(),
      name,
      code: snakeCase(name),
      childBillingMode: ChildBillingMode.ParentBreakdown,
    };
  }, [account, entityNamings, getStartOfToday]);

  return (
    <React.Fragment>
      {error && (
        <ErrorAlert
          title={t('errors:generic.problemSavingData', {
            entityName: entityNamings.singularLower,
          })}
          error={error}
          mb={4}
        />
      )}
      <BreadcrumbItem title={t('common:create')} />
      <AccountCustomPlanGroupForm
        isSaving={isSaving}
        initialValues={initialValues}
        onSave={onSave}
        onCancel={onCancel}
      />
    </React.Fragment>
  );
};
