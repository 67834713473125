import React, { PropsWithChildren, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { DataType, Id } from '@m3ter-com/m3ter-api';

import {
  NamedLink,
  NamedLinkProps,
} from '@/components/common/navigation/NamedLink/NamedLink';
import { CrudRouteType, getCrudRouteName, getIdParamName } from '@/routes/crud';

export interface CrudEditLinkProps
  extends Omit<NamedLinkProps, 'name' | 'params'> {
  dataType: DataType;
  id: Id;
  queryParams?: Record<string, any>;
  addReturnPath?: boolean;
}

export const CrudEditLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<CrudEditLinkProps>
>(
  (
    { dataType, id, children, queryParams, addReturnPath = false, ...props },
    ref
  ) => {
    const params = useMemo(
      () => ({
        [getIdParamName(dataType)]: id,
      }),
      [dataType, id]
    );

    // Get the path where the link is rendered to allow `addReturnPath`
    const location = useLocation();

    const combinedQueryParams = useMemo(
      () =>
        addReturnPath
          ? {
              ...queryParams,
              returnPath: `${location.pathname}${location.search}`,
            }
          : queryParams,
      [queryParams, addReturnPath, location]
    );

    return (
      <NamedLink
        ref={ref}
        name={getCrudRouteName(dataType, CrudRouteType.Edit)}
        params={params}
        queryParams={combinedQueryParams}
        {...props}
      >
        {children}
      </NamedLink>
    );
  }
);
