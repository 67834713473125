import React, {
  ComponentProps,
  ReactElement,
  ReactNode,
  useEffect,
} from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { Select } from '@m3ter-com/ui-components';

import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch/LoadingErrorContentSwitch';
import useEntityOptions, {
  UseEntityOptionsProps,
} from '@/hooks/data/useEntityOptions';

export interface EntitySelectProps<DT extends DataType>
  extends Omit<ComponentProps<typeof Select>, 'options'>,
    UseEntityOptionsProps<DT> {
  isRequired?: boolean;
  emptyContent?: ReactNode;
}

export function EntitySelect<DT extends DataType>({
  dataType,
  accessor,
  detailAccessor,
  params,
  filter,
  optionValueAccessor,
  isDisabled,
  isRequired = false,
  emptyContent,
  ...selectProps
}: EntitySelectProps<DT>): ReactElement<any, any> {
  const { isLoading, error, options } = useEntityOptions({
    dataType,
    accessor,
    detailAccessor,
    params,
    filter,
    optionValueAccessor,
    isDisabled,
  });

  const { value, onChange } = selectProps;

  useEffect(() => {
    // If the field is required, we don't have a value and we only have one option, set it by default.
    if (isRequired && !value && options.length === 1) {
      onChange(options[0].value);
    }
  }, [options, isRequired, value, onChange]);

  return (
    <LoadingErrorContentSwitch isLoading={isLoading} error={error}>
      {options.length > 0 || !emptyContent ? (
        <Select
          isSearchable
          isDisabled={isDisabled}
          searchMatchFrom="any"
          options={options}
          {...selectProps}
        />
      ) : (
        emptyContent
      )}
    </LoadingErrorContentSwitch>
  );
}
