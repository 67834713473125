import React, { useCallback } from 'react';

import { Outlet, useNavigate, useResolvedPath } from 'react-router-dom';

import { DataType } from '@m3ter-com/m3ter-api';

import { CrudQueryParams } from '@/types/routes';

import useEntityDelete from '@/hooks/data/crud/useEntityDelete';
import useCrudRouteNames from '@/hooks/navigation/useCrudRouteNames';
import useQueryString from '@/hooks/navigation/useQueryString';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { CrudContextProvider } from '@/components/common/crud/CrudContext/CrudContext';
import { RequiresProductsWrapper } from '@/components/features/products/RequiresProductsWrapper/RequiresProductsWrapper';

export interface CrudRouteProps {
  dataType: DataType;
  title: string;
  canDelete?: boolean;
  requiresProduct?: boolean;
}

export const CrudRoute: React.FC<CrudRouteProps> = ({
  dataType,
  title,
  canDelete = true,
  requiresProduct = false,
}) => {
  const navigate = useNavigate();
  const path = useResolvedPath('');
  const { returnPath: queryParamsReturnPath } =
    useQueryString<CrudQueryParams>();

  const { createRouteName, detailsRouteName, editRouteName, listRouteName } =
    useCrudRouteNames(dataType);

  const basePath = path.pathname;

  const onCancel = useCallback(() => {
    let returnPath = queryParamsReturnPath;

    if (returnPath && returnPath.indexOf(':id') !== -1) {
      const [pathname, searchParams] = returnPath.split('?');
      const newSearchParams = new URLSearchParams(searchParams);

      Array.from(newSearchParams.keys()).forEach((key) => {
        if (newSearchParams.get(key) === ':id') {
          newSearchParams.delete(key);
        }
      });
      returnPath = `${pathname}${
        newSearchParams.toString() ? `?${newSearchParams.toString()}` : ''
      }`;
    }

    navigate(returnPath || basePath);
  }, [basePath, navigate, queryParamsReturnPath]);

  const { deleteItem } = useEntityDelete(dataType, basePath);

  return (
    <React.Fragment>
      <BreadcrumbItem title={title} hasNoRoute={!listRouteName} />
      <CrudContextProvider
        basePath={basePath}
        createRouteName={createRouteName}
        dataType={dataType}
        detailsRouteName={detailsRouteName}
        editRouteName={editRouteName}
        onCancel={onCancel}
        onDelete={canDelete ? deleteItem : undefined}
      >
        {requiresProduct ? (
          <RequiresProductsWrapper>
            <Outlet />
          </RequiresProductsWrapper>
        ) : (
          <Outlet />
        )}
      </CrudContextProvider>
    </React.Fragment>
  );
};
