import React, { useMemo } from 'react';

import { Card, CardBody, Link, Heading } from '@chakra-ui/react';

import { Account, Balance, DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, CardActionsHeader } from '@m3ter-com/ui-components';

import { OtherListIds } from '@/types/lists';

import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';
import useCrudRouteNames from '@/hooks/navigation/useCrudRouteNames';
import useEntityDelete from '@/hooks/data/crud/useEntityDelete';

export interface AccountBalancesProps {
  account: Account;
}

export const AccountBalances: React.FC<AccountBalancesProps> = ({
  account,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Balance);

  const { formatCurrency } = useCurrencies();
  const { toLongDateTime } = useDateFormatter();
  const { editRouteName } = useCrudRouteNames(DataType.Balance);
  const { deleteItem } = useEntityDelete(DataType.Balance);

  const columns = useMemo<Array<ColumnDefinition<Balance>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (item) => (
          <Link as={CrudDetailsLink} dataType={DataType.Balance} id={item.id}>
            {item.name}
          </Link>
        ),
      },
      { id: 'code', header: t('forms:labels.code'), accessor: 'code' },
      {
        id: 'amount',
        header: t('forms:labels.amount'),
        accessor: (item) => formatCurrency(item.amount, item.currency),
      },
      {
        id: 'startDate',
        header: t('forms:labels.startDate'),
        accessor: (item) => toLongDateTime(item.startDate),
      },
      {
        id: 'endDate',
        header: t('forms:labels.endDate'),
        accessor: (item) => toLongDateTime(item.endDate),
      },
      {
        id: 'actions',
        header: '',
        accessor: (item) => (
          <EntityCrudActions<Balance>
            addReturnPath
            dataType={DataType.Balance}
            item={item}
            editRouteName={editRouteName}
            onDelete={deleteItem}
          />
        ),
      },
    ],
    [t, formatCurrency, toLongDateTime, editRouteName, deleteItem]
  );

  const params = useMemo(
    () => ({
      accountId: account.id,
    }),
    [account]
  );

  return (
    <Card>
      <CardActionsHeader
        actions={
          <Button
            size="sm"
            as={CrudCreateLink}
            addReturnPath
            dataType={DataType.Balance}
          >
            {t('forms:buttons.createEntity', {
              entityName: entityNamings.singularLower,
            })}
          </Button>
        }
      >
        <Heading size="md">{entityNamings.singular}</Heading>
      </CardActionsHeader>
      <CardBody>
        <CrudList<Balance>
          dataType={DataType.Balance}
          listId={OtherListIds.AccountBalances}
          params={params}
        >
          <CrudListHeader hideCreateLink />
          <CrudListTable columns={columns} />
          <CrudListFooter />
        </CrudList>
      </CardBody>
    </Card>
  );
};
