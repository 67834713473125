import React, { PropsWithChildren, useMemo } from 'react';

import { Flex, Link, SimpleGrid } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { DataType, AccountPlan, Contract } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, KeyValue } from '@m3ter-com/ui-components';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';

import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useAccountPlanContracts from '@/hooks/features/accounts/useAccountPlanContracts';
import { DetailsCard } from '@/components/common/data/DetailsCard/DetailsCard';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import {
  ColumnDefinition,
  EntitySelectModal,
} from '@/components/common/data/EntitySelectModal';
import { dataTypeRetrieveQuery } from '@/queries/crud';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';

export interface AccountPlanDetailsProps {
  accountPlan: AccountPlan;
}

const relationships = ['account'];

export const AccountPlanDetails: React.FC<
  PropsWithChildren<AccountPlanDetailsProps>
> = ({ children, accountPlan }) => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();
  const {
    contract,
    isAddingContract,
    isContractsModalOpen,
    onAddContractToAccountPlan,
    onCloseContractsModal,
    onOpenContractsModal,
  } = useAccountPlanContracts(accountPlan);

  const pathParams = useOrgPathParams();
  const {
    error: accountLoadingError,
    isLoading: isLoadingAccount,
    data: account,
  } = useQuery(
    dataTypeRetrieveQuery({
      dataType: DataType.Account,
      id: accountPlan.accountId,
      pathParams,
    })
  );

  const contractListQueryParams = useMemo(
    () => ({ accountId: account?.parentAccountId }),
    [account]
  );

  const columnDefinitions = useMemo<
    Array<ColumnDefinition<EntityWithRelationships<Contract>>>
  >(
    () => [
      { id: 'name', header: t('common:name'), accessor: 'name' },
      { id: 'code', header: t('common:code'), accessor: 'code' },
      {
        id: 'account',
        header: t('common:account'),
        accessor: (item) => item.account && item.account.name,
      },
    ],
    [t]
  );

  const isPlanGroup = !!accountPlan.planGroupId;
  const isChildAccount = !isLoadingAccount && !!account?.parentAccountId;

  return (
    <React.Fragment>
      {accountLoadingError && <ErrorAlert error={accountLoadingError} />}
      <DetailsCard
        showEditButton
        data={accountPlan}
        dataType={DataType.AccountPlan}
        header={
          isChildAccount && (
            <Flex w="100%" mr={2} justifyContent="flex-end">
              <Button
                size="sm"
                onClick={onOpenContractsModal}
                isLoading={isAddingContract}
                isDisabled={isAddingContract}
              >
                {t('features:account.addToParentContract')}
              </Button>
            </Flex>
          )
        }
        entityNameOverride={
          isPlanGroup
            ? t('features:planGroups.planGroupAttachment')
            : t('features:plans.planAttachment')
        }
        details={
          <SimpleGrid columns={4} gap={6} w="100%">
            <KeyValue
              label={t('common:start')}
              value={toLongDateTime(accountPlan.startDate)}
            />
            <KeyValue
              label={t('common:end')}
              value={
                accountPlan.endDate ? toLongDateTime(accountPlan.endDate) : '-'
              }
            />
            <KeyValue
              label={t('forms:labels.accountChildBillingMode')}
              value={
                accountPlan.childBillingMode
                  ? t(
                      `features:account.childBillingMode.${accountPlan.childBillingMode}`
                    )
                  : '-'
              }
            />
            <KeyValue
              label={t('forms:labels.contract')}
              value={
                contract ? (
                  <Link
                    as={CrudDetailsLink}
                    dataType={DataType.Contract}
                    id={contract.id}
                  >
                    {contract.name}
                  </Link>
                ) : (
                  '-'
                )
              }
            />
          </SimpleGrid>
        }
      >
        {children}
      </DetailsCard>
      <EntitySelectModal<Contract>
        columns={columnDefinitions}
        dataType={DataType.Contract}
        isOpen={isContractsModalOpen}
        onClose={onCloseContractsModal}
        onConfirm={onAddContractToAccountPlan}
        params={contractListQueryParams}
        relationships={relationships}
        selected={accountPlan.contractId ?? null}
      />
    </React.Fragment>
  );
};
