import { useCallback, KeyboardEvent } from 'react';

import { Input, InputProps } from '@m3ter-com/ui-components';

export interface ManualValueInputProps extends InputProps {
  onEnter?: () => void;
}

export const ManualValueInput: React.FC<ManualValueInputProps> = ({
  placeholder,
  onEnter,
  ...inputProps
}) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        // Prevent submitting the `form` that wraps the query builder fields.
        event.preventDefault();

        if (onEnter) {
          onEnter();
        }
      }
    },
    [onEnter]
  );

  return (
    <Input
      {...inputProps}
      placeholder={placeholder}
      aria-label={placeholder}
      onKeyDown={onKeyDown}
    />
  );
};
