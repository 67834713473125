import React, { ReactElement } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { DataType } from '@m3ter-com/m3ter-api';
import { SelectOption } from '@m3ter-com/ui-components';

import {
  SearchableEntitySelect,
  SearchableEntitySelectProps,
} from '@/components/forms/SearchableEntitySelect/SearchableEntitySelect';

export interface CrudListSearchableEntitySelectFilterProps<DT extends DataType>
  extends Omit<SearchableEntitySelectProps<DT>, 'value' | 'onChange'> {
  name: string;
  comparatorOptions: Array<SelectOption>;
}

export function CrudListSearchableEntitySelectFilter<DT extends DataType>({
  name,
  comparatorOptions,
  ...props
}: CrudListSearchableEntitySelectFilterProps<DT>): ReactElement<any, any> {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: {
          value = { comparator: comparatorOptions[0].value, value: null },
          onChange,
        },
      }) => (
        <SearchableEntitySelect
          value={value.value}
          onChange={(entityId) => onChange({ ...value, value: entityId })}
          isClearable
          {...props}
        />
      )}
    />
  );
}
