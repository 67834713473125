import React, { useMemo } from 'react';

import {
  IntegrationConfigParameterType,
  IntegrationCredential,
} from '@m3ter-com/m3ter-api';

import { BaseFormProps } from '@/types/forms';

import { getAvailableIntegrationDestinationSchemas } from '@/util/integrations';
import { useIntegrationConfigSchemaContext } from '@/components/features/integrations/IntegrationConfigSchemaContext/IntegrationConfigSchemaContext';

import { IntegrationCredentialConfigureExternalContent } from './IntegrationCredentialConfigureExternalContent';
import { IntegrationCredentialConfigureInternalContent } from './IntegrationCredentialConfigureInternalContent';

export interface IntegrationCredentialCreateFormConfigureStepProps
  extends Pick<
    BaseFormProps<IntegrationCredential>,
    'isSaving' | 'onCancel' | 'onSave'
  > {
  destination: string;
  name: string;
  onBack: () => void;
}

export const IntegrationCredentialCreateFormConfigureStep: React.FC<
  IntegrationCredentialCreateFormConfigureStepProps
> = ({ destination, isSaving, name, onBack, onCancel, onSave }) => {
  const { configSchema } = useIntegrationConfigSchemaContext();

  const destinationSchema = useMemo(() => {
    const availableDestinationSchemas =
      getAvailableIntegrationDestinationSchemas(configSchema);

    return availableDestinationSchemas.find((d) => d.apiName === destination);
  }, [configSchema, destination]);

  const renderExternalLink = useMemo(
    () =>
      !!destinationSchema?.authConfigOptions?.some(
        (authParamaterSchema) =>
          authParamaterSchema.type === IntegrationConfigParameterType.AuthLink
      ),
    [destinationSchema]
  );

  if (!destinationSchema) {
    return null;
  }

  return renderExternalLink ? (
    <IntegrationCredentialConfigureExternalContent
      destinationSchema={destinationSchema}
      name={name}
      onBack={onBack}
      onCancel={onCancel}
    />
  ) : (
    <IntegrationCredentialConfigureInternalContent
      destinationSchema={destinationSchema}
      isSaving={isSaving}
      name={name}
      onBack={onBack}
      onCancel={onCancel}
      onSave={onSave}
    />
  );
};
