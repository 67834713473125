import React, { useMemo } from 'react';

import { Outlet, useOutletContext } from 'react-router-dom';

import { TabbedLinks } from '@m3ter-com/ui-components';

import { NavTabLink } from '@/components/common/navigation/NavTabLink/NavTabLink';
import useNestedSubRoute from '@/hooks/util/useNestedSubRoute';

export interface Tab {
  to: string;
  text: string;
}

export interface TabbedRouteProps {
  tabs: Array<Tab>;
}

interface TabOutletContext {
  showTabs: boolean;
}

export const TabbedRoute: React.FC<TabbedRouteProps> = ({ tabs }) => {
  const { isNestedSubRoute } = useNestedSubRoute();

  const context = useMemo<TabOutletContext>(
    () => ({ showTabs: !isNestedSubRoute }),
    [isNestedSubRoute]
  );

  return (
    <React.Fragment>
      {!isNestedSubRoute && (
        <TabbedLinks mb={4}>
          {tabs.map(({ to, text }) => (
            <NavTabLink key={to} to={to} activeOnlyIfExact={to === ''}>
              {text}
            </NavTabLink>
          ))}
        </TabbedLinks>
      )}
      <Outlet context={context} />
    </React.Fragment>
  );
};

export const useTabContext = () => useOutletContext<TabOutletContext>() ?? {};
