import React, { ReactNode } from 'react';

import { Link, Text } from '@chakra-ui/react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert } from '@m3ter-com/ui-components';

import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';

export interface NoCurrenciesAlertProps {
  isRequired?: boolean;
  message?: ReactNode;
}

export const NoCurrenciesAlert: React.FC<NoCurrenciesAlertProps> = ({
  isRequired,
  message,
}) => {
  const { t } = useTranslation();
  return (
    <Alert status={isRequired ? 'warning' : 'info'}>
      <Text>
        {message ?? t('features:organizations.noCurrenciesMessage')}{' '}
        <Link as={CrudCreateLink} addReturnPath dataType={DataType.Currency}>
          {t('features:organizations.manageCurrencies')}
        </Link>
      </Text>
    </Alert>
  );
};
