import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { RadioGroup, Stack, Radio, Text } from '@chakra-ui/react';

export interface RadioGroupOption {
  value: string;
  label: string;
  secondaryLabel?: string;
}

export interface FormRadioGroupProps {
  name: string;
  options: Array<RadioGroupOption>;
  stacked?: boolean;
  labelStacked?: boolean;
}

export const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
  name,
  options,
  stacked = false,
  labelStacked = false,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup value={value} onChange={onChange}>
          <Stack direction={stacked ? 'column' : 'row'} alignItems="start">
            {options.map((option) => (
              <Radio key={option.value} value={option.value}>
                <Stack direction={labelStacked ? 'column' : 'row'} gap={0}>
                  <Text>{option.label}</Text>
                  {option.secondaryLabel && (
                    <Text fontSize="sm" color="chakra-subtle-text">
                      {option.secondaryLabel}
                    </Text>
                  )}
                </Stack>
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      )}
    />
  );
};
