import React from 'react';

import { DataType, Account } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { CrudRetrieve } from '@/components/common/crud/CrudRetrieve/CrudRetrieve';
import { AccountsRoute } from '@/components/routes/accounts/AccountsRoute';
import { AccountsListRoute } from '@/components/routes/accounts/AccountsListRoute';
import { AccountsCreateRoute } from '@/components/routes/accounts/AccountsCreateRoute';
import { AccountsEditRoute } from '@/components/routes/accounts/AccountsEditRoute';
import { AccountsRetrieveRoute } from '@/components/routes/accounts/AccountsRetrieveRoute';

import setupBalancesRoute from './balances';
import setupBillsRoute from './bills';
import setupChildrenRoute from './children';
import setupCommitmentsRoute from './commitments';
import setupContractsRoute from './contracts';
import setupPlansRoute from './plans';
import setUpItemCounterAdjustmentsRoute from './item-counter-adjustments';

const LazyAccountsDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "AccountsDetails" */ '@/components/routes/accounts/AccountsDetailsRoute'
  ).then((module) => ({ default: module.AccountsDetailsRoute }))
);

export default () =>
  createCrudRoute<Account>({
    path: 'accounts',
    dataType: DataType.Account,
    element: <AccountsRoute />,
    list: <AccountsListRoute />,
    details: <LazyAccountsDetailsRoute />,
    create: <AccountsCreateRoute />,
    edit: <AccountsEditRoute />,
    retrieve: (
      <CrudRetrieve<Account>
        element={<AccountsRetrieveRoute />}
        titleAccessor="name"
      />
    ),
    entityChildRoutes: [
      setupBalancesRoute(),
      setupBillsRoute(),
      setupChildrenRoute(),
      setupCommitmentsRoute(),
      setupContractsRoute(),
      setupPlansRoute(),
      setUpItemCounterAdjustmentsRoute(),
    ],
  });
