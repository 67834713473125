import React from 'react';

import { PermissionPolicy } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails/CrudDetails';
import { PermissionPolicyDetails } from '@/components/features/access/permission-policy/PermissionPolicyDetails/PermissionPolicyDetails';

export const PermissionsDetailsRoute: React.FC = () => {
  return <CrudDetails<PermissionPolicy> component={PermissionPolicyDetails} />;
};
