import React, { useMemo } from 'react';

import {
  DataType,
  IntegrationConfigDestinationSchema,
  IntegrationCredential,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormFileInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { IntegrationConfigParameterField } from '@/components/features/integrations/IntegrationConfigParameterField/IntegrationConfigParameterField';

export interface IntegrationCredentialConfigureInternalContentProps
  extends Pick<
    BaseFormProps<IntegrationCredential>,
    'isSaving' | 'onCancel' | 'onSave'
  > {
  destinationSchema: IntegrationConfigDestinationSchema;
  name: string;
  onBack: () => void;
}

// For some integration destinations, we only need to render a form with a couple of fields for things like API
// keys / secrets. This component handles rendering those fields and creating the credential.
export const IntegrationCredentialConfigureInternalContent: React.FC<
  IntegrationCredentialConfigureInternalContentProps
> = ({ destinationSchema, isSaving, name, onBack, onCancel, onSave }) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.IntegrationCredential);

  const initialValues = useMemo(
    () => ({
      destination: destinationSchema.apiName,
      type: destinationSchema.authType,
      name,
    }),
    [destinationSchema, name]
  );

  // This is temporarily needed until the endpoint which provides the form
  // field schemas is updated to return a new schema for file inputs.
  const renderPrivateKeyFileInput =
    destinationSchema.destination.toLowerCase() === 'netsuite';

  return (
    <Form initialValues={initialValues} onSubmit={onSave}>
      <FormStack>
        <FormSection
          heading={`${destinationSchema.displayName} ${t(
            'features:integrations.connection'
          )}`}
        >
          {destinationSchema.authConfigOptions?.map((authParameterSchema) => (
            <IntegrationConfigParameterField
              key={authParameterSchema.name}
              name={authParameterSchema.name}
              schema={authParameterSchema}
            />
          ))}
          {renderPrivateKeyFileInput && (
            <FormField
              name="privateKey"
              label={t('features:integrations.uploadNetsuiteCredentials')}
              control={FormFileInput}
              accept=".pem"
            />
          )}
        </FormSection>
        <FormActions
          backText={t('common:back')}
          cancelText={t('common:cancel')}
          isSaving={isSaving}
          onBack={onBack}
          onCancel={onCancel}
          submitText={t('forms:buttons.createEntity', {
            entityName: entityNamings.singularLower,
          })}
        />
      </FormStack>
    </Form>
  );
};
