import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { Spinner, VStack } from '@chakra-ui/react';

import { DataType, ExternalMapping } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Form,
  FormActions,
  FormSection,
} from '@m3ter-com/console-core/components';
import { FormStack } from '@m3ter-com/ui-components';

import { BaseFormProps } from '@/types/forms';

import { edit } from '@/validation/externalMappings';
import { dataTypeRetrieveQuery } from '@/queries/crud';
import useOrg from '@/hooks/data/crud/useOrg';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { ExternalMappingM3terIdField } from '@/components/features/external-mappings/ExternalMappingM3terIdField/ExternalMappingM3terIdField';
import { ExternalMappingExternalIdField } from '@/components/features/external-mappings/ExternalMappingExternalIdField/ExternalMappingExternalIdField';

import { ExternalMappingsFormSettingsDisplay } from './ExternalMappingsFormSettingsDisplay';

interface ExternalMappingEditFormProps
  extends Omit<BaseFormProps<ExternalMapping>, 'isEdit'> {
  initialValues: ExternalMapping;
}

export const ExternalMappingEditForm: React.FC<
  ExternalMappingEditFormProps
> = ({ isSaving, initialValues, onCancel, onSave }) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.ExternalMapping);

  const { currentOrgId: organizationId } = useOrg();
  const {
    data: linkedIntegration,
    error: linkedIntegrationError,
    isLoading: isLoadingLinkedIntegration,
  } = useQuery(
    dataTypeRetrieveQuery(
      {
        dataType: DataType.Integration,
        id: initialValues.integrationConfigId,
        pathParams: { organizationId },
      },
      { enabled: !!initialValues.integrationConfigId }
    )
  );

  if (isLoadingLinkedIntegration) {
    return <Spinner />;
  }

  return (
    <VStack alignItems="stretch" spacing={6}>
      {!!linkedIntegrationError && (
        <ErrorAlert
          error={linkedIntegrationError}
          title={t('errors:externalMappings.problemLoadingLinkedIntegration')}
        />
      )}
      <Form
        onSubmit={onSave}
        initialValues={initialValues}
        validationSchema={edit}
      >
        <FormStack>
          <ExternalMappingsFormSettingsDisplay
            externalSystem={initialValues.externalSystem}
            externalTable={initialValues.externalTable}
            m3terEntity={initialValues.m3terEntity}
          />
          <FormSection
            heading={t('common:entityDetails', {
              entityName: entityNamings.singular,
            })}
          >
            <ExternalMappingM3terIdField
              entityType={initialValues.m3terEntity}
              externalSystem={initialValues.externalSystem}
              externalTable={initialValues.externalTable}
              initialEntityId={initialValues.m3terId}
              name="m3terId"
            />
            <ExternalMappingExternalIdField
              externalSystem={initialValues.externalSystem}
              integrationCredentialId={
                linkedIntegration?.integrationCredentialsId
              }
              m3terEntityType={initialValues.m3terEntity}
              name="externalId"
            />
          </FormSection>
          <FormActions
            cancelText={t('common:cancel')}
            submitText={t('forms:buttons.updateEntity', {
              entityName: entityNamings.singularLower,
            })}
            isSaving={isSaving}
            onCancel={onCancel}
          />
        </FormStack>
      </Form>
    </VStack>
  );
};
