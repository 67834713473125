import React, { useCallback } from 'react';

import { Divider, Flex, HStack, Heading, Stack, Text } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, FormStack } from '@m3ter-com/ui-components';
import { Form, FormField, FormInput } from '@m3ter-com/console-core/components';
import { ForgotPasswordFormValues } from '@m3ter-com/console-core/types';

import useAuth from '@/hooks/auth/useAuth';
import forgotPasswordFormSchema from '@/validation/forgotPasswordForm';
import { NamedLink } from '@/components/common/navigation/NamedLink/NamedLink';
import { AuthSubmitButton } from '@/components/features/auth/AuthSubmitButton/AuthSubmitButton';

const emptyObject = {};

export const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const { onForgotPassword, isLoading } = useAuth(true);

  const onSubmit = useCallback(
    (data: ForgotPasswordFormValues) => {
      onForgotPassword(data.email);
    },
    [onForgotPassword]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={emptyObject}
      validationSchema={forgotPasswordFormSchema}
    >
      <FormStack spacing={4}>
        <Flex w="100%" align="center" justify="center">
          <Heading size="md" textAlign="center">
            {t('features:auth.forgotPassword')}
          </Heading>
        </Flex>
        <Text>{t('features:auth.resetPasswordDescription')}</Text>
        <Text variant="annotation">
          {t('features:auth.tempPasswordInformation')}
        </Text>
        <FormField
          autoFocus
          isRequired
          name="email"
          type="email"
          label={t('forms:labels.emailAddress')}
          control={FormInput}
          placeholder={t('features:auth.emailPlaceholder')}
          helpText={t('forms:helpText.caseSensitiveEmailAddress')}
        />
        <AuthSubmitButton
          isLoading={isLoading}
          buttonText={t('features:auth.resetPassword')}
        />
        <Stack w="100%" spacing={6}>
          <HStack>
            <Divider />
            <Text textStyle="sm" whiteSpace="nowrap">
              {t('features:auth.signInWith')}
            </Text>
            <Divider />
          </HStack>
        </Stack>
        <Button w="100%" as={NamedLink} name="auth.sign-in">
          {t('features:auth.emailAndPassword')}
        </Button>
      </FormStack>
    </Form>
  );
};
