import React, { useMemo } from 'react';

import { Account } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds, ListSearchOperator } from '@/types/lists';

import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';

const searchFields = ['code', 'name', 'id'];

export const AccountsListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<Account>>>(
    () => [
      { id: 'name', header: t('forms:labels.name'), accessor: 'name' },
      { id: 'code', header: t('forms:labels.code'), accessor: 'code' },
    ],
    [t]
  );

  return (
    <CrudList<Account>
      columns={columns}
      listId={EntityRouteListIds.Account}
      searchFields={searchFields}
      searchOperator={ListSearchOperator.Or}
    />
  );
};
