import React, { useMemo } from 'react';

import { useWatch } from 'react-hook-form';

import {
  DataType,
  Account,
  StatementAutoGenerateMode,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert, FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormField,
  FormInput,
  FormSection,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import { BaseFormProps, InitialValues } from '@/types/forms';

import { getCreditOrderDescription } from '@/util/credit';
import accountSchema from '@/validation/account';
import useOrg from '@/hooks/data/crud/useOrg';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useAccountHasChildren from '@/hooks/features/accounts/useAccountHasChildren';
import { BillStatementConfigFields } from '@/components/forms/BillStatementConfigFields/BillStatementConfigFields';
import { FormDatePicker } from '@/components/forms/FormDatePicker/FormDatePicker';
import { AddressFields } from '@/components/forms/AddressFields/AddressFields';
import { FormCustomFieldsEditorDefaults } from '@/components/forms/FormCustomFieldsEditorDefaults/FormCustomFieldsEditorDefaults';
import { FormActionsWithConfirmation } from '@/components/forms/FormActionsWithConfirmation/FormActionsWithConfirmation';
import {
  DataTypeFormSearchableEntitySelect,
  FormSearchableEntitySelect,
} from '@/components/forms/FormSearchableEntitySelect/FormSearchableEntitySelect';
import { NoCurrenciesAlert } from '@/components/features/organization/NoCurrenciesAlert/NoCurrenciesAlert';
import { FormPicklistSelect } from '@/components/features/accounts/FormPicklistSelect/FormPicklistSelect';
import { CreditOrderField } from '@/components/features/pricing/CreditOrderField/CreditOrderField';

export interface AccountFormProps extends BaseFormProps<Account> {}

interface AccountFormInnerProps {
  onSave: (item: Account) => void;
  isSaving: boolean;
  isEdit: boolean;
  initialValues: InitialValues<Account>;
  onCancel?: () => void;
}

const defaultInitialValues: InitialValues<Account> = {};

const accountSearchFields = ['name', 'code'];

const AccountFormInner: React.FC<AccountFormInnerProps> = ({
  onSave,
  onCancel,
  isSaving,
  isEdit,
  initialValues,
}) => {
  const { orgConfig } = useOrg();
  const accountCode = useWatch({ name: 'code' });
  const initialAccountCode = initialValues?.code;

  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Account);

  const creditApplicationOrderDescription = useMemo(
    () =>
      orgConfig.creditApplicationOrder &&
      getCreditOrderDescription(orgConfig.creditApplicationOrder),
    [orgConfig]
  );

  const { hasChildren: isParentAccount } = useAccountHasChildren(
    initialValues.id
  );

  return (
    <React.Fragment>
      <FormStack mb={4}>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <NameCodeFields />
          <FormField
            isRequired
            name="emailAddress"
            label={t('forms:labels.emailAddress')}
            control={FormInput}
          />
          <FormField
            isClearable
            name="parentAccountId"
            label={t('forms:labels.parentAccount')}
            isDisabled={isParentAccount}
            control={
              FormSearchableEntitySelect as DataTypeFormSearchableEntitySelect<DataType.Account>
            }
            dataType={DataType.Account}
            accessor="name"
            detailAccessor="code"
            searchFields={accountSearchFields}
            alert={
              isParentAccount && (
                <Alert status="info">
                  {t('features:account.parentAccountWarning')}
                </Alert>
              )
            }
          />
        </FormSection>
        <FormSection isOptional heading={t('forms:labels.address')}>
          <AddressFields keyPrefix="address" isRequired={false} />
        </FormSection>
        <FormSection
          isOptional
          heading={t('forms:labels.entitySettings', {
            entityName: t('common:bill'),
          })}
        >
          <FormField
            name="currency"
            label={t('forms:labels.currency')}
            helpText={t('forms:helpText.accountCurrency')}
            control={FormPicklistSelect}
            useCodeAsValue
            dataType={DataType.Currency}
            emptyContent={
              <NoCurrenciesAlert
                message={t('features:account.noCurrenciesMessage')}
              />
            }
          />
          <FormField
            name="billEpoch"
            label={t('forms:labels.billingCycleDate')}
            helpText={t('forms:helpText.accountBillEpoch')}
            control={FormDatePicker}
            stripTime
          />
          <FormField
            name="creditApplicationOrder"
            label={t('forms:labels.creditApplicationOrder')}
            control={CreditOrderField}
            defaultLabel={t('forms:labels.useConfigFromOrgConfig', {
              default: creditApplicationOrderDescription ?? t('common:none'),
            })}
            includeOrgConfig
          />
          <FormField
            type="number"
            name="daysBeforeBillDue"
            label={t('forms:labels.daysBeforeBillDue')}
            control={FormInput}
          />
          <FormField
            name="purchaseOrderNumber"
            label={t('forms:labels.purchaseOrderNumber')}
            control={FormInput}
          />
        </FormSection>
        <FormSection isOptional heading={t('forms:labels.billStatements')}>
          <BillStatementConfigFields
            definitionIdFieldName="statementDefinitionId"
            definitionIdHelpText={t('forms:helpText.statementDefinition')}
            generateModeHelpText={t(
              'forms:helpText.accountAutoGenerateStatements'
            )}
            defaultLabel={t('forms:labels.useConfigFromOrgConfig', {
              default: t(
                `features:statements.autoGenerateMode.${
                  orgConfig.autoGenerateStatementMode ??
                  StatementAutoGenerateMode.None
                }`
              ),
            })}
            includeOrgConfig
          />
        </FormSection>
        <FormCustomFieldsEditorDefaults dataType={DataType.Account} />
      </FormStack>
      <FormActionsWithConfirmation
        submitText={
          isEdit
            ? t('forms:buttons.updateEntity', {
                entityName: entityNamings.singularLower,
              })
            : t('forms:buttons.createEntity', {
                entityName: entityNamings.singularLower,
              })
        }
        cancelText={t('common:cancel')}
        onSave={onSave}
        showConfirmation={
          isEdit && !!initialAccountCode && accountCode !== initialAccountCode
        }
        confirmationMessage={t('features:account.confirmations.newAccountCode')}
        isSaving={isSaving}
        onCancel={onCancel}
      />
    </React.Fragment>
  );
};

export const AccountForm: React.FC<AccountFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  isEdit = false,
  initialValues = defaultInitialValues,
}) => {
  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={accountSchema}
    >
      <AccountFormInner
        onSave={onSave}
        onCancel={onCancel}
        isSaving={isSaving}
        isEdit={isEdit}
        initialValues={initialValues}
      />
    </Form>
  );
};
