import React, { useEffect, useMemo } from 'react';

import orderBy from 'lodash/orderBy';
import { useQuery } from '@tanstack/react-query';
import { Flex, Spinner, StackDivider, VStack } from '@chakra-ui/react';

import { DataType, QueryParams } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  BadgeIconButton,
  DropdownButton,
  DropdownButtonAction,
} from '@m3ter-com/ui-components';

import { EntityRouteListIds } from '@/types/lists';

import { dataTypeListQuery, dataTypeRetrieveQuery } from '@/queries/crud';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import usePreference from '@/hooks/data/usePreference';
import useOrg from '@/hooks/data/crud/useOrg';
import { NamedLink } from '@/components/common/navigation/NamedLink/NamedLink';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';
import { ExternalSystemLogo } from '@/components/common/brand/ExternalSystemLogo/ExternalSystemLogo';

import { ExternalMappingsList } from './ExternalMappingsList';

export interface ExternalMappingsTableProps {
  externalSystem: string;
}

export const AllExternalMappingsList: React.FC = () => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.ExternalMapping);

  const [selectedSystem, setSelectedSystem] = usePreference<string | null>(
    'external-mappings-list-system-filter',
    null
  );

  const { currentOrgId: organizationId } = useOrg();

  const { data: externalMappingConfig, isLoading: isLoadingConfig } = useQuery(
    dataTypeRetrieveQuery({
      dataType: DataType.ExternalMappingConfig,
      pathParams: { organizationId },
    })
  );

  // Check if any external mappings exist.
  // If they do and a system isn't selected, use the first one to set the selected system.
  // If not, just select the first of the available systems.
  const { data: firstMappings, isPending: isLoadingFirstMappings } = useQuery(
    dataTypeListQuery({
      dataType: DataType.ExternalMapping,
      pathParams: { organizationId },
      queryParams: { pageSize: 1 },
    })
  );
  const externalSystems = useMemo(
    () => orderBy(externalMappingConfig?.externalSystems || [], 'name'),
    [externalMappingConfig]
  );
  useEffect(() => {
    if (firstMappings && !selectedSystem) {
      setSelectedSystem(
        firstMappings[0]?.externalSystem || externalSystems[0]?.name
      );
    }
  }, [externalSystems, firstMappings, selectedSystem, setSelectedSystem]);

  const listActionsQueryParams = useMemo<QueryParams>(
    () => ({ externalSystem: selectedSystem }),
    [selectedSystem]
  );
  const listActions = useMemo<Array<DropdownButtonAction>>(
    () => [
      {
        key: 'create',
        label: t('forms:buttons.createEntity', {
          entityName: entityNamings.singularLower,
        }),
        as: CrudCreateLink,
        dataType: DataType.ExternalMapping,
        intent: 'primary',
        queryParams: listActionsQueryParams,
      },
      {
        key: 'bulk-create',
        label: t('common:createInBulk'),
        as: NamedLink,
        name: 'external-mappings.bulk-create',
        queryParams: listActionsQueryParams,
      },
    ],
    [entityNamings, listActionsQueryParams, t]
  );

  const listQueryParams = useMemo<QueryParams | undefined>(
    () => (selectedSystem ? { externalSystemId: selectedSystem } : undefined),
    [selectedSystem]
  );

  if (isLoadingConfig || isLoadingFirstMappings) {
    return <Spinner />;
  }

  return (
    <VStack
      alignItems="stretch"
      divider={<StackDivider />}
      justifyContent="flex-start"
      spacing={6}
    >
      <Flex
        alignItems="center"
        flexFlow="row wrap"
        gap={4}
        justifyContent="flex-start"
      >
        {externalSystems.map((system) => {
          const isSelected = selectedSystem === system.name;

          return (
            <BadgeIconButton
              addTooltip
              key={system.name}
              aria-label={system.name}
              isActive={isSelected}
              onClick={() => {
                setSelectedSystem(system.name);
              }}
              icon={<ExternalSystemLogo system={system.name} height="20px" />}
              px={4}
              py={2}
              variant="outline"
            />
          );
        })}
      </Flex>
      {!!listQueryParams && (
        <ExternalMappingsList
          includeIntegrationColumn
          actions={<DropdownButton actions={listActions} maxButtons={1} />}
          listId={EntityRouteListIds.ExternalMapping}
          queryParams={listQueryParams}
        />
      )}
    </VStack>
  );
};
