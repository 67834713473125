import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { EndBillingEntitiesFormValues } from '@/components/features/accounts/EndBillingEntitiesModal/EndBillingEntitiesModal';

const suite = create(
  'endBillingEntities',
  (data: Partial<EndBillingEntitiesFormValues>) => {
    test(
      'endDate',
      i18next.t('forms:validations.common.endDateRequired'),
      () => {
        enforce(data.endDate).isNotEmpty();
      }
    );
    test(
      'billingEntities',
      i18next.t(
        'forms:validations.endBillingEntitiesModal.billingEntitiesRequired'
      ),
      () => {
        enforce(data.billingEntities).isNotEmpty();
      }
    );
  }
);

export default suite;
