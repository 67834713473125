import { create, enforce, omitWhen, test } from 'vest';
import i18next from 'i18next';

import {
  AggregationFrequency,
  ExportSchedule,
  SourceType,
} from '@m3ter-com/m3ter-api';
import { FormValidationContext } from '@m3ter-com/console-core/components';

import { ExportScheduleFormExtraData } from '@/components/features/data-exports/ExportScheduleForm/ExportScheduleForm';

const suite = create(
  'exportSchedule',
  (
    data: Partial<ExportSchedule>,
    context: FormValidationContext<{}, ExportScheduleFormExtraData>
  ) => {
    const { isAdhoc } = context.extraData;

    omitWhen(isAdhoc, () => {
      test('name', i18next.t('forms:validations.common.nameRequired'), () => {
        enforce(data.name).isNotEmpty();
      });

      test('code', i18next.t('forms:validations.common.codeRequired'), () => {
        enforce(data.code).isNotEmpty();
      });
    });

    test(
      'destinationIds',
      i18next.t('forms:validations.dataExport.destinationsRequired'),
      () => {
        enforce(data.destinationIds).isNotEmpty();
      }
    );

    omitWhen(data.sourceType === SourceType.Usage, () => {
      test(
        'operationalDataTypes',
        i18next.t('forms:validations.dataExport.operationalDataTypesRequired'),
        () => {
          enforce(data.operationalDataTypes).isNotEmpty();
        }
      );
    });

    omitWhen(
      data.sourceType === SourceType.Operational ||
        data.aggregationFrequency === AggregationFrequency.Original,
      () => {
        test(
          'aggregation',
          i18next.t('forms:validations.dataExport.aggregationRequired'),
          () => {
            enforce(data.aggregation).isNotEmpty();
          }
        );
      }
    );
  }
);

export default suite;
