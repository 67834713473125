import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { ChildBillingMode, Commitment } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudCreate } from '@/components/common/crud/CrudCreate/CrudCreate';
import { RequiresCurrenciesWrapper } from '@/components/features/organization/RequiresCurrenciesWrapper/RequiresCurrenciesWrapper';
import { CommitmentForm } from '@/components/features/accounts/CommitmentForm/CommitmentForm';

export const CommitmentsCreateRoute: React.FC = () => {
  const { t } = useTranslation();
  const { accountId } = useParams();

  const initialValues = useMemo<Partial<Commitment>>(
    () => ({
      accountId,
      amountPrePaid: 0,
      billingInterval: 1,
      billingOffset: 0,
      childBillingMode: ChildBillingMode.ParentBreakdown,
      feeDates: [],
    }),
    [accountId]
  );

  return (
    <RequiresCurrenciesWrapper
      message={t('features:commitments.noCurrenciesMessage')}
    >
      <CrudCreate<Commitment>
        form={CommitmentForm}
        initialValues={initialValues}
      />
    </RequiresCurrenciesWrapper>
  );
};
