import React, { useMemo } from 'react';

import {
  Box,
  Heading,
  Link,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';

import { DataType, Account, AnalyticsJobType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  KeyValue,
  KeyValueList,
  KeyValueListItem,
} from '@m3ter-com/ui-components';

import { isProd } from '@/config';
import { getReportRouteName } from '@/routes/reports';
import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useAccountRecurringRevenue from '@/hooks/features/accounts/useAccountRecurringRevenue';
import { AuditData } from '@/components/common/data/AuditData/AuditData';
import { AddressDetails } from '@/components/common/data/AddressDetails/AddressDetails';
import { KeyValueReferenceLink } from '@/components/common/data/KeyValueReferenceLink/KeyValueReferenceLink';
import { NamedLink } from '@/components/common/navigation/NamedLink/NamedLink';

export interface AccountDetailsProps {
  account: Account;
}

export const AccountDetails: React.FC<AccountDetailsProps> = ({ account }) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { toLongDate } = useDateFormatter();

  const showAddressField = useMemo(
    () => !!account.address && Object.values(account.address).some(Boolean),
    [account]
  );

  const accountCode = account.code;
  const recurringRevenueReportLinkParams = useMemo(
    () => ({
      account: accountCode,
    }),
    [accountCode]
  );
  const { isLoading: isLoadingRecurringRevenueData, recurringRevenueData } =
    useAccountRecurringRevenue(accountCode);

  return (
    <VStack alignItems="start" spacing={4} divider={<StackDivider />}>
      <VStack alignItems="flex-start">
        {showAddressField && (
          <KeyValue
            data-testid="account-details-address"
            label={t('forms:labels.address')}
            value={<AddressDetails data={account.address!} />}
          />
        )}
        <KeyValue label={t('forms:labels.code')} value={account.code} />
        {!!account.purchaseOrderNumber && (
          <KeyValue
            label={t('forms:labels.purchaseOrderNumber')}
            value={account.purchaseOrderNumber}
          />
        )}
        <KeyValue
          label={t('forms:labels.emailAddress')}
          value={account.emailAddress}
        />
        {!!account.billEpoch && (
          <KeyValue
            label={t('forms:labels.billingCycleDate')}
            value={toLongDate(account.billEpoch)}
          />
        )}
        {!!account.parentAccountId && (
          <KeyValueReferenceLink
            label={t('features:account.parentAccount')}
            dataType={DataType.Account}
            id={account.parentAccountId}
            accessor="name"
          />
        )}
        {!!account.currency && (
          <KeyValue
            data-testid="account-details-currency"
            label={t('forms:labels.currency')}
            value={account.currency}
          />
        )}
        {!!account.daysBeforeBillDue && (
          <KeyValue
            data-testid="account-details-days-before-bill-due"
            label={t('forms:labels.daysBeforeBillDue')}
            value={account.daysBeforeBillDue}
          />
        )}
      </VStack>
      {!isProd() && (
        <Box data-testid="account-details-recurring-revenue">
          <Heading size="xs" mb={2} as="h4">
            {t(
              'features:reports.recurringRevenue.accountSummary.currentMrrArr'
            )}
          </Heading>
          {isLoadingRecurringRevenueData ? (
            <Spinner
              data-testid="account-details-recurring-revenue-spinner"
              size="xs"
            />
          ) : (
            <KeyValueList>
              <KeyValueListItem
                label={t(
                  'features:reports.recurringRevenue.accountSummary.standard'
                )}
                value={
                  <Text data-testid="account-details-recurring-revenue-standard-value">
                    {recurringRevenueData.standardCurrency ? (
                      <Link
                        as={NamedLink}
                        name={getReportRouteName(
                          AnalyticsJobType.RecurringRevenueReport
                        )}
                        queryParams={recurringRevenueReportLinkParams}
                      >
                        {formatCurrency(
                          recurringRevenueData.standardMrr,
                          recurringRevenueData.standardCurrency
                        )}
                        {' / '}
                        {formatCurrency(
                          recurringRevenueData.standardArr,
                          recurringRevenueData.standardCurrency
                        )}
                      </Link>
                    ) : (
                      '- / -'
                    )}
                  </Text>
                }
              />
              <KeyValueListItem
                label={t(
                  'features:reports.recurringRevenue.accountSummary.billingBased'
                )}
                value={
                  <Text data-testid="account-details-recurring-revenue-billing-based-value">
                    {recurringRevenueData.billingBasedCurrency ? (
                      <Link
                        as={NamedLink}
                        name={getReportRouteName(
                          AnalyticsJobType.BillingBasedRecurringRevenueReport
                        )}
                        queryParams={recurringRevenueReportLinkParams}
                      >
                        {formatCurrency(
                          recurringRevenueData.billingBasedMrr,
                          recurringRevenueData.billingBasedCurrency
                        )}
                        {' / '}
                        {formatCurrency(
                          recurringRevenueData.billingBasedArr,
                          recurringRevenueData.billingBasedCurrency
                        )}
                      </Link>
                    ) : (
                      '- / -'
                    )}
                  </Text>
                }
              />
            </KeyValueList>
          )}
        </Box>
      )}
      <AuditData data={account} />
    </VStack>
  );
};
