import { Fragment, useMemo } from 'react';

import { OutboundWebhook } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge/ActiveStatusBadge';
import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';

const searchFields = ['name', 'code'];

export const OutboundWebhooksListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<OutboundWebhook>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (webhook) => (
          <Fragment>
            {webhook.name}
            <ActiveStatusBadge active={webhook.active} />
          </Fragment>
        ),
      },
      {
        id: 'url',
        accessor: 'url',
        header: t('forms:labels.url'),
      },
    ],
    [t]
  );

  return (
    <CrudList<OutboundWebhook>
      listId={EntityRouteListIds.OutboundWebhook}
      columns={columns}
      searchFields={searchFields}
    />
  );
};
