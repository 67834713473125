import React, { useMemo, ElementType, ReactElement } from 'react';

import { Entity, UnsavedEntity, PathParams } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { DeepPartial } from '@m3ter-com/console-core/types';

import { cloneEntity } from '@/util/data';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useCrudCreate from '@/hooks/data/crud/useCrudCreate';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch/LoadingErrorContentSwitch';

export interface CrudCreateProps<E extends Entity> {
  form: ElementType;
  extraData?: Record<string, any>;
  initialValues?: DeepPartial<E>;
  pathParams?: PathParams;
  duplicateMerge?: (entity: UnsavedEntity<E>) => Partial<E>;
}

const defaultInitialValues: DeepPartial<any> = {};

export function CrudCreate<E extends Entity>({
  form: Form,
  initialValues = defaultInitialValues,
  extraData,
  pathParams,
  duplicateMerge,
}: CrudCreateProps<E>): ReactElement<any, any> | null {
  const { t } = useTranslation();

  const {
    createItem,
    dataType,
    duplicate,
    error,
    isLoading,
    isSaving,
    onCancel,
  } = useCrudCreate<E>(pathParams);

  const initialOrDuplicateValues = useMemo(() => {
    if (!duplicate) {
      return initialValues;
    }

    if (!duplicateMerge) {
      return cloneEntity(duplicate);
    }

    return duplicateMerge(cloneEntity(duplicate));
  }, [duplicate, duplicateMerge, initialValues]);

  const { singular: entityName } = useEntityNamings(dataType);

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:create')} />
      <LoadingErrorContentSwitch
        showContentOnError
        isLoading={isLoading}
        error={error}
        errorTitle={t('errors:generic.problemSavingData', { entityName })}
      >
        <Form
          isSaving={isSaving}
          onSave={createItem}
          onCancel={onCancel}
          initialValues={initialOrDuplicateValues}
          extraData={extraData}
          entityName={entityName}
          dataType={dataType}
        />
      </LoadingErrorContentSwitch>
    </React.Fragment>
  );
}
