import React from 'react';

import { DataType, Account } from '@m3ter-com/m3ter-api';

import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';
import { AccountBalances } from '@/components/features/accounts/AccountBalances/AccountBalances';

export const BalancesListRoute: React.FC = () => {
  const account = useCrudRetrieveData<Account>(DataType.Account);

  return <AccountBalances account={account} />;
};
