import React, { useMemo } from 'react';

import { Link } from '@chakra-ui/react';

import {
  DataType,
  ExternalMapping,
  externalMappingEntityTypeToDataType,
} from '@m3ter-com/m3ter-api';

import { useAppRoutesContext } from '@/routes/context';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';

export interface ExternalMappingM3terIdLinkProps {
  externalMapping: ExternalMapping;
}

// We can't cpnstruct links to all data types since some are under nested routes
// e.g. .../accounts/:accountId/prepaymeents/:prepaymentId
// and we only have the entity ID, not the IDs of associated entities
const linkableDataTypes = [
  DataType.Account,
  DataType.Aggregation,
  DataType.Bill,
  DataType.CompoundAggregation,
  DataType.Meter,
  DataType.Plan,
  DataType.Product,
];

export const ExternalMappingM3terIdLink: React.FC<
  ExternalMappingM3terIdLinkProps
> = ({ externalMapping }) => {
  const { routeExists } = useAppRoutesContext();
  const m3terEntityDataType =
    externalMappingEntityTypeToDataType[externalMapping.m3terEntity];
  const canLink = useMemo(
    () =>
      !!m3terEntityDataType &&
      routeExists(
        getCrudRouteName(m3terEntityDataType, CrudRouteType.Details)
      ) &&
      linkableDataTypes.includes(m3terEntityDataType),
    [m3terEntityDataType, routeExists]
  );

  return canLink ? (
    <Link
      as={CrudDetailsLink}
      dataType={m3terEntityDataType!}
      id={externalMapping.m3terId}
    >
      {externalMapping.m3terId}
    </Link>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>{externalMapping.m3terId}</React.Fragment>
  );
};
