import React from 'react';

import { DataType, IntegrationTransformation } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { createCrudRoute } from '@/routes/crud';

const LazyIntegrationTransformationsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationTransformationsRoute" */ '@/components/routes/integrations/transformations/IntegrationTransformationsRoute'
  ).then((module) => ({ default: module.IntegrationTransformationsRoute }))
);

const LazyIntegrationTransformationsListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationTransformationsListRoute" */ '@/components/routes/integrations/transformations/IntegrationTransformationsListRoute'
  ).then((module) => ({ default: module.IntegrationTransformationsListRoute }))
);

const LazyIntegrationTransformationCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationTransformationCreateRoute" */ '@/components/routes/integrations/transformations/IntegrationTransformationCreateRoute'
  ).then((module) => ({ default: module.IntegrationTransformationCreateRoute }))
);

const LazyIntegrationTransformationEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationTransformationEditRoute" */ '@/components/routes/integrations/transformations/IntegrationTransformationEditRoute'
  ).then((module) => ({ default: module.IntegrationTransformationEditRoute }))
);

const LazyIntegrationTransformationDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationTransformationDetailsRoute" */ '@/components/routes/integrations/transformations/IntegrationTransformationDetailsRoute'
  ).then((module) => ({
    default: module.IntegrationTransformationDetailsRoute,
  }))
);

export default (): NamedRoute =>
  createCrudRoute<IntegrationTransformation>({
    path: 'transformations',
    dataType: DataType.IntegrationTransformation,
    element: <LazyIntegrationTransformationsRoute />,
    list: <LazyIntegrationTransformationsListRoute />,
    details: <LazyIntegrationTransformationDetailsRoute />,
    edit: <LazyIntegrationTransformationEditRoute />,
    create: <LazyIntegrationTransformationCreateRoute />,
  });
