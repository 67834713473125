import { useCallback, useMemo } from 'react';

import { useQueries, useQuery } from '@tanstack/react-query';

import {
  AccountPlan,
  DataType,
  Id,
  Plan,
  PlanGroup,
  Product,
} from '@m3ter-com/m3ter-api';
import {
  getCleanDateInstance,
  getDatesSortOrder,
} from '@m3ter-com/console-core/utils';

import {
  PlanAccountPlan,
  PlanAccountPlanDataByProduct,
  PlanAccountPlanDatum,
  PlanAccountPlansByProduct,
  PlanGroupAccountPlan,
  PlanGroupAccountPlanDatum,
} from '@/types/data';

import useOrg from '@/hooks/data/crud/useOrg';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { dataTypeListAllQuery, dataTypeListQuery } from '@/queries/crud';
import useEntityDeleteMutation from '@/hooks/data/useEntityDeleteMutation';

/**
 * Builds a mapping of product IDs to their associated account plans and plans.
 *
 * @param planAccountPlanDataByProduct - An object where the keys are product IDs and the values are arrays of plan account plan data.
 * @param accountPlans - An array of account plans.
 * @param plans - An array of plans.
 * @param products - An array of products.
 * @returns An object where the keys are product IDs and the values are arrays of plan account plans with associated plan and product.
 */
export const buildPlanAccountPlans = (
  planAccountPlanDataByProduct: PlanAccountPlanDataByProduct,
  accountPlans: Array<AccountPlan>,
  plans: Array<Plan>,
  products: Array<Product>
): PlanAccountPlansByProduct => {
  const result: PlanAccountPlansByProduct = {};

  Object.entries(planAccountPlanDataByProduct).forEach(
    ([productId, planAccountPlanData]) => {
      const product = products.find(({ id }) => id === productId);
      if (!product) {
        return;
      }

      planAccountPlanData.forEach((datum) => {
        const accountPlan = accountPlans.find(
          ({ id }) => id === datum.accountPlanId
        );
        const plan = plans.find(({ id }) => id === datum.planId);
        if (accountPlan && plan) {
          if (!result[productId]) {
            result[productId] = new Array<PlanAccountPlan>();
          }

          result[productId].push({
            ...accountPlan,
            plan,
            product,
          });
        }
      });
    }
  );

  return result;
};

/**
 * Builds an array of PlanGroupAccountPlan objects by combining data from planGroupData, accountPlans, and planGroups.
 *
 * @param {Array<PlanGroupAccountPlanDatum>} planGroupData - An array of PlanGroupAccountPlanDatum objects containing the mapping between account plans and plan groups.
 * @param {Array<AccountPlan>} accountPlans - An array of AccountPlan objects.
 * @param {Array<PlanGroup>} planGroups - An array of PlanGroup objects.
 * @returns {Array<PlanGroupAccountPlan>} An array of PlanGroupAccountPlan objects that combines data from the input arrays.
 */
export const buildPlanGroupAccountPlans = (
  planGroupData: Array<PlanGroupAccountPlanDatum>,
  accountPlans: Array<AccountPlan>,
  planGroups: Array<PlanGroup>
): Array<PlanGroupAccountPlan> =>
  planGroupData
    .map((datum) => {
      const accountPlan = accountPlans.find(
        ({ id }) => id === datum.accountPlanId
      );
      const planGroup = planGroups.find(({ id }) => id === datum.planGroupId);
      if (!accountPlan || !planGroup) {
        return undefined;
      }

      return {
        ...accountPlan,
        planGroup,
      };
    })
    .filter(Boolean) as Array<PlanGroupAccountPlan>;

const useAccountPlans = (accountId: Id) => {
  const pathParams = useOrgPathParams();
  const { orgConfig } = useOrg();

  const {
    error: accountPlanLoadingError,
    isLoading: isLoadingAccountPlans,
    data: accountPlans = [],
  } = useQuery(
    dataTypeListAllQuery({
      dataType: DataType.AccountPlan,
      pathParams,
      queryParams: { account: accountId, includeall: true },
    })
  );

  const sortedAccountPlans = useMemo(
    () =>
      accountPlans.sort((currentAccountPlan, nextAccountPlan) =>
        getDatesSortOrder(
          currentAccountPlan.startDate,
          nextAccountPlan.startDate
        )
      ),
    [accountPlans]
  );

  const { deleteEntity: deleteAccountPlan } = useEntityDeleteMutation(
    DataType.AccountPlan
  );

  const onDeleteAccountPlan = useCallback(
    (accountPlan: AccountPlan) => {
      deleteAccountPlan({ entity: accountPlan });
    },
    [deleteAccountPlan]
  );

  /**
   * useMemo hook to process and categorize account plans and plan groups.
   *
   * This hook processes the sortedAccountPlans array and categorizes the plans and plan groups
   * into active/pending and previous categories. It also collects unique plan IDs, product IDs,
   * and plan group IDs.
   *
   * @param {Object} orgConfig - The organization configuration object containing timezone information.
   * @param {Array} sortedAccountPlans - An  array of account plans sorted by date asc.
   *
   * @returns {Object} An object containing:
   * - activeAndPendingPlansData: A mapping of product IDs to arrays of active or pending plan data.
   * - previousPlansData: A mapping of product IDs to arrays of previous plan data.
   * - activeAndPendingPlanGroupsData: An array of active or pending plan group data.
   * - previousPlanGroupsData: An array of previous plan group data.
   * - planIdsSet: A Set of unique plan IDs.
   * - productIdsSet: A Set of unique product IDs.
   * - planGroupIdsSet: A Set of unique plan group IDs.
   */
  const {
    activeAndPendingPlansData: activeAndPendingPlans,
    previousPlansData: previousPlans,
    activeAndPendingPlanGroupsData: activeAndPendingPlanGroups,
    previousPlanGroupsData: previousPlanGroups,
    planIdsSet: planIds,
    planGroupIdsSet: planGroupIds,
    productIdsSet: productIds,
  } = useMemo(() => {
    const activeAndPendingPlansData: PlanAccountPlanDataByProduct = {};
    const previousPlansData: PlanAccountPlanDataByProduct = {};
    const activeAndPendingPlanGroupsData =
      new Array<PlanGroupAccountPlanDatum>();

    const previousPlanGroupsData = new Array<PlanGroupAccountPlanDatum>();
    const planIdsSet = new Set<Id>();
    const productIdsSet = new Set<Id>();
    const planGroupIdsSet = new Set<Id>();

    sortedAccountPlans.forEach((accountPlan) => {
      const accountPlanEndDate =
        accountPlan.endDate &&
        getCleanDateInstance(accountPlan.endDate, orgConfig.timezone);
      const isActiveOrPending =
        !accountPlanEndDate || accountPlanEndDate > new Date(Date.now());

      if (accountPlan.planId && accountPlan.productId) {
        const { id: accountPlanId, planId, productId } = accountPlan;
        planIdsSet.add(planId);
        productIdsSet.add(productId);

        const destination = isActiveOrPending
          ? activeAndPendingPlansData
          : previousPlansData;
        if (!destination[productId]) {
          destination[productId] = new Array<PlanAccountPlanDatum>();
        }
        const data: PlanAccountPlanDatum = {
          accountPlanId,
          planId,
          productId,
        };

        destination[productId].push(data);
      } else if (accountPlan.planGroupId) {
        const { id: accountPlanId, planGroupId } = accountPlan;
        planGroupIdsSet.add(accountPlan.planGroupId);
        const destination = isActiveOrPending
          ? activeAndPendingPlanGroupsData
          : previousPlanGroupsData;

        const data: PlanGroupAccountPlanDatum = { accountPlanId, planGroupId };
        destination.push(data);
      }
    });

    return {
      activeAndPendingPlansData,
      previousPlansData,
      activeAndPendingPlanGroupsData,
      previousPlanGroupsData,
      planIdsSet,
      productIdsSet,
      planGroupIdsSet,
    };
  }, [orgConfig, sortedAccountPlans]);

  const accountPlanData = useMemo(
    () => ({
      plandIds: Array.from(planIds),
      productIds: Array.from(productIds),
      planGroupIds: Array.from(planGroupIds),
    }),
    [planGroupIds, planIds, productIds]
  );

  const [plans, products, planGroups] = useQueries({
    queries: [
      dataTypeListQuery(
        {
          dataType: DataType.Plan,
          pathParams,
          queryParams: { ids: accountPlanData.plandIds },
        },
        { enabled: accountPlanData.plandIds.length > 0 }
      ),
      dataTypeListQuery(
        {
          dataType: DataType.Product,
          pathParams,
          queryParams: { ids: accountPlanData.productIds },
        },
        { enabled: accountPlanData.productIds.length > 0 }
      ),
      dataTypeListQuery(
        {
          dataType: DataType.PlanGroup,
          pathParams,
          queryParams: { ids: accountPlanData.planGroupIds },
        },
        { enabled: accountPlanData.planGroupIds.length > 0 }
      ),
    ],
  });

  const activeAndPendingPlanAccountPlans = useMemo(
    () =>
      buildPlanAccountPlans(
        activeAndPendingPlans,
        sortedAccountPlans,
        plans?.data ?? [],
        products?.data ?? []
      ),
    [activeAndPendingPlans, plans, products, sortedAccountPlans]
  );

  const previousPlanAccountPlans = useMemo(
    () =>
      buildPlanAccountPlans(
        previousPlans,
        sortedAccountPlans,
        plans?.data ?? [],
        products?.data ?? []
      ),
    [plans, previousPlans, products, sortedAccountPlans]
  );

  const activeAndPendingPlanGroupAccountPlans = useMemo(
    () =>
      buildPlanGroupAccountPlans(
        activeAndPendingPlanGroups,
        sortedAccountPlans,
        planGroups?.data ?? []
      ),
    [activeAndPendingPlanGroups, planGroups, sortedAccountPlans]
  );

  const previousPlanGroupAccountPlans = useMemo(
    () =>
      buildPlanGroupAccountPlans(
        previousPlanGroups,
        sortedAccountPlans,
        planGroups?.data ?? []
      ),
    [planGroups, previousPlanGroups, sortedAccountPlans]
  );

  const errors = useMemo(
    () => [
      accountPlanLoadingError,
      plans.error,
      products.error,
      planGroups.error,
    ],
    [accountPlanLoadingError, planGroups, plans, products]
  );

  const isLoading =
    isLoadingAccountPlans ||
    plans.isLoading ||
    products.isLoading ||
    planGroups.isLoading;

  return {
    isLoading,
    errors,
    activeAndPendingPlanAccountPlans,
    activeAndPendingPlanGroupAccountPlans,
    onDeleteAccountPlan,
    previousPlanAccountPlans,
    previousPlanGroupAccountPlans,
  };
};

export default useAccountPlans;
