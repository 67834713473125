import React from 'react';

import { Aggregation } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails/CrudDetails';
import { AggregationDetails } from '@/components/features/aggregations/AggregationDetails/AggregationDetails';

const AggregationsDetailsRouteComponent: React.FC<{ data: Aggregation }> = ({
  data,
}) => <AggregationDetails showEditButton showSegments aggregation={data} />;

export const AggregationsDetailsRoute: React.FC = () => (
  <CrudDetails<Aggregation> component={AggregationsDetailsRouteComponent} />
);
