import React, { useMemo } from 'react';

import { ExternalMapping } from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudQueryParams } from '@/types/routes';

import useQueryString from '@/hooks/navigation/useQueryString';
import { useCrudContext } from '@/components/common/crud/CrudContext/CrudContext';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import {
  ExternalMappingsCreateForm,
  ExternalMappingsCreateFormValues,
  ExternalMappingsCreateMode,
} from '@/components/features/external-mappings/ExternalMappingForms/ExternalMappingsCreateForm';

type ExternalMappingsBulkCreateRouteQueryParams = Partial<
  Pick<
    ExternalMapping,
    'externalSystem' | 'integrationConfigId' | 'm3terEntity' | 'm3terId'
  > & {
    returnPath: string;
  }
>;

export const ExternalMappingsBulkCreateRoute: React.FC = () => {
  const { t } = useTranslation();
  const { basePath, onCancel } = useCrudContext<ExternalMapping>();
  const {
    externalSystem,
    integrationConfigId,
    m3terEntity,
    m3terId,
    returnPath = basePath,
  } = useQueryString<
    CrudQueryParams & ExternalMappingsBulkCreateRouteQueryParams
  >();

  const initialValues = useMemo<DeepPartial<ExternalMappingsCreateFormValues>>(
    () => ({
      externalSystem,
      m3terEntity,
      mappings: [
        {
          m3terId,
        },
      ],
    }),
    [externalSystem, m3terEntity, m3terId]
  );

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:createInBulk')} />
      <ExternalMappingsCreateForm
        initialValues={initialValues}
        linkedIntegrationId={integrationConfigId}
        mode={ExternalMappingsCreateMode.Bulk}
        onCancel={onCancel}
        returnPath={returnPath}
      />
    </React.Fragment>
  );
};
