import React from 'react';

import { SimpleGrid, VStack } from '@chakra-ui/react';

import {
  DataType,
  Organization,
  OrganizationStatus,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge/ActiveStatusBadge';
import { AddressDetails } from '@/components/common/data/AddressDetails/AddressDetails';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { DetailsCard } from '@/components/common/data/DetailsCard/DetailsCard';
import { ReferenceLink } from '@/components/common/data/ReferenceLink/ReferenceLink';
import { OrganizationTypeBadge } from '@/components/features/organization/OrganizationTypeBadge/OrganizationTypeBadge';

export interface OrganizationDetailsProps {
  org: Organization;
  adminActions?: React.ReactNode;
  showMetadata?: boolean;
  showAdminDetails?: boolean;
}

export const OrganizationDetails: React.FC<OrganizationDetailsProps> = ({
  org,
  adminActions,
  showMetadata,
  showAdminDetails = false,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {showAdminDetails && <BreadcrumbItem title={org.organizationName} />}
      <DetailsCard
        data={org}
        dataType={DataType.Organization}
        header={adminActions}
        showMetadata={showMetadata}
        details={
          <SimpleGrid columns={3} gap={6} w="100%">
            <VStack alignItems="start">
              <KeyValue
                label={t('features:organizations.name')}
                value={org.organizationName}
              />
              <KeyValue
                label={t('features:organizations.shortName')}
                value={org.shortName}
              />
              {showAdminDetails && (
                <KeyValue
                  label={t('common:customer')}
                  value={
                    org.customerId ? (
                      <ReferenceLink
                        dataType={DataType.Customer}
                        id={org.customerId}
                        accessor="name"
                      />
                    ) : (
                      '-'
                    )
                  }
                />
              )}
            </VStack>
            <VStack alignItems="start">
              <KeyValue
                label={t('forms:labels.type')}
                value={
                  org.type ? <OrganizationTypeBadge organization={org} /> : '-'
                }
              />
              <KeyValue
                label={t('forms:labels.status')}
                value={
                  org.status ? (
                    <ActiveStatusBadge
                      ml={0}
                      active={org.status === OrganizationStatus.Active}
                      inactiveLabel={t(
                        `features:organizations.status.${OrganizationStatus.Archived}`
                      )}
                    />
                  ) : (
                    '-'
                  )
                }
              />
            </VStack>
            <KeyValue
              label={t('forms:labels.address')}
              value={<AddressDetails data={org} />}
            />
          </SimpleGrid>
        }
      />
    </React.Fragment>
  );
};
