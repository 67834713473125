import React, { ReactElement } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { DataType } from '@m3ter-com/m3ter-api';

import {
  SearchableEntitySelect,
  SearchableEntitySelectProps,
} from '@/components/forms/SearchableEntitySelect/SearchableEntitySelect';

export interface FormSearchableEntitySelectProps<DT extends DataType>
  extends Omit<SearchableEntitySelectProps<DT>, 'value' | 'onChange'> {
  name: string;
}

// Helper for casting when using `FormField`.
export type DataTypeFormSearchableEntitySelect<DT extends DataType> = React.FC<
  FormSearchableEntitySelectProps<DT>
>;

export function FormSearchableEntitySelect<DT extends DataType>({
  name,
  ...props
}: FormSearchableEntitySelectProps<DT>): ReactElement<any, any> {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <SearchableEntitySelect value={value} onChange={onChange} {...props} />
      )}
    />
  );
}
