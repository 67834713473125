import React, { PropsWithChildren } from 'react';

import useCurrencies from '@/hooks/util/useCurrencies';
import { NoCurrenciesAlert } from '@/components/features/organization/NoCurrenciesAlert/NoCurrenciesAlert';

export interface RequiresCurrenciesWrapperProps {
  message?: string;
}

export const RequiresCurrenciesWrapper: React.FC<
  PropsWithChildren<RequiresCurrenciesWrapperProps>
> = ({ message, children }) => {
  const { currencies } = useCurrencies();
  const hasCurrencies = currencies && currencies.length > 0;

  return hasCurrencies ? (
    // Without the Fragment the return type doesn't match `React.FC`
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <NoCurrenciesAlert isRequired message={message} />
  );
};
