import React, { useMemo } from 'react';

import { Meter, MeterFieldCategory } from '@m3ter-com/m3ter-api';

import { MeterForm } from '@/components/features/meters/MeterForm/MeterForm';
import { CrudCreate } from '@/components/common/crud/CrudCreate/CrudCreate';
import useQueryString from '@/hooks/navigation/useQueryString';

interface MeterCreateQueryParams {
  productId?: string;
}

export const MetersCreateRoute: React.FC = () => {
  const { productId } = useQueryString<MeterCreateQueryParams>();

  const initialValues = useMemo(() => {
    const values: Partial<Meter> = {
      dataFields: [
        { code: '', name: '', category: MeterFieldCategory.MEASURE, unit: '' },
      ],
      derivedFields: [],
    };

    if (productId) {
      values.productId = productId;
    }

    return values;
  }, [productId]);

  return <CrudCreate<Meter> form={MeterForm} initialValues={initialValues} />;
};
