import React, { ComponentProps, ReactElement, ReactNode } from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { Select } from '@m3ter-com/ui-components';

import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import useSearchableEntityOptions, {
  UseSearchableEntityOptionsProps,
} from '@/hooks/data/useSearchableEntityOptions';

export interface SearchableEntitySelectProps<DT extends DataType>
  extends Omit<ComponentProps<typeof Select>, 'options'>,
    UseSearchableEntityOptionsProps<DT> {
  isRequired?: boolean;
  emptyContent?: ReactNode;
}

export function SearchableEntitySelect<DT extends DataType>({
  dataType,
  emptyContent,
  searchFields,
  accessor,
  detailAccessor,
  optionValueAccessor = 'id',
  value,
  ...selectProps
}: SearchableEntitySelectProps<DT>): ReactElement<any, any> {
  if (optionValueAccessor !== 'id') {
    throw new Error(
      'SearchableEntitySelect can only be used with entity `id`s as the values.'
    );
  }

  const { error, isLoading, options, search } = useSearchableEntityOptions({
    accessor,
    dataType,
    detailAccessor,
    optionValueAccessor,
    searchFields,
    value,
  });

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return options.length > 0 || !emptyContent ? (
    <Select
      isSearchable
      onInputChange={search}
      filterOption={null}
      isLoading={isLoading}
      options={options}
      value={value}
      {...selectProps}
    />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>{emptyContent}</React.Fragment>
  );
}
