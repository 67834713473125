import React, { Suspense } from 'react';

import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';

import {
  AppLayout,
  AppLayoutHeader,
  AppLayoutMain,
  AppLayoutNavigation,
  LoadingScreen,
  NavigationPanel,
  NotFoundScreen,
} from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { AdminNavigation } from '@/components/common/navigation/AdminNavigation/AdminNavigation';
import { ConsoleHeader } from '@/components/common/navigation/ConsoleHeader/ConsoleHeader';
import { PageHeader } from '@/components/common/navigation/PageHeader/PageHeader';
import { selectIsBootstrapping } from '@/store/app/bootstrap/bootstrap';
import {
  removeAuthRedirectPath,
  setAuthRedirectPath,
} from '@/util/localStorage';
import useAuth from '@/hooks/auth/useAuth';
import { TimezoneContextProvider } from '@/hooks/util/useDateFormatter';

export const AdminRoute: React.FC = () => {
  const { t } = useTranslation();
  const { isM3terAdmin, isLoading } = useAuth();
  const isAppBootstrapping = useSelector(selectIsBootstrapping);

  if (isAppBootstrapping) {
    setAuthRedirectPath('/admin');
    return <LoadingScreen />;
  }
  removeAuthRedirectPath();

  if (!isAppBootstrapping && !isLoading && !isM3terAdmin) {
    return <NotFoundScreen message={t('common:pageNotFound')} />;
  }

  return (
    <TimezoneContextProvider timeZone="UTC">
      <AppLayout>
        <AppLayoutHeader>
          <ConsoleHeader showNavigation={false} showOrgSwitcher={false} />
        </AppLayoutHeader>
        <AppLayoutNavigation>
          <NavigationPanel>
            <AdminNavigation />
          </NavigationPanel>
        </AppLayoutNavigation>
        <AppLayoutMain>
          <PageHeader />
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </AppLayoutMain>
      </AppLayout>
    </TimezoneContextProvider>
  );
};
