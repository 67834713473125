import React from 'react';

import { DataType, Id, SupportAccess } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { FormDatePicker } from '@/components/forms/FormDatePicker/FormDatePicker';
import {
  DataTypeFormEntityMultiSelect,
  FormEntityMultiSelect,
} from '@/components/forms/FormEntityMultiSelect/FormEntityMultiSelect';

export interface SupportAccessUpdateFormValues extends SupportAccess {
  permissionPolicyIds: Array<Id>;
}

export interface SupportAccessFormProps
  extends BaseFormProps<SupportAccessUpdateFormValues> {}

const defaultInitialValues: Partial<SupportAccessUpdateFormValues> = {};

export const SupportAccessForm: React.FC<SupportAccessFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.SupportAccess);

  return (
    <Form initialValues={initialValues} onSubmit={onSave}>
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <FormField
            isRequired
            name="dtEndSupportUsersAccess"
            label={t('forms:labels.supportAccessEndDate')}
            helpText={t('forms:helpText.supportAccessEndDate')}
            control={FormDatePicker}
          />
          <FormField
            name="permissionPolicyIds"
            label={t('common:permissionPolicys')}
            control={
              FormEntityMultiSelect as DataTypeFormEntityMultiSelect<DataType.PermissionPolicy>
            }
            dataType={DataType.PermissionPolicy}
            helpText={t('forms:helpText.supportAccessPermissions')}
            accessor="name"
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={t('forms:buttons.updateEntity', {
            entityName: entityNamings.singularLower,
          })}
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
