import React from 'react';

import { Outlet } from 'react-router-dom';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';

export interface SupportAccessRouteProps {}

export const SupportAccessRoute: React.FC<SupportAccessRouteProps> = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:supportAccess')} />
      <Outlet />
    </React.Fragment>
  );
};
