import React from 'react';

import { Commitment } from '@m3ter-com/m3ter-api';

import { CrudRetrieve } from '@/components/common/crud/CrudRetrieve/CrudRetrieve';

const relationships = ['products', 'billingPlan', 'contract'];

export const CommitmentsRetrieveRoute: React.FC = () => {
  return <CrudRetrieve<Commitment> relationships={relationships} />;
};
