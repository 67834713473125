import React, { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import {
  DataType,
  dataTypeToExternalMappingEntityType,
  Id,
  QueryParams,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';

export interface ExternalMappingCreateLinkProps {
  dataType: DataType;
  entityId: Id;
}

export const ExternalMappingCreateLink = React.forwardRef<
  HTMLAnchorElement,
  ExternalMappingCreateLinkProps
>(({ dataType, entityId, ...props }, ref) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.ExternalMapping);

  const { pathname } = useLocation();
  const createQueryParams = useMemo<QueryParams | undefined>(() => {
    const externalMappingEntityType =
      dataTypeToExternalMappingEntityType[dataType];
    if (!externalMappingEntityType) {
      return undefined;
    }

    return {
      m3terEntity: externalMappingEntityType,
      m3terId: entityId,
      returnPath: pathname,
    };
  }, [dataType, entityId, pathname]);

  if (!createQueryParams) {
    return null;
  }

  return (
    <CrudCreateLink
      ref={ref}
      dataType={DataType.ExternalMapping}
      queryParams={createQueryParams}
      {...props}
    >
      {t('forms:buttons.createEntity', {
        entityName: entityNamings.singularLower,
      })}
    </CrudCreateLink>
  );
});
