import React, { useMemo } from 'react';

import { Icon, Text, VStack } from '@chakra-ui/react';
import { TriangleAlertIcon } from 'lucide-react';

import { DataType, Pricing } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';
import {
  getFirstValidSegmentedPricing,
  isActive,
} from '@m3ter-com/console-core/utils';

import { NamedLink } from '@/components/common/navigation/NamedLink/NamedLink';
import { usePricingGridRowContext } from '@/components/features/pricing/grid/PricingGridRowContext/PricingGridRowContext';
import { usePricingGridCellContext } from '@/components/features/pricing/grid/PricingGridCellContext/PricingGridCellContext';

export const SegmentedPricingLink: React.FC = () => {
  const { t } = useTranslation();

  const { segments } = usePricingGridRowContext();
  const {
    planPricings = [],
    planTemplatePricings = [],
    pricingLinkQueryParams,
  } = usePricingGridCellContext<DataType.Pricing>();

  const pricingsCount = useMemo(() => {
    let count = 0;

    const activePlanPricings = planPricings.filter(isActive);
    const activePlanTemplatePricings = planTemplatePricings.filter(isActive);

    segments.forEach((aggregationSegment) => {
      let segmentMatchedPricing: Pricing | undefined;
      segmentMatchedPricing = getFirstValidSegmentedPricing(
        aggregationSegment,
        activePlanPricings
      );

      if (!segmentMatchedPricing) {
        segmentMatchedPricing = getFirstValidSegmentedPricing(
          aggregationSegment,
          activePlanTemplatePricings
        );
      }

      if (segmentMatchedPricing) {
        count += 1;
      }
    });

    return count;
  }, [planPricings, planTemplatePricings, segments]);

  return (
    <VStack justifyContent="flex-start" alignItems="center" spacing={4}>
      {pricingsCount === 0 && <Icon as={TriangleAlertIcon} boxSize={6} />}
      <Text>
        {t('features:pricing.numberOfActivelyPricedSegments', {
          pricingsCount,
          segmentsCount: segments.length,
        })}
      </Text>
      <Button
        size="sm"
        as={NamedLink}
        name="pricing.editor.segments"
        queryParams={pricingLinkQueryParams}
      >
        {t('features:pricing.editSegmentedPricing')}
      </Button>
    </VStack>
  );
};
