import { Entity } from '@m3ter-com/m3ter-api';

import { useCrudContext } from '@/components/common/crud/CrudContext/CrudContext';
import useEntityRetrieve from '@/hooks/data/crud/useEntityRetrieve';
import useCrudId from '@/hooks/data/crud/useCrudId';

const useCrudRetrieve = <E extends Entity = Entity>(
  relationships?: Array<string>
) => {
  const { dataType } = useCrudContext<E>();
  const id = useCrudId(dataType);

  const { error, isLoading, itemData } = useEntityRetrieve<E>(
    dataType,
    id,
    relationships
  );

  return {
    dataType,
    error,
    isLoading,
    itemData,
  };
};

export default useCrudRetrieve;
