import React from 'react';

import { PermissionPolicy } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { PermissionForm } from '@/components/features/access/permission-policy/PermissionForm/PermissionForm';

export const PermissionsEditRoute: React.FC = () => (
  <CrudEdit<PermissionPolicy> form={PermissionForm} />
);
