import React from 'react';

import { CounterAdjustment } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails/CrudDetails';
import { ItemCounterAdjustmentsDetails } from '@/components/features/accounts/ItemCounterAdjustmentsDetails/ItemCounterAdjustmentsDetails';

export const ItemCounterAdjustmentsDetailsRoute: React.FC = () => (
  <CrudDetails<CounterAdjustment> component={ItemCounterAdjustmentsDetails} />
);
