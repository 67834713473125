import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import sortBy from 'lodash/sortBy';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@chakra-ui/react';

import {
  DataType,
  DateTimeISOString,
  Id,
  Meter,
  MeterDataField,
  MeterField,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { dataTypeListAllQuery } from '@/queries/crud';
import { isFieldNumeric } from '@/util/meter';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';

interface MeterWithDimensions {
  id: Id;
  name: string;
  dimensions: Array<MeterDataField>;
}

interface UsageQueryBuilderContextValue {
  meters: Array<Meter>;
  allowedMeters: Array<Meter>;
  allowedMetersWithDimensions: Array<MeterWithDimensions>;
  getMeterFieldLabel: (meterField: MeterField) => string;
  startDate?: DateTimeISOString;
  endDate?: DateTimeISOString;
}

const SEPARATOR = '▸';

const UsageQueryBuilderContext = createContext<
  UsageQueryBuilderContextValue | undefined
>(undefined);

export interface UsageQueryBuilderProps {
  selectedMeterIds?: Array<Id>;
  startDate?: DateTimeISOString;
  endDate?: DateTimeISOString;
}

export const UsageQueryBuilder: React.FC<
  PropsWithChildren<UsageQueryBuilderProps>
> = ({ selectedMeterIds, startDate, endDate, children }) => {
  const { t } = useTranslation();

  const pathParams = useOrgPathParams();

  const {
    data: meters = [],
    isLoading,
    error,
  } = useQuery(
    dataTypeListAllQuery({
      dataType: DataType.Meter,
      pathParams,
    })
  );

  const allowedMeters = useMemo(
    () =>
      selectedMeterIds && selectedMeterIds.length > 0
        ? meters.filter(({ id }) => selectedMeterIds.includes(id))
        : meters,
    [meters, selectedMeterIds]
  );

  const allowedMetersWithDimensions = useMemo<Array<MeterWithDimensions>>(
    () =>
      sortBy(allowedMeters, 'name')
        .map((meter) => ({
          id: meter.id,
          name: meter.name,
          // Combine data and derived fields and get just the dimension fields.
          dimensions: [...meter.dataFields, ...meter.derivedFields].filter(
            (field) => !isFieldNumeric(field)
          ),
        }))
        // Remove any meters with no dimensions.
        .filter(({ dimensions }) => dimensions.length > 0),
    [allowedMeters]
  );

  const getMeterFieldLabel = useCallback(
    (meterField: MeterField) => {
      let meterName = meterField.meterId;
      let fieldName = meterField.fieldCode;

      const meter = meters.find(({ id }) => id === meterField.meterId);
      if (meter) {
        meterName = meter.name;

        const field = [...meter.dataFields, ...meter.derivedFields].find(
          ({ code }) => code === meterField.fieldCode
        );
        if (field) {
          fieldName = field.name;
        }
      }

      return `${meterName} ${SEPARATOR} ${fieldName}`;
    },
    [meters]
  );

  const value = useMemo(
    () => ({
      meters,
      allowedMeters,
      allowedMetersWithDimensions,
      getMeterFieldLabel,
      startDate,
      endDate,
    }),
    [
      meters,
      allowedMeters,
      allowedMetersWithDimensions,
      getMeterFieldLabel,
      startDate,
      endDate,
    ]
  );

  if (error) {
    return (
      <ErrorAlert
        title={t('errors:generic.problemLoadingData', {
          entityName: t('common:meters'),
        })}
        error={error}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <UsageQueryBuilderContext.Provider value={value}>
      {children}
    </UsageQueryBuilderContext.Provider>
  );
};

export const useQueryBuilder = () => {
  const context = useContext(UsageQueryBuilderContext);
  if (!context) {
    throw new Error('useQueryBuilder must be used within a UsageQueryBuilder');
  }
  return context;
};
