import React from 'react';

import { Account } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { AccountForm } from '@/components/features/accounts/AccountForm/AccountForm';

export const AccountsEditRoute: React.FC = () => (
  <CrudEdit<Account> form={AccountForm} />
);
