import React, { useCallback, useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';

import {
  SourceType,
  ExportSchedule,
  DataType,
  ScheduleType,
  DataExportTimePeriodType,
  AggregationFrequency,
  ExportFileFormat,
} from '@m3ter-com/m3ter-api';
import { useFormError, useTranslation } from '@m3ter-com/console-core/hooks';
import {
  FormStack,
  RadioTileOption,
  RadioTiles,
  SelectOption,
  Input,
  Alert,
  AlertLink,
} from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormRadioGroup,
  FormSection,
  FormSelect,
  NameCodeFields,
  RadioGroupOption,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import { dataTypeListQuery } from '@/queries/crud';
import exportScheduleSchema from '@/validation/exportSchedule';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { UsageQueryBuilder } from '@/components/features/usage/query-builder/UsageQueryBuilder/UsageQueryBuilder';
import {
  DataTypeFormEntityMultiSelect,
  FormEntityMultiSelect,
} from '@/components/forms/FormEntityMultiSelect/FormEntityMultiSelect';
import { FormUsageQueryBuilderAccounts } from '@/components/forms/FormUsageQueryBuilderAccounts/FormUsageQueryBuilderAccounts';
import { FormUsageQueryBuilderAggregation } from '@/components/forms/FormUsageQueryBuilderAggregation/FormUsageQueryBuilderAggregation';
import { FormUsageQueryBuilderAggregationFrequency } from '@/components/forms/FormUsageQueryBuilderAggregationFrequency/FormUsageQueryBuilderAggregationFrequency';
import { FormUsageQueryBuilderMeters } from '@/components/forms/FormUsageQueryBuilderMeters/FormUsageQueryBuilderMeters';
import { FormUsageQueryBuilderRelativeTimePeriod } from '@/components/forms/FormUsageQueryBuilderRelativeTimePeriod/FormUsageQueryBuilderRelativeTimePeriod';
import { FormUsageQueryBuilderDataTypes } from '@/components/forms/FormUsageQueryBuilderDataTypes/FormUsageQueryBuilderDataTypes';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';

export interface ExportScheduleCreateFormProps
  extends BaseFormProps<ExportSchedule> {
  isAdhoc?: boolean;
}

export interface ExportScheduleFormExtraData {
  isAdhoc: boolean;
}

const defaultInitialValues: Partial<ExportSchedule> = {};

const ExportScheduleTypeFields: React.FC = () => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const { isInvalid: isInvalidDataTypes, message: invalidDataTypesMessage } =
    useFormError('operationalDataTypes');
  const {
    isInvalid: isInvalidAggregation,
    message: invalidAggregationMessage,
  } = useFormError('aggregation');
  const sourceType: SourceType | undefined = useWatch({
    name: 'sourceType',
  });
  const aggregationFrequency: AggregationFrequency | undefined = useWatch({
    name: 'aggregationFrequency',
  });

  const isOriginalFrequency =
    aggregationFrequency === AggregationFrequency.Original;

  useEffect(() => {
    if (isOriginalFrequency) {
      setValue('aggregation', null, { shouldValidate: true });
    }
  }, [isOriginalFrequency, setValue]);

  const onTypeChange = useCallback(
    (newType: SourceType) => {
      if (newType === SourceType.Usage) {
        setValue('operationalDataTypes', []);
        setValue('timePeriod', DataExportTimePeriodType.Today);
        setValue('aggregationFrequency', AggregationFrequency.Original);
      } else {
        setValue('aggregationFrequency', null);
        setValue('timePeriod', null);
        setValue('aggregation', null);
        setValue('meterIds', []);
        setValue('accountIds', []);
      }
    },
    [setValue]
  );

  const exportTypeOptions = useMemo<Array<RadioTileOption<SourceType>>>(
    () => [
      {
        value: SourceType.Usage,
        label: t(`features:dataExports.sourceType.${SourceType.Usage}`),
        content: (
          <Text>
            {t(
              `features:dataExports.sourceTypeDescription.${SourceType.Usage}`
            )}
          </Text>
        ),
      },
      {
        value: SourceType.Operational,
        label: t(`features:dataExports.sourceType.${SourceType.Operational}`),
        content: (
          <Text>
            {t(
              `features:dataExports.sourceTypeDescription.${SourceType.Operational}`
            )}
          </Text>
        ),
      },
    ],
    [t]
  );

  const dataExportTimePeriods = useMemo(
    () => Object.values(DataExportTimePeriodType),
    []
  );

  return (
    <Box maxW="60em">
      <FormControl isRequired mb={4}>
        <FormLabel>{t('forms:labels.sourceType')}</FormLabel>
        <Controller
          name="sourceType"
          control={control}
          render={({ field: { value, onChange } }) => (
            <RadioTiles
              columns={2}
              value={value}
              options={exportTypeOptions}
              onChange={(type) => {
                onTypeChange(type);
                onChange(type);
              }}
            />
          )}
        />
      </FormControl>
      <Box>
        {sourceType === SourceType.Usage && (
          <UsageQueryBuilder>
            <SimpleGrid columns={6} gap={2} width="100%">
              <FormControl isRequired gridColumn="span 2">
                <FormUsageQueryBuilderRelativeTimePeriod
                  name="timePeriod"
                  periods={dataExportTimePeriods}
                />
              </FormControl>
              <FormControl isRequired gridColumn="span 2">
                <FormUsageQueryBuilderAggregationFrequency name="aggregationFrequency" />
              </FormControl>
              <FormControl
                gridColumn="span 2"
                isRequired={!isOriginalFrequency}
                isInvalid={!!isInvalidAggregation}
              >
                <FormUsageQueryBuilderAggregation
                  name="aggregation"
                  isDisabled={isOriginalFrequency}
                />
                {isInvalidAggregation && (
                  <FormErrorMessage>
                    {invalidAggregationMessage}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl gridColumn="span 3">
                <FormUsageQueryBuilderMeters name="meterIds" />
              </FormControl>
              <FormControl gridColumn="span 3">
                <FormUsageQueryBuilderAccounts name="accountIds" />
              </FormControl>
            </SimpleGrid>
          </UsageQueryBuilder>
        )}
        {sourceType === SourceType.Operational && (
          <FormControl isInvalid={!!isInvalidDataTypes}>
            <FormUsageQueryBuilderDataTypes name="operationalDataTypes" />
            {isInvalidDataTypes && (
              <FormErrorMessage>{invalidDataTypesMessage}</FormErrorMessage>
            )}
          </FormControl>
        )}
      </Box>
    </Box>
  );
};

const ExportScheduleFrequencyFields: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const scheduleTypeOptions = useMemo<Array<SelectOption<ScheduleType>>>(
    () =>
      Object.values(ScheduleType)
        .filter((type) => type !== ScheduleType.AdHoc)
        .map((type) => ({
          value: type,
          label: t(`features:dataExports.scheduleType.${type}`),
        })),
    [t]
  );

  return (
    <Flex alignItems="flex-end" gap={4} width="100%">
      <FormControl isRequired>
        <FormLabel>{t('forms:labels.frequency')}</FormLabel>
        <Controller
          name="period"
          control={control}
          render={({ field: { value, onChange } }) => (
            <InputGroup>
              <InputLeftAddon>{t('common:every')}</InputLeftAddon>
              <Input
                width="100%"
                type="number"
                value={value}
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
      </FormControl>
      <FormControl isRequired>
        <FormSelect name="scheduleType" options={scheduleTypeOptions} />
      </FormControl>
    </Flex>
  );
};

export const ExportScheduleForm: React.FC<ExportScheduleCreateFormProps> = ({
  onSave,
  onCancel,
  isAdhoc = false,
  isEdit = false,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.ExportSchedule);

  const extraValidationData = useMemo(() => ({ isAdhoc }), [isAdhoc]);
  const pathParams = useOrgPathParams();

  const { isLoading, data: exportDestinations = [] } = useQuery(
    dataTypeListQuery({
      dataType: DataType.ExportDestination,
      pathParams,
      queryParams: { pageSize: 1 },
    })
  );

  const exportFileFormatOptions = useMemo<Array<RadioGroupOption>>(
    () =>
      Object.values(ExportFileFormat).map((value) => ({
        value,
        label: t(`features:dataExports.exportFileFormat.${value}`),
      })),
    [t]
  );

  const hasNoDestinations = exportDestinations.length === 0;

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={exportScheduleSchema}
      extraValidationData={extraValidationData}
    >
      <FormSection
        mb={4}
        width="fit-content"
        heading={
          !isAdhoc
            ? t('common:entityDetails', { entityName: entityNamings.singular })
            : t('common:entityDetails', {
                entityName: t('features:dataExports.adhocExport'),
              })
        }
      >
        <Stack spacing={4} width="100%">
          <FormStack>
            {!isAdhoc && <NameCodeFields />}
            <FormField
              isRequired
              isLoading={isLoading}
              isDisabled={hasNoDestinations}
              name="destinationIds"
              label={t('common:destinations')}
              control={
                FormEntityMultiSelect as DataTypeFormEntityMultiSelect<DataType.ExportDestination>
              }
              dataType={DataType.ExportDestination}
              accessor="name"
              alert={
                hasNoDestinations && (
                  <Alert status="info">
                    <Trans
                      t={t as TFunction}
                      i18nKey="features:dataExports.noDestinationsWarning"
                      components={{
                        createExportDestinationLink: (
                          <AlertLink
                            as={CrudCreateLink}
                            addReturnPath
                            dataType={DataType.ExportDestination}
                          />
                        ),
                      }}
                    />
                  </Alert>
                )
              }
            />
            <FormField
              stacked
              name="exportFileFormat"
              label={t('forms:labels.exportFileFormat')}
              control={FormRadioGroup}
              options={exportFileFormatOptions}
              helpText={t('forms:helpText.exportFileFormat')}
            />
            {!isAdhoc && <ExportScheduleFrequencyFields />}
          </FormStack>
          <ExportScheduleTypeFields />
        </Stack>
      </FormSection>
      <FormActions
        cancelText={t('common:cancel')}
        submitText={
          // eslint-disable-next-line no-nested-ternary
          isAdhoc
            ? t('features:dataExports.runAdhocExport')
            : isEdit
            ? t('forms:buttons.updateEntity', {
                entityName: entityNamings.singularLower,
              })
            : t('forms:buttons.createEntity', {
                entityName: entityNamings.singularLower,
              })
        }
        isSaving={isSaving}
        onCancel={onCancel}
      />
    </Form>
  );
};
