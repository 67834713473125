import { Auth, Hub } from 'aws-amplify';
import i18next from 'i18next';

import { EnvironmentConfig } from '@/config';
import { federatedSignInHelper } from '@/util/auth';

import { setAuthErrorMessage } from './state/auth';

const setupAmplify = (config: EnvironmentConfig) => {
  Auth.configure({
    region: config.cognito.region,
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.userPoolWebClientId,
    oauth: {
      domain: config.cognito.domain,
      redirectSignIn: `${window.location.protocol}//${window.location.host}`,
      redirectSignOut: `${window.location.protocol}//${window.location.host}`,
      responseType: 'code',
      urlOpener: federatedSignInHelper,
    },
  });
  Hub.listen('auth', ({ payload }) => {
    if (payload.event === 'signIn_failure') {
      setAuthErrorMessage(
        payload.message ?? i18next.t('features:auth.unknownAuthError')
      );
    }
  });
};

export default setupAmplify;
