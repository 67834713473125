import React, { useMemo } from 'react';

import orderBy from 'lodash/orderBy';
import { useQuery } from '@tanstack/react-query';

import {
  DataType,
  getResourceTypes,
  PermissionEffect,
  PermissionPolicy,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack, SelectOption } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import permissionPolicySchema from '@/validation/permissionPolicy';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { FormPermissionPolicyEditor } from '@/components/forms/FormPermissionPolicyEditor/FormPermissionPolicyEditor';
import { NestedCheckboxOption } from '@/components/forms/NestedCheckbox/NestedCheckbox';

export interface PermissionFormProps extends BaseFormProps<PermissionPolicy> {}

const defaultInitialValues: Partial<PermissionPolicy> = {};

export const PermissionForm: React.FC<PermissionFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  isEdit = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.PermissionPolicy);

  const { data: permissionResources = [] } = useQuery({
    queryFn: () => getResourceTypes(),
    queryKey: ['resourceTypes'],
  });

  const effects = useMemo<Array<SelectOption<PermissionEffect>>>(
    () =>
      Object.values(PermissionEffect).map((effect) => ({
        label: t(`features:access.effect.${effect}`),
        value: effect,
      })),
    [t]
  );

  const actions = useMemo<Array<NestedCheckboxOption>>(
    () => [
      {
        label: t(`features:access.action.allConfig`),
        value: 'config',
        children: [
          {
            label: t(`features:access.action.create`),
            value: 'config:create',
          },
          {
            label: t(`features:access.action.retrieve`),
            value: 'config:retrieve',
          },
          {
            label: t(`features:access.action.update`),
            value: 'config:update',
          },
          {
            label: t(`features:access.action.delete`),
            value: 'config:delete',
          },
        ],
      },
      {
        label: t(`features:access.action.allMeasurements`),
        value: 'measurements',
        children: [
          {
            label: t(`features:access.action.retrieve`),
            value: 'measurements:retrieve',
          },
          {
            label: t(`features:access.action.upload`),
            value: 'measurements:upload',
          },
          {
            label: t(`features:access.action.fileUpload`),
            value: 'measurements:fileUpload',
          },
        ],
      },
    ],
    [t]
  );

  const resources = useMemo<Array<SelectOption>>(
    () =>
      orderBy(
        permissionResources.reduce((acc, resource) => {
          if (resource !== '*') {
            acc.push({
              label: resource,
              value: `${resource}/*`,
            });
          }
          return acc;
        }, new Array<SelectOption>()),
        'label'
      ),
    [permissionResources]
  );

  const options = useMemo(
    () => ({ effects, actions, resources }),
    [actions, effects, resources]
  );

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={permissionPolicySchema}
    >
      <FormStack mb={4}>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <FormField
            isRequired
            name="name"
            label={t('forms:labels.name')}
            control={FormInput}
          />
        </FormSection>
      </FormStack>
      <FormSection
        mb={4}
        minW="40em"
        w="fit-content"
        heading={t('features:access.permissionPolicyStatements')}
      >
        <FormField
          isRequired
          name="permissionPolicy"
          label={t('forms:labels.statements')}
          control={FormPermissionPolicyEditor}
          options={options}
        />
      </FormSection>
      <FormActions
        cancelText={t('common:cancel')}
        submitText={
          isEdit
            ? t('forms:buttons.updateEntity', {
                entityName: entityNamings.singularLower,
              })
            : t('forms:buttons.createEntity', {
                entityName: entityNamings.singularLower,
              })
        }
        isSaving={isSaving}
        onCancel={onCancel}
      />
    </Form>
  );
};
