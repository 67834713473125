import React from 'react';

import {
  Box,
  Heading,
  Link,
  SimpleGrid,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';

import { DataType, Commitment, Product } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';
import { KeyValue } from '@m3ter-com/ui-components';

import { isCommitmentActive } from '@/util/account';
import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEntityDelete from '@/hooks/data/crud/useEntityDelete';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink/CrudEditLink';
import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge/ActiveStatusBadge';
import { useCrudListContext } from '@/components/common/crud/CrudList';

export enum AccountCommitmentListType {
  ActiveAndPending = 'active-and-pending',
  Expired = 'expired',
}

export interface AccountCommitmentsTableProps {
  listType: AccountCommitmentListType;
}

export const AccountCommitmentsTable: React.FC<
  AccountCommitmentsTableProps
> = ({ listType }) => {
  const { t } = useTranslation();
  const { timeZone, toLongDate } = useDateFormatter();
  const { formatCurrency } = useCurrencies();
  const { deleteItem } = useEntityDelete(DataType.Commitment);
  const { currentPageEntities, isLoading } =
    useCrudListContext<EntityWithRelationships<Commitment>>();

  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      {!isLoading && currentPageEntities.length === 0 && (
        <Text textAlign="center">
          {listType === AccountCommitmentListType.ActiveAndPending
            ? t('features:account.noActiveOrPendingPrepayments')
            : t('features:account.noPreviousPrepayments')}
        </Text>
      )}
      {!isLoading && currentPageEntities.length > 0 && (
        <VStack alignItems="stretch" spacing={4} divider={<StackDivider />}>
          {currentPageEntities.map((commitment) => (
            <SimpleGrid
              key={commitment.id}
              alignItems="start"
              columns={4}
              role="row"
            >
              <Heading size="sm">
                <Link
                  as={CrudDetailsLink}
                  dataType={DataType.Commitment}
                  id={commitment.id}
                >
                  {formatCurrency(commitment.amount, commitment.currency)}
                </Link>
                {listType === AccountCommitmentListType.ActiveAndPending && (
                  <ActiveStatusBadge
                    active={isCommitmentActive(commitment, timeZone)}
                    inactiveLabel={t('common:pending')}
                  />
                )}
              </Heading>
              <Box>
                <KeyValue
                  label={t('common:start')}
                  value={toLongDate(commitment.startDate)}
                />
                <KeyValue
                  label={t('common:end')}
                  value={toLongDate(commitment.endDate)}
                />
              </Box>
              <Box>
                {!!commitment.products?.length && (
                  <KeyValue
                    label={t('features:commitments.includesProducts')}
                    value={
                      <Text>
                        {(commitment.products as Array<Product>).map(
                          (product, productIndex) => (
                            <React.Fragment key={product.id}>
                              <Link
                                as={CrudEditLink}
                                dataType={DataType.Product}
                                id={product.id}
                              >
                                {product.name}
                              </Link>
                              {productIndex + 1 < commitment.products.length &&
                                ', '}
                            </React.Fragment>
                          )
                        )}
                      </Text>
                    }
                  />
                )}
                {!!commitment.contract && (
                  <KeyValue
                    label={t('forms:labels.contract')}
                    value={
                      <Link
                        as={CrudDetailsLink}
                        dataType={DataType.Contract}
                        id={commitment.contract.id}
                      >
                        {commitment.contract.name}
                      </Link>
                    }
                  />
                )}
                {!!commitment.billingPlan && (
                  <KeyValue
                    label={t('features:commitments.billedWith')}
                    value={
                      <Link
                        as={CrudDetailsLink}
                        dataType={DataType.Plan}
                        id={commitment.billingPlan.id}
                      >
                        {commitment.billingPlan.name}
                      </Link>
                    }
                  />
                )}
              </Box>
              <Box>
                <EntityCrudActions<Commitment>
                  addReturnPath
                  dataType={DataType.Commitment}
                  editRouteName={getCrudRouteName(
                    DataType.Commitment,
                    CrudRouteType.Edit
                  )}
                  item={commitment}
                  onDelete={deleteItem}
                  duplicateRouteName={getCrudRouteName(
                    DataType.Commitment,
                    CrudRouteType.Create
                  )}
                />
              </Box>
            </SimpleGrid>
          ))}
        </VStack>
      )}
    </React.Fragment>
  );
};
