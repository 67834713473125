import React, { Suspense } from 'react';

import { Box, Container, Stack, Spinner } from '@chakra-ui/react';
import { Navigate, Outlet } from 'react-router-dom';

import { LoadingScreen } from '@m3ter-com/ui-components';

import { getAuthRedirectPath } from '@/util/localStorage';
import useAuth from '@/hooks/auth/useAuth';
import { Logo } from '@/components/common/brand/Logo/Logo';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';

export const AuthContainer: React.FC = () => {
  const { authError, isAuthenticatedUser, isRestoringSession } = useAuth();

  if (isRestoringSession) {
    return <LoadingScreen />;
  }

  if (isAuthenticatedUser) {
    const redirectAfterAuth = getAuthRedirectPath();
    return <Navigate replace to={redirectAfterAuth ?? '/'} />;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Container maxW="lg" p={8}>
        <Stack spacing={8}>
          <Stack spacing={8}>
            <Logo w="300px" mr="auto" ml="auto" />
          </Stack>
          <Box p={8} boxShadow="md" borderRadius="md">
            <Stack spacing={4}>
              {authError && <ErrorAlert error={authError} />}
              <Outlet />
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Suspense>
  );
};
