import React, { useCallback, useMemo } from 'react';

import { SimpleGrid, Td } from '@chakra-ui/react';

import {
  DataType,
  BillLineItem,
  Commitment,
  Id,
  PricingCommon,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useCurrencies from '@/hooks/util/useCurrencies';
import { KeyValueReferenceLink } from '@/components/common/data/KeyValueReferenceLink/KeyValueReferenceLink';
import useDateFormatter from '@/hooks/util/useDateFormatter';

interface BillLineItemDetailsProps {
  accountId: Id;
  lineItem: BillLineItem;
  rowSpan?: number;
}

export const BillLineItemDetails: React.FC<BillLineItemDetailsProps> = ({
  lineItem,
  accountId,
  rowSpan,
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { formatDateRangeWithExclusiveEndDate, toLongDate } =
    useDateFormatter();

  const params = useMemo(() => ({ accountId }), [accountId]);

  const commitmentAccessor = useCallback(
    (commitment: Commitment) =>
      formatCurrency(commitment.amount, commitment.currency),
    [formatCurrency]
  );
  const pricingAccessor = useCallback(
    (pricing: PricingCommon) =>
      pricing.endDate
        ? formatDateRangeWithExclusiveEndDate(
            pricing.startDate,
            pricing.endDate
          )
        : toLongDate(pricing.startDate),
    [formatDateRangeWithExclusiveEndDate, toLongDate]
  );

  return (
    <React.Fragment>
      <Td colSpan={2} rowSpan={rowSpan} />
      <Td fontSize="md" colSpan={2} rowSpan={rowSpan}>
        <SimpleGrid columns={3} gap={2}>
          {lineItem.childAccountId && (
            <KeyValueReferenceLink
              label={t('features:account.childAccount')}
              dataType={DataType.Account}
              id={lineItem.childAccountId}
              accessor="name"
            />
          )}
          {lineItem.planId && (
            <KeyValueReferenceLink
              label={t('common:plan')}
              dataType={DataType.Plan}
              id={lineItem.planId}
              accessor="name"
            />
          )}
          {lineItem.planGroupId && (
            <KeyValueReferenceLink
              label={t('common:planGroup')}
              dataType={DataType.PlanGroup}
              id={lineItem.planGroupId}
              accessor="name"
            />
          )}
          {lineItem.aggregationId && (
            <KeyValueReferenceLink
              label={t('common:aggregation')}
              dataType={DataType.Aggregation}
              id={lineItem.aggregationId}
              accessor="name"
            />
          )}
          {lineItem.compoundAggregationId && (
            <KeyValueReferenceLink
              label={t('common:compoundAggregation')}
              dataType={DataType.CompoundAggregation}
              id={lineItem.compoundAggregationId}
              accessor="name"
            />
          )}
          {lineItem.meterId && (
            <KeyValueReferenceLink
              label={t('common:meter')}
              dataType={DataType.Meter}
              id={lineItem.meterId}
              accessor="name"
            />
          )}
          {lineItem.commitmentId && (
            <KeyValueReferenceLink
              label={t('common:commitment')}
              dataType={DataType.Commitment}
              id={lineItem.commitmentId}
              accessor={commitmentAccessor}
              params={params}
            />
          )}
          {lineItem.counterId && (
            <KeyValueReferenceLink
              label={t('common:counter')}
              dataType={DataType.Counter}
              id={lineItem.counterId}
              accessor="name"
            />
          )}
          {(lineItem.aggregationId || lineItem.compoundAggregationId) &&
            lineItem.pricingId && (
              <KeyValueReferenceLink
                label={t('common:pricing')}
                dataType={DataType.Pricing}
                id={lineItem.pricingId}
                accessor={pricingAccessor}
              />
            )}
          {lineItem.counterId && lineItem.pricingId && (
            <KeyValueReferenceLink
              label={t('common:counterPricing')}
              dataType={DataType.CounterPricing}
              id={lineItem.pricingId}
              accessor={pricingAccessor}
            />
          )}
        </SimpleGrid>
      </Td>
    </React.Fragment>
  );
};
