import React from 'react';

import { Params } from 'react-router-dom';

import useNamedLink from '@/hooks/navigation/useNamedLink';
import {
  NavLink,
  NavLinkProps,
} from '@/components/common/navigation/NavLink/NavLink';

export interface NamedNavLinkProps extends Omit<NavLinkProps, 'to'> {
  name: string;
  params?: Params;
  queryParams?: Record<string, any>;
}

export const NamedNavLink: React.FC<NamedNavLinkProps> = ({
  name,
  params,
  queryParams,
  ...props
}) => {
  const link = useNamedLink(name, params, queryParams);

  return <NavLink to={link} {...props} />;
};
